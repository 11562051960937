var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Heading, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CentredWrapper, FormButton, Icon, InputControl, MarkdownBase, TextField, } from '@/components';
import { useToast } from '@/hooks';
import { AuthService } from '@/services';
import { useAuth } from '@/store';
export const PasswordReset = () => {
    const { t } = useTranslation();
    const { showToast } = useToast();
    const { identifier, token } = useParams();
    const authCTX = useAuth();
    const [loading, setLoading] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [newPass, setNewPass] = useState('');
    const handleChange = (evt) => setNewPass(evt.target.value);
    const handleVisibility = () => setShowPass(!showPass);
    const handlePasswordReset = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        try {
            setLoading(true);
            yield AuthService.resetPassword({
                identifier,
                password: newPass,
                password_confirmation: newPass,
                token,
            });
            yield authCTX.handleAuth();
            showToast({
                message: t('common:message.success.resetPassword'),
                status: 'success',
            });
        }
        finally {
            setLoading(false);
        }
    });
    return (_jsxs(CentredWrapper, { children: [_jsx(Heading, Object.assign({ size: "lg" }, { children: t('page:passwordReset.title') })), _jsx(Text, { children: t('page:passwordReset.subtitle') }), _jsxs(Grid, Object.assign({ as: "form", gridGap: "8", mt: "8", onSubmit: handlePasswordReset, w: "100%" }, { children: [_jsx(MarkdownBase, { bg: "darkBrandBlue.5", borderRadius: "8", color: "darkBrandBlue.40", markdown: t('page:passwordReset.requirements'), p: "4", textAlign: "left" }), _jsx(InputControl, Object.assign({ isRequired: true, label: t('common:label.password') }, { children: _jsx(TextField, { autoComplete: "new-password", onChange: handleChange, rightElement: _jsx(Icon, { name: showPass ? 'eye' : 'eye-slash', type: "solid", onClick: handleVisibility }), type: showPass ? 'text' : 'password' }) })), _jsx(FormButton, Object.assign({ loading: loading, type: "submit" }, { children: t('common:btn.savePassword') }))] }))] }));
};
