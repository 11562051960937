import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { APP_CONFIG } from '@/types';
import { MarkdownBase } from '.';
import { Icon } from './Icon';
export const StateMessage = ({ message }) => {
    const { t } = useTranslation();
    const styleMap = {
        [APP_CONFIG.COMPONENTS.ConfigWarnType.INFORMATION]: {
            color: 'lightBlue.60',
            icon: 'info-circle',
        },
        [APP_CONFIG.COMPONENTS.ConfigWarnType.SUCCESS]: {
            color: 'green.60',
            icon: 'check-circle',
        },
        [APP_CONFIG.COMPONENTS.ConfigWarnType.WARNING]: {
            color: 'red.80',
            icon: 'exclamation-circle',
        },
    };
    return (_jsx(Stack, Object.assign({ spacing: "3", w: "100%" }, { children: message === null || message === void 0 ? void 0 : message.blocks.map(({ text, type }, idx) => {
            const style = styleMap[type];
            return (_jsxs(HStack, Object.assign({ borderColor: style.color, borderRadius: "8px", borderWidth: "2px", p: "4", spacing: "2" }, { children: [_jsx(Icon, { color: "green.60", name: style.icon, size: "35", type: "solid" }), _jsx(MarkdownBase, { markdown: t(text) })] }), idx));
        }) })));
};
