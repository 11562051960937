var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { MarkdownBase } from '@/components';
import { APP_CONFIG } from '@/types';
export const FieldMarkdown = (_a) => {
    var { data: { text }, type } = _a, rest = __rest(_a, ["data", "type"]);
    const { t } = useTranslation();
    const getMarkdownStyle = () => {
        switch (type) {
            case APP_CONFIG.COMPONENTS.ConfigFieldType.MARKDOWN_HELP:
                return {
                    bg: 'darkBrandBlue.5',
                    borderRadius: '8',
                    color: 'darkBrandBlue.40',
                    padding: '4',
                    textAlign: 'left',
                };
            case APP_CONFIG.COMPONENTS.ConfigFieldType.MARKDOWN_TEXT:
                return {};
        }
    };
    const styles = getMarkdownStyle();
    return _jsx(MarkdownBase, Object.assign({}, rest, { markdown: t(text), sx: styles }));
};
