import { jsx as _jsx } from "react/jsx-runtime";
import { APP_CONFIG } from '@/types';
import { FieldCheckbox, FieldMarkdown, FieldPassword, FieldText } from './fields';
const configFieldComponents = {
    [APP_CONFIG.COMPONENTS.ConfigFieldType.CHECKBOX]: FieldCheckbox,
    [APP_CONFIG.COMPONENTS.ConfigFieldType.MARKDOWN_HELP]: FieldMarkdown,
    [APP_CONFIG.COMPONENTS.ConfigFieldType.MARKDOWN_TEXT]: FieldMarkdown,
    [APP_CONFIG.COMPONENTS.ConfigFieldType.PASSWORD]: FieldPassword,
    [APP_CONFIG.COMPONENTS.ConfigFieldType.TEXT]: FieldText,
};
export const renderConfigField = (props) => {
    const Component = configFieldComponents[props.type];
    return _jsx(Component, Object.assign({}, props));
};
