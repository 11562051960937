import { useEffect, useRef } from 'react';
export const useInterval = (callback, delay = 3000) => {
    const savedCallback = useRef(callback);
    useEffect(() => {
        savedCallback.current = callback;
    });
    useEffect(() => {
        function tick() {
            savedCallback === null || savedCallback === void 0 ? void 0 : savedCallback.current();
        }
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
};
