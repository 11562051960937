import { initialAuthState } from '@/store';
import { APP_CONFIG, AuthActionType } from '@/types';
export const authReducer = (state, action) => {
    switch (action.type) {
        case AuthActionType.SET_USER_INVITATION: {
            const { payload } = action;
            return Object.assign(Object.assign({}, state), { userInvitation: Object.assign(Object.assign(Object.assign({}, state.userInvitation), payload), { meta: Object.assign(Object.assign({}, state.userInvitation.meta), payload.meta) }) });
        }
        case AuthActionType.SET_USER_DETAILS: {
            return Object.assign(Object.assign({}, state), { user: action.payload });
        }
        case AuthActionType.SET_CURRENT_ROUTE: {
            return Object.assign(Object.assign({}, state), { currentRoute: action.payload });
        }
        case AuthActionType.SET_CURRENT_USER_PHASE: {
            const { payload } = action;
            const currentUserPhaseSegment = state.segments.find((segment) => segment.id === (payload === null || payload === void 0 ? void 0 : payload.segment_id));
            return Object.assign(Object.assign({}, state), { currentUserPhaseEvent: payload, currentUserPhaseSegment: currentUserPhaseSegment || initialAuthState.currentUserPhaseSegment });
        }
        case AuthActionType.SET_USER_PHASE: {
            const { events = [], id, segments = [] } = action.payload;
            return Object.assign(Object.assign({}, state), { currentUserPhase: id, schedule: events, segments });
        }
        case AuthActionType.SET_IDENTITY_PROVIDERS: {
            return Object.assign(Object.assign({}, state), { identityProviders: action.payload });
        }
        case AuthActionType.SET_USER_PHASE_EVENT_DONE: {
            const { payload: eventId } = action;
            const event = state.schedule.find((x) => x.meta.find((m) => m.value === eventId)) ||
                state.currentUserPhaseEvent;
            if (event.type.toLowerCase() ===
                APP_CONFIG.COMPONENTS.ScheduleEventType.QUESTIONNAIRE.toLowerCase()) {
                const newEvent = Object.assign(Object.assign({}, event), { completed: true });
                const schedule = state.schedule.map((item) => (item.id === (newEvent === null || newEvent === void 0 ? void 0 : newEvent.id) ? newEvent : item));
                return Object.assign(Object.assign({}, state), { schedule });
            }
            return state;
        }
        default:
            throw new Error();
    }
};
