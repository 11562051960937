var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Heading } from '@chakra-ui/react';
export const PageWrapper = (_a) => {
    var { children, title = '' } = _a, rest = __rest(_a, ["children", "title"]);
    return (_jsxs(Flex, Object.assign({ direction: "column", h: "100%", justifyContent: "start", m: "0 auto", maxW: "820px", px: "4", py: "16", w: "100%" }, rest, { children: [title && (_jsx(Heading, Object.assign({ as: "h2", variant: "MASTHEAD" }, { children: title }))), children] })));
};
