export var CONFIG_STYLE;
(function (CONFIG_STYLE) {
    let AlignmentType;
    (function (AlignmentType) {
        AlignmentType["centre"] = "centre";
        AlignmentType["end"] = "end";
        AlignmentType["start"] = "start";
    })(AlignmentType = CONFIG_STYLE.AlignmentType || (CONFIG_STYLE.AlignmentType = {}));
    let PaddingType;
    (function (PaddingType) {
        PaddingType["xxsmall"] = "xxsmall";
        PaddingType["xsmall"] = "xsmall";
        PaddingType["small"] = "small";
        PaddingType["medium"] = "medium";
        PaddingType["large"] = "large";
        PaddingType["xlarge"] = "xlarge";
        PaddingType["xxlarge"] = "xxlarge";
    })(PaddingType = CONFIG_STYLE.PaddingType || (CONFIG_STYLE.PaddingType = {}));
})(CONFIG_STYLE || (CONFIG_STYLE = {}));
export var BLOCK_CSS;
(function (BLOCK_CSS) {
    let AlignmentType;
    (function (AlignmentType) {
        AlignmentType["centre"] = "center";
        AlignmentType["end"] = "end";
        AlignmentType["start"] = "start";
    })(AlignmentType = BLOCK_CSS.AlignmentType || (BLOCK_CSS.AlignmentType = {}));
    let PaddingType;
    (function (PaddingType) {
        PaddingType["xxsmall"] = "4px";
        PaddingType["xsmall"] = "8px";
        PaddingType["small"] = "12px";
        PaddingType["medium"] = "16px";
        PaddingType["large"] = "24px";
        PaddingType["xlarge"] = "32px";
        PaddingType["xxlarge"] = "64px";
    })(PaddingType = BLOCK_CSS.PaddingType || (BLOCK_CSS.PaddingType = {}));
    let HeadingSize;
    (function (HeadingSize) {
        HeadingSize["h1"] = "34px";
        HeadingSize["h2"] = "26px";
        HeadingSize["h3"] = "22px";
        HeadingSize["h4"] = "20px";
        HeadingSize["h5"] = "18px";
        HeadingSize["h6"] = "14px";
    })(HeadingSize = BLOCK_CSS.HeadingSize || (BLOCK_CSS.HeadingSize = {}));
})(BLOCK_CSS || (BLOCK_CSS = {}));
