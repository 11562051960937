import { jsx as _jsx } from "react/jsx-runtime";
import { MarkdownBase } from '@/components';
import { useResolveValue } from '@/hooks';
import { StaticBlockType, } from '@/types';
import { convertToCSS } from '@/utils';
export const Markdown = ({ block: { content, id, layout = {}, type } }) => {
    const [resolver] = useResolveValue();
    const resolved = {
        content: resolver.resolveString(content),
    };
    const styles = Object.assign(Object.assign({}, convertToCSS(layout)), (type === StaticBlockType.STATIC_MARKDOWN_INSTRUCTION && {
        bg: 'darkBrandBlue.5',
        borderRadius: 16,
        p: 4,
    }));
    return _jsx(MarkdownBase, { "data-test": id, markdown: resolved.content, sx: styles });
};
