export var AuthActionType;
(function (AuthActionType) {
    AuthActionType["SET_CURRENT_ROUTE"] = "SET_CURRENT_ROUTE";
    AuthActionType["SET_CURRENT_USER_PHASE"] = "SET_CURRENT_USER_PHASE";
    AuthActionType["SET_IDENTITY_PROVIDERS"] = "SET_IDENTITY_PROVIDERS";
    AuthActionType["SET_USER_DETAILS"] = "SET_USER_DETAILS";
    AuthActionType["SET_USER_INVITATION"] = "SET_USER_INVITATION";
    AuthActionType["SET_USER_PHASE"] = "SET_USER_PHASE";
    AuthActionType["SET_USER_PHASE_EVENT_DONE"] = "SET_USER_PHASE_EVENT_DONE";
})(AuthActionType || (AuthActionType = {}));
