import { jsx as _jsx } from "react/jsx-runtime";
import { Link as ReactLink, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { isUrlExternal, isUrlSpecial } from '@/utils';
export const StyledLink = (props) => {
    const newProps = Object.assign({}, props);
    delete newProps.variant;
    if (isUrlExternal(props.to) || isUrlSpecial(props.to)) {
        return (_jsx(ReactLink, Object.assign({ href: props.to, isExternal: true }, { children: props.children })));
    }
    return props.disabled ? (_jsx(Text, Object.assign({ as: "div", variant: props.variant }, { children: props.children }))) : (_jsx(Link, Object.assign({}, newProps, { to: newProps.to || newProps.href }, { children: _jsx(Text, Object.assign({ as: "div", variant: props.variant }, { children: props.children })) })));
};
