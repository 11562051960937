var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormErrorMessage, FormHelperText, Input, InputGroup, InputLeftElement, InputRightElement, forwardRef, } from '@chakra-ui/react';
export const TextField = forwardRef((_a, ref) => {
    var { error, helper, leftElement, rightElement } = _a, rest = __rest(_a, ["error", "helper", "leftElement", "rightElement"]);
    return (_jsxs(_Fragment, { children: [_jsxs(InputGroup, { children: [leftElement && (_jsx(InputLeftElement, Object.assign({ h: "100%", minW: "50px" }, { children: leftElement }))), _jsx(Input, Object.assign({ border: "2px solid", borderColor: "darkBrandBlue.10", borderRadius: "8", h: "50px" }, rest, { ref: ref })), rightElement && (_jsx(InputRightElement, Object.assign({ h: "100%", minW: "50px" }, { children: rightElement })))] }), helper && _jsx(FormHelperText, { children: helper }), _jsx(FormErrorMessage, { children: error })] }));
});
