export const isMediaValid = (questionnaire) => {
    const { blockList, getMediaCore } = questionnaire;
    return blockList.every((block) => {
        if ('images' in block) {
            const isImagesValid = block.images.every((image) => {
                const { not_selected_image_key, selected_image_key } = image;
                const isValidKey = (key) => {
                    return getMediaCore(key).length > 0 || key === '';
                };
                return isValidKey(selected_image_key) && isValidKey(not_selected_image_key);
            });
            if (!isImagesValid) {
                console.error(`Broken media key found in block: ${block.id}`);
                return false;
            }
        }
        if ('media' in block) {
            const hasMediaError = !getMediaCore(block.media.key);
            if (hasMediaError) {
                console.error(`Broken media key found in block: ${block.id}`);
                return false;
            }
        }
        return true;
    });
};
