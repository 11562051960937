import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { InputControl, TextField } from '@/components';
import { useDebounce, useResolveValue } from '@/hooks';
import { ValuesType } from '@/types';
import { getInputBlockAnswer } from '@/utils';
export const WholeNumberField = ({ block: { answer, id, key, placeholder_text, required }, defaultValue = null, errors = [], onChange, }) => {
    useEffect(() => {
        inputRef.current.value = (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.toString()) || '';
    }, [defaultValue]);
    const inputRef = useRef();
    const [resolver] = useResolveValue();
    const resolved = {
        label: resolver.resolveString(answer.label),
        placeholder: resolver.resolveString(placeholder_text),
        required: resolver.resolveBoolean(required),
    };
    const debouncedValue = useDebounce(defaultValue, 500);
    const isInvalid = (errors === null || errors === void 0 ? void 0 : errors.length) > 0 && defaultValue !== null && debouncedValue !== null;
    const handleChange = (e) => {
        const { value } = e.target;
        const answerValue = Object.assign(Object.assign({}, answer), { label: resolved.label, value: value === '' ? null : Number(value) });
        const newAnswer = getInputBlockAnswer(ValuesType.INTEGER, answerValue, key);
        onChange(newAnswer);
    };
    const handlePaste = (e) => {
        e.preventDefault();
    };
    const handleInvalidChar = (e) => {
        if (['.', 'e', 'E', '+', '-'].includes(e.key))
            e.preventDefault();
    };
    const handleScroll = () => {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
    };
    return (_jsx(InputControl, Object.assign({ "data-test": id, isInvalid: isInvalid, isRequired: resolved.required, label: resolved.label }, { children: _jsx(TextField, { "data-test": answer.id, defaultValue: defaultValue, error: errors[0] || '', inputMode: "numeric", min: 0, onChange: handleChange, onKeyDown: handleInvalidChar, onPaste: handlePaste, onWheel: handleScroll, placeholder: resolved.placeholder, ref: inputRef, type: "number" }) })));
};
