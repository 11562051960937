export var ConditionType;
(function (ConditionType) {
    ConditionType["ALL_OF"] = "allOf::condition";
    ConditionType["ALWAYS"] = "always::condition";
    ConditionType["ANY_OF"] = "anyOf::condition";
    ConditionType["DATE_COMPARISON"] = "dateComparison::condition";
    ConditionType["DECIMAL_COMPARISON"] = "decimalComparison::condition";
    ConditionType["INTEGER_COMPARISON"] = "integerComparison::condition";
    ConditionType["IS_FALSE"] = "isFalse::condition";
    ConditionType["IS_TRUE"] = "isTrue::condition";
    ConditionType["NEVER"] = "never::condition";
    ConditionType["STRING_COMPARISON"] = "stringComparison::condition";
    ConditionType["TIME_COMPARISON"] = "timeComparison::condition";
    ConditionType["TIME_ZONE_EQUALITY"] = "timeZoneEquality::condition";
    ConditionType["ZONED_DATE_TIME_COMPARISON"] = "zonedDateTimeComparison::condition";
})(ConditionType || (ConditionType = {}));
