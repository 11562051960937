var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, Text } from '@chakra-ui/react';
export const EventBase = (_a) => {
    var { bg = 'grey.60', children, color = 'darkBrandBlue.100', leftIcon, rightIcon, title } = _a, rest = __rest(_a, ["bg", "children", "color", "leftIcon", "rightIcon", "title"]);
    return (_jsxs(HStack, Object.assign({ __css: {
            'img, svg': {
                maxH: '24px',
            },
        }, alignItems: "center", bg: bg, border: "none", borderRadius: "8", color: color, cursor: "pointer", fontSize: "md", justifyContent: "space-between", minH: "60px", px: "4", py: "2", w: "100%" }, rest, { children: [_jsxs(HStack, Object.assign({ alignItems: "center", spacing: "4" }, { children: [leftIcon, _jsx(Text, { children: title })] })), rightIcon] })));
};
