export var StaticBlockType;
(function (StaticBlockType) {
    StaticBlockType["STATIC_HEADING"] = "static::heading";
    StaticBlockType["STATIC_MARKDOWN"] = "static::markdown";
    StaticBlockType["STATIC_MARKDOWN_INSTRUCTION"] = "static::markdown_instruction";
    StaticBlockType["STATIC_MEDIA_IMAGE"] = "static::media_image";
    StaticBlockType["STATIC_TEXT"] = "static::text";
})(StaticBlockType || (StaticBlockType = {}));
export var MediaSizeType;
(function (MediaSizeType) {
    MediaSizeType["ORIGINAL"] = "original";
    MediaSizeType["FILL_WIDTH"] = "fill_width";
})(MediaSizeType || (MediaSizeType = {}));
