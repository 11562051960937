import { useLayoutEffect, useState } from 'react';
export const useWindowSize = () => {
    const [size, setSize] = useState({ height: 0, width: 0 });
    const handleWindowSize = () => {
        setSize({
            height: window.innerHeight,
            width: window.innerWidth,
        });
    };
    useLayoutEffect(() => {
        handleWindowSize();
        window.addEventListener('resize', handleWindowSize);
        return () => window.removeEventListener('resize', handleWindowSize);
    }, []);
    return size;
};
export default useWindowSize;
