import { jsx as _jsx } from "react/jsx-runtime";
import './style.css';
import { Tooltip } from '@chakra-ui/react';
import { forwardRef, useCallback, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { Icon, InputControl, TextField } from '@/components';
import { getLocale } from '@/config';
import { useResolveValue } from '@/hooks';
import { ValuesType } from '@/types';
import { ResolveDate, getInputBlockAnswer } from '@/utils';
import { PickerHeader } from './PickerHeader';
const resolveDate = new ResolveDate();
export const DateSelector = ({ block: { answer, clear_label, id, key, max_date, min_date, required }, defaultValue = '', errors = [], onChange, }) => {
    const [resolver] = useResolveValue();
    const resolved = {
        clearLabel: resolver.resolveString(clear_label),
        label: resolver.resolveString(answer.label),
        maxDate: resolver.resolveDate(max_date),
        minDate: resolver.resolveDate(min_date),
        required: resolver.resolveBoolean(required),
    };
    const isInvalid = errors.length > 0 && !isNaN(Date.parse(defaultValue));
    const updateAnswer = (date) => {
        const data = {
            answer: date ? resolveDate.formatDate(date).date : null,
        };
        const answers = [
            getInputBlockAnswer(ValuesType.DATE, Object.assign(Object.assign({}, answer), { label: resolved.label, value: data.answer }), key),
        ];
        onChange(answers);
    };
    const handleChange = (date) => updateAnswer(date);
    const handleDeselect = () => updateAnswer();
    const pickerView = useMemo(() => {
        const prop = {
            shouldCloseOnSelect: true,
            showMonthYearPicker: false,
            showTimeInput: false,
            showYearPicker: false,
        };
        return prop;
    }, []);
    const props = Object.assign({ maxDate: resolved.maxDate, minDate: resolved.minDate, selected: defaultValue ? new Date(defaultValue) : undefined }, pickerView);
    const pickerFormatter = useCallback(() => {
        const { selected } = props;
        if (selected) {
            const formattedDate = resolveDate.formatDateLocalized(selected);
            return formattedDate.date;
        }
        return '';
    }, [props]);
    const PickerInput = forwardRef(({ onClick }, ref) => {
        const val = pickerFormatter();
        return (_jsx(InputControl, Object.assign({ isInvalid: isInvalid, isRequired: resolved.required }, { children: _jsx(TextField, { "data-test": answer.id, defaultValue: val, error: isInvalid && errors[0], leftElement: _jsx(Icon, { name: "calendar", onClick: onClick }), onClick: onClick, 
                // as this block is not yet validated, we need to prevent the typing behavior to restrict it to the picker only
                onKeyDown: (e) => e.preventDefault(), ref: ref, rightElement: val && (_jsx(Tooltip, Object.assign({ label: resolved.clearLabel, placement: "left" }, { children: _jsx(Icon, { name: "close-circle", cursor: "pointer", onClick: handleDeselect }) }))) }) })));
    });
    return (_jsx(InputControl, Object.assign({ "data-test": id, isRequired: resolved.required, label: resolved.label }, { children: _jsx(DatePicker, Object.assign({ customInput: _jsx(PickerInput, {}), disabledKeyboardNavigation: true, dropdownMode: "select", locale: getLocale(), onChange: handleChange, renderCustomHeader: !props.showYearPicker ? (hProps) => _jsx(PickerHeader, Object.assign({}, props, hProps)) : undefined, showMonthDropdown: true, showPopperArrow: false, showYearDropdown: true }, props)) })));
};
