import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, chakra } from '@chakra-ui/react';
import { Image } from '@/components';
import { useResolveValue } from '@/hooks';
import { MediaSizeType } from '@/types';
import { convertToCSS } from '@/utils';
export const MediaImage = ({ block: { caption, id, media: { alignment, alt, size, src = '' }, padding, }, }) => {
    const [resolver] = useResolveValue();
    const resolved = {
        alt: resolver.resolveString(alt),
        caption: resolver.resolveString(caption === null || caption === void 0 ? void 0 : caption.content),
    };
    const styles = {
        caption: convertToCSS(caption === null || caption === void 0 ? void 0 : caption.layout),
        fillWidth: (size === null || size === void 0 ? void 0 : size.type) === MediaSizeType.FILL_WIDTH
            ? (size === null || size === void 0 ? void 0 : size.override_screen_padding.ignore_horizontal_padding)
                ? 'fill-width ignore-horizontal-padding'
                : 'fill-width'
            : '',
        root: convertToCSS({ alignment, padding }),
    };
    return (_jsx(Flex, Object.assign({ sx: styles.root }, { children: _jsxs(chakra.figure, Object.assign({ className: styles.fillWidth, "data-test": id }, { children: [_jsx(Image, { alt: resolved.alt, rounded: "8", src: src, w: "100%" }), caption && (_jsx(chakra.figcaption, Object.assign({ fontSize: "sm", sx: styles.caption, w: "100%" }, { children: resolved.caption })))] })) })));
};
