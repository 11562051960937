export default {
    blue10: '#cdd7dc',
    blue100: '#00344F',
    blue20: '#9caeb9',
    blue40: '#435771',
    blue5: '#ECEEF0',
    blue50: '#035F8F',
    blue60: '#0091FF',
    blue70: '#7F99A7',
    blue80: '#647D9A',
    blue90: '#003551',
};
