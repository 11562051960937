import { jsx as _jsx } from "react/jsx-runtime";
import { useToken } from '@chakra-ui/react';
import Select, { components, } from 'react-select';
import { Icon } from '@/components';
import { useWindowSize } from '@/hooks';
export const DropdownBase = (props) => {
    const windowSize = useWindowSize();
    const [darkBrandBlue5, darkBrandBlue10, darkBrandBlue20, darkBrandBlue40] = useToken('colors', [
        'darkBrandBlue.5',
        'darkBrandBlue.10',
        'darkBrandBlue.20',
        'darkBrandBlue.40',
    ]);
    const styles = {
        control: (styles) => (Object.assign(Object.assign({}, styles), { '&:hover': {
                borderColor: darkBrandBlue40,
            }, borderColor: darkBrandBlue10, borderWidth: 2, boxShadow: 'none', minHeight: '50px', padding: '5px 10px' })),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        placeholder: (styles) => (Object.assign(Object.assign({}, styles), { color: darkBrandBlue20 })),
        singleValue: (styles) => (Object.assign(Object.assign({}, styles), { textOverflow: 'initial', whiteSpace: 'normal' })),
    };
    const theme = (theme) => (Object.assign(Object.assign({}, theme), { borderRadius: 8, colors: Object.assign(Object.assign({}, theme.colors), { primary: darkBrandBlue40, primary25: darkBrandBlue5, primary50: darkBrandBlue10, primary75: darkBrandBlue40 }) }));
    const DropdownIndicator = (props) => {
        const { menuIsOpen } = props.selectProps;
        return (_jsx(components.DropdownIndicator, Object.assign({}, props, { children: _jsx(Icon, { name: "chevron-down", p: "0", sx: {
                    transform: menuIsOpen && 'rotate(180deg)',
                    transition: 'all .2s ease',
                }, type: "solid" }) })));
    };
    return (_jsx(Select, Object.assign({ className: "dropdown-select", components: { DropdownIndicator }, isSearchable: false, maxMenuHeight: windowSize.height - 50, styles: styles, theme: theme }, props)));
};
