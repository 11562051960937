var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _ValidateScene_resolver;
import { InputBlockType, } from '@/types';
import { mergeArrays } from './helpers';
import { ResolveValue } from './resolve-value';
import { Validator } from './validator';
export class ValidateScene extends Validator {
    constructor(answers, cachedAnswers = [], segment, reusableResolvers, computedValues) {
        super();
        _ValidateScene_resolver.set(this, void 0);
        __classPrivateFieldSet(this, _ValidateScene_resolver, new ResolveValue(answers, cachedAnswers, segment, reusableResolvers, computedValues), "f");
    }
    blocks(scene) {
        const errors = new Map();
        const page = __classPrivateFieldGet(this, _ValidateScene_resolver, "f").resolveCurrentQueuePage();
        const blockList = mergeArrays(scene === null || scene === void 0 ? void 0 : scene.main_blocks, scene === null || scene === void 0 ? void 0 : scene.footer_blocks);
        const skipList = new Set([InputBlockType.INPUT_MOTIF]);
        blockList.forEach((block) => {
            var _a;
            if ('key' in block && !skipList.has(block.type)) {
                const { key: blockKey, required, type } = block;
                const validators = [];
                const conditions = [];
                const isRequired = __classPrivateFieldGet(this, _ValidateScene_resolver, "f").resolveBoolean(required);
                const totalAnswers = __classPrivateFieldGet(this, _ValidateScene_resolver, "f").resolveAllAnswers(blockKey, scene.key).length;
                const answer = __classPrivateFieldGet(this, _ValidateScene_resolver, "f").resolveAnswer(blockKey, page, undefined, {
                    shouldCrash: false,
                });
                if (isRequired) {
                    switch (type) {
                        case InputBlockType.INPUT_STRING_FREE_TEXT: {
                            validators.push(this.required().test(answer));
                        }
                        case InputBlockType.INPUT_WHOLE_NUMBER_KEYPAD: {
                            validators.push(this.min(0).test(answer));
                        }
                        default: {
                            validators.push(this.min(1).test(totalAnswers));
                            break;
                        }
                    }
                }
                switch (type) {
                    case InputBlockType.INPUT_DECIMAL: {
                        if (answer) {
                            const { type_validation_error_message } = block.input_mode;
                            const { parsed } = answer;
                            const parts = parsed.split('.');
                            const test = !parts[0] || !parts[1];
                            if (test) {
                                validators.push();
                                errors.set(blockKey, [__classPrivateFieldGet(this, _ValidateScene_resolver, "f").resolveString(type_validation_error_message)]);
                            }
                        }
                        break;
                    }
                    case InputBlockType.INPUT_STRING_FREE_TEXT: {
                        if (Number.isInteger(block === null || block === void 0 ? void 0 : block.max_length) && answer) {
                            validators.push(this.max(block.max_length).test(answer));
                        }
                        break;
                    }
                    case InputBlockType.INPUT_MULTI_OPTION_SELECT:
                    case InputBlockType.INPUT_STRING_TEXT_BOXES: {
                        if (totalAnswers) {
                            validators.push(this.max(block.max).test(totalAnswers), this.min(block.min).test(totalAnswers));
                        }
                        break;
                    }
                    default: {
                        break;
                    }
                }
                if (totalAnswers) {
                    (_a = block.validation) === null || _a === void 0 ? void 0 : _a.forEach(({ condition, message }) => {
                        const resolved = __classPrivateFieldGet(this, _ValidateScene_resolver, "f").resolveBoolean(condition);
                        if (!resolved)
                            conditions.push(__classPrivateFieldGet(this, _ValidateScene_resolver, "f").resolveString(message));
                    });
                }
                if (conditions.length || !validators.every((x) => x)) {
                    errors.set(blockKey, conditions);
                }
            }
        });
        return errors;
    }
    confirmations(scene) {
        if (!scene || !('confirmations' in scene))
            return [];
        return scene.confirmations.reduce((acc, { condition, modal }) => {
            const resolved = __classPrivateFieldGet(this, _ValidateScene_resolver, "f").resolveBoolean(condition);
            if (resolved) {
                const cancel_text = __classPrivateFieldGet(this, _ValidateScene_resolver, "f").resolveString(modal.cancel_text);
                const confirm_text = __classPrivateFieldGet(this, _ValidateScene_resolver, "f").resolveString(modal.confirm_text);
                const content = __classPrivateFieldGet(this, _ValidateScene_resolver, "f").resolveString(modal.content);
                const title = __classPrivateFieldGet(this, _ValidateScene_resolver, "f").resolveString(modal.title);
                acc.push({ cancel_text, confirm_text, content, title });
            }
            return acc;
        }, []);
    }
}
_ValidateScene_resolver = new WeakMap();
