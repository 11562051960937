var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { BackgroundImage, Button, CentredWrapper, Image, ScreenIntroText, renderConfigField, } from '@/components';
import { useMultiStepForm } from '@/hooks';
import { AuthService } from '@/services';
import { useAuth, useConfig } from '@/store';
import { APP_CONFIG, HTTP_STATUS, PlatformType, ROUTE } from '@/types';
import { timeZoneName } from '@/utils';
export const Register = () => {
    const { t } = useTranslation();
    const { getComponentConfig } = useConfig();
    const { handleAuth, state: { user, userInvitation }, } = useAuth();
    // USE INVITATION CODE AS URL PARAMETER AND FETCH IF PASSED ?
    if (!userInvitation.code)
        return _jsx(Navigate, { to: ROUTE.HOME });
    const { screens } = getComponentConfig(APP_CONFIG.COMPONENTS.ConfigComponentType.REGISTRATION);
    const formOptions = screens.reduce((acc, curr, i) => {
        if (curr.type === APP_CONFIG.COMPONENTS.ConfigScreenType.INPUT) {
            curr.data.fields.forEach(({ data: d }) => {
                if ('key' in d) {
                    acc.data[d.key] = 'default' in d ? d.default : '';
                    acc.validation[d.key] = {
                        required: 'required' in d ? d.required : false,
                        step: i,
                    };
                }
            });
        }
        return acc;
    }, {
        data: {},
        validation: {},
    });
    const handleLogin = () => __awaiter(void 0, void 0, void 0, function* () {
        yield AuthService.login({
            password: formData.password,
            username: userInvitation.code,
        });
        yield handleAuth();
    });
    const { formData, formErrors, handleBack, handleFieldChange, handleGoTo, handleNext, handleSubmitForm, isLastStep, setFormErrors, step, } = useMultiStepForm(screens, Object.assign(Object.assign({}, formOptions), { onSubmit: () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            try {
                const payload = Object.assign(Object.assign(Object.assign({}, formData), { client: PlatformType.WEB, invitation_id: userInvitation.id, timezone_name: timeZoneName }), (((_b = (_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.locale) && {
                    locale: (_d = (_c = user === null || user === void 0 ? void 0 : user.data) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.locale,
                }));
                yield AuthService.register(payload);
                handleNext();
            }
            catch (e) {
                if (e.status === HTTP_STATUS.UNPROCESSABLE_ENTITY) {
                    let stepError = screens.length;
                    const errorList = e.data.errors.reduce((acc, curr) => {
                        const { pointer } = curr.source;
                        const field = formOptions.validation[pointer];
                        if (field.step < stepError)
                            stepError = field.step;
                        return Object.assign(Object.assign({}, acc), { [pointer]: curr.detail });
                    }, {});
                    setFormErrors(errorList);
                    handleGoTo(stepError);
                }
            }
        }) }));
    const renderScreen = () => {
        var _a;
        const { next, prev, title } = step.data;
        const Navigation = () => {
            var _a, _b;
            const isInput = step.type === APP_CONFIG.COMPONENTS.ConfigScreenType.INPUT;
            return (_jsxs(Flex, Object.assign({ gridGap: "5", justifyContent: "center", sx: {
                    '& button': {
                        maxW: '200px',
                    },
                }, w: "100%" }, { children: [prev && (_jsx(Button, Object.assign({ onClick: handleBack, variant: "outline" }, { children: t(prev) }))), next && (_jsx(Button, Object.assign({ onClick: isLastStep ? handleLogin : handleNext, styles: !isInput && ((_a = step.data) === null || _a === void 0 ? void 0 : _a.style) }, { children: t(next) }))), isInput && ((_b = step.data) === null || _b === void 0 ? void 0 : _b.submit) && (_jsx(Button, Object.assign({ onClick: handleSubmitForm }, { children: t(step.data.submit) })))] })));
        };
        switch (step.type) {
            case APP_CONFIG.COMPONENTS.ConfigScreenType.COVER:
            case APP_CONFIG.COMPONENTS.ConfigScreenType.INSTRUCTIONS: {
                const { backgroundImage, body, logo, style } = step.data;
                return (_jsx(BackgroundImage, Object.assign({ bgColor: style.primary, path: backgroundImage === null || backgroundImage === void 0 ? void 0 : backgroundImage.portrait }, { children: _jsxs(CentredWrapper, Object.assign({ gridGap: "16" }, { children: [_jsx(Image, { maxW: "140px", mb: "8", path: logo }), _jsx(ScreenIntroText, { styles: style, subtitle: t(body), title: t(title) }), _jsx(Navigation, {})] })) })));
            }
            case APP_CONFIG.COMPONENTS.ConfigScreenType.INPUT: {
                // Input screens with markdown fields use a wider container e.g. Terms & conditions
                const hasMarkDownField = step.data.fields.some((field) => field.type === APP_CONFIG.COMPONENTS.ConfigFieldType.MARKDOWN_TEXT);
                const markdownMaxWidth = '820px';
                return (_jsxs(CentredWrapper, Object.assign({ gridGap: "16" }, (hasMarkDownField ? { maxW: markdownMaxWidth } : {}), { children: [_jsx(ScreenIntroText, { title: t(title) }), _jsx(Flex, Object.assign({ direction: "column", gridGap: "6", textAlign: "left", w: "100%" }, { children: (_a = step.data) === null || _a === void 0 ? void 0 : _a.fields.map((field) => {
                                var _a, _b, _c;
                                return renderConfigField(Object.assign(Object.assign({}, field), ('key' in field.data && {
                                    answerChange: handleFieldChange,
                                    defaultValue: formData[(_a = field.data) === null || _a === void 0 ? void 0 : _a.key],
                                    error: formErrors[(_b = field.data) === null || _b === void 0 ? void 0 : _b.key],
                                    isInvalid: formErrors[(_c = field.data) === null || _c === void 0 ? void 0 : _c.key] !== undefined,
                                })));
                            }) })), _jsx(Navigation, {})] })));
            }
            default:
                throw new Error('Invalid Register Component Screen Type');
        }
    };
    return renderScreen();
};
