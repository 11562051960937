export const solidIcons = {
    calendar: `M21.6,10.2v7.1c0,2.3-1.9,4.2-4.2,4.2H6.6c-2.3,0-4.2-1.9-4.2-4.2v-7.1H21.6z M8,2.3c-0.3,0-0.5,0.2-0.5,0.5v2.3 c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V2.8C8.5,2.5,8.3,2.3,8,2.3z M16,2.3c-0.3,0-0.5,0.2-0.5,0.5v2.3c0,0.3,0.2,0.5,0.5,0.5 s0.5-0.2,0.5-0.5V2.8C16.5,2.5,16.3,2.3,16,2.3z M17.4,3.8L17.4,3.8c-0.1,0-0.1,0-0.1,0.1v1.3c0,0.7-0.6,1.2-1.2,1.2 s-1.2-0.6-1.2-1.2V3.9c0-0.1,0-0.1-0.1-0.1H9.4c-0.1,0-0.1,0-0.1,0.1v1.3c0,0.7-0.6,1.2-1.2,1.2S6.8,5.9,6.8,5.2V3.9 c0-0.1,0-0.1-0.1-0.1h0C4.3,3.8,2.4,5.7,2.4,8v1.2h19.2V8C21.6,5.7,19.7,3.8,17.4,3.8z`,
    circle: `M12,21.8c-5.41,0-9.8-4.4-9.8-9.8s4.4-9.8,9.8-9.8s9.8,4.4,9.8,9.8S17.41,21.8,12,21.8z M12,3.7 c-4.58,0-8.3,3.73-8.3,8.3s3.73,8.3,8.3,8.3s8.3-3.73,8.3-8.3S16.58,3.7,12,3.7z`,
    check: `M8.8,18.6c-0.3,0-0.5-0.1-0.7-0.3l-4.5-4.5c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l3.8,3.8L19,6c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L9.5,18.3C9.3,18.5,9.1,18.6,8.8,18.6z`,
    'check-circle': `M12 2.2c-5.4 0-9.8 4.4-9.8 9.8s4.4 9.8 9.8 9.8 9.8-4.4 9.8-9.8-4.4-9.8-9.8-9.8zm4.5 7.5l-5.4 5.4c-.1.1-.3.2-.5.2s-.4-.1-.5-.2l-2.6-2.6c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l2.1 2.1 4.9-4.9c.3-.3.8-.3 1.1 0s.1.8-.2 1.1z`,
    'chevron-down': `M12,15.5c-0.3,0-0.5-0.1-0.7-0.3l-5-4.9c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l4.3,4.2l4.3-4.3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-5,5C12.5,15.4,12.3,15.5,12,15.5z`,
    'chevron-drag': `M15,17.2c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l3.9-3.9l-3.9-3.9c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l4.4,4.4 c0.3,0.3,0.3,0.8,0,1.1l-4.4,4.4C15.3,17.1,15.2,17.2,15,17.2z M9,17.2c-0.2,0-0.4-0.1-0.5-0.2l-4.4-4.4c-0.3-0.3-0.3-0.8,0-1.1 l4.4-4.4c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1L5.7,12l3.9,3.9c0.3,0.3,0.3,0.8,0,1.1C9.4,17.1,9.2,17.2,9,17.2z`,
    'chevron-left': `M8.5,12c0,0.3,0.1,0.5,0.3,0.7l4.9,4.9c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4L10.9,12l4.3-4.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-5,5C8.6,11.5,8.5,11.8,8.5,12z`,
    'chevron-right': `M15.5,12c0,0.3-0.1,0.5-0.3,0.7l-4.9,4.9c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4l4.2-4.2L8.8,7.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l5,5C15.4,11.5,15.5,11.8,15.5,12z`,
    'chevron-up': `M12,8.5c-0.3,0-0.5,0.1-0.7,0.3l-4.9,4.9c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0l4.2-4.2l4.3,4.3c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4l-5-5C12.5,8.6,12.2,8.5,12,8.5z`,
    clock: `M15.5,15c-0.1,0.2-0.4,0.4-0.6,0.4c-0.1,0-0.3,0-0.4-0.1l-3.2-1.9c-0.2-0.1-0.4-0.4-0.4-0.6V8.6 c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8v3.5c0,0.1,0.1,0.2,0.1,0.3l2.7,1.6C15.6,14.1,15.7,14.6,15.5,15z M19.4,12.7 c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8h1.8c0.2,0,0.3-0.1,0.3-0.3c-0.5-4.4-4-7.9-8.4-8.4c-0.2,0-0.3,0.1-0.3,0.3v1.8 c0,0.4-0.3,0.8-0.8,0.8S11.2,5,11.2,4.6V2.9c0-0.2-0.1-0.3-0.3-0.3c-4.4,0.5-7.9,4-8.4,8.4c0,0.2,0.1,0.3,0.3,0.3h1.8 c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8H2.9c-0.2,0-0.3,0.1-0.3,0.3c0.5,4.4,4,7.9,8.4,8.4c0.2,0,0.3-0.1,0.3-0.3v-1.8 c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v1.8c0,0.2,0.1,0.3,0.3,0.3c4.4-0.5,7.9-4,8.4-8.4c0-0.2-0.1-0.3-0.3-0.3H19.4z`,
    'close-circle': `M12,2.5c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5s9.5-4.3,9.5-9.5S17.2,2.5,12,2.5z M15.3,14.6c0.2,0.2,0.2,0.5,0,0.7 c-0.1,0.1-0.2,0.1-0.4,0.1s-0.3,0-0.4-0.1L12,12.7l-2.6,2.6c-0.1,0.1-0.2,0.1-0.4,0.1s-0.3,0-0.4-0.1c-0.2-0.2-0.2-0.5,0-0.7 l2.6-2.6L8.7,9.3c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l2.6,2.6l2.6-2.6c0.2-0.2,0.5-0.2,0.7,0s0.2,0.5,0,0.7L12.7,12L15.3,14.6z`,
    'divider-dual': `M20.34,15.25H3.99c-0.41,0-0.75-0.34-0.75-0.75s0.34-0.75,0.75-0.75h16.36c0.41,0,0.75,0.34,0.75,0.75S20.76,15.25,20.34,15.25z M20.34,10.25H3.99c-0.41,0-0.75-0.34-0.75-0.75s0.34-0.75,0.75-0.75h16.36c0.41,0,0.75,0.34,0.75,0.75S20.76,10.25,20.34,10.25z`,
    'exclamation-circle': `M12 2.2c-5.4 0-9.8 4.4-9.8 9.8s4.4 9.8 9.8 9.8 9.8-4.4 9.8-9.8-4.4-9.8-9.8-9.8zm.7 14.1c0 .1-.1.2-.2.2-.1.1-.2.1-.2.2-.1 0-.2.1-.3.1-.1 0-.2 0-.3-.1-.1 0-.2-.1-.3-.2-.1-.1-.1-.2-.2-.2 0-.1-.1-.2-.1-.3 0-.1 0-.2.1-.3 0-.1.1-.2.2-.3.3-.3.8-.3 1.1 0 .1.1.1.2.2.3 0 .1.1.2.1.3 0 .1 0 .2-.1.3zm.1-3.8c0 .4-.4.8-.8.8s-.8-.4-.8-.8V8.1c0-.4.4-.8.8-.8s.8.4.8.8v4.4z`,
    'exclamation-triangle': `M22.5 18.1L14.1 3.4c-.4-.7-1.2-1.2-2.1-1.2-.9 0-1.6.5-2 1.2L1.5 18.1c-.5.7-.5 1.6-.1 2.4.4.8 1.2 1.2 2.1 1.2h17c.8 0 1.6-.4 2-1.2.5-.7.5-1.6 0-2.4zm-9.8-.8c0 .1-.1.2-.2.2-.1.1-.2.1-.2.2-.1 0-.2.1-.3.1-.1 0-.2 0-.3-.1-.1 0-.2-.1-.3-.2-.1-.1-.1-.2-.2-.2 0-.1-.1-.2-.1-.3 0-.1 0-.2.1-.3 0-.1.1-.2.2-.3.3-.3.8-.3 1.1 0 .1.1.1.2.2.3 0 .1.1.2.1.3 0 .1 0 .2-.1.3zm.1-3.8c0 .4-.4.8-.8.8s-.8-.4-.8-.8V9.1c0-.4.4-.8.8-.8s.8.4.8.8v4.4z`,
    eye: `M12,4C3.49,4,1.01,11.5,1.01,11.5S3.55,19,12,19s10.99-7.5,10.99-7.5S20.51,4,12,4z M12,16.51 c-2.77,0-5.01-2.24-5.01-5.01c0-2.77,2.24-5.01,5.01-5.01s5.01,2.24,5.01,5.01C17.01,14.27,14.77,16.51,12,16.51z M15.02,11.5 c0,1.67-1.35,3.02-3.02,3.02s-3.02-1.35-3.02-3.02S10.33,8.48,12,8.48S15.02,9.83,15.02,11.5z`,
    'eye-slash': `M7.97,4.66c1.16-0.4,2.5-0.65,4.03-0.65c8.51,0,10.99,7.5,10.99,7.5s-0.91,2.64-3.41,4.79l-2.93-2.94 c0.23-0.57,0.36-1.2,0.36-1.85c0-2.77-2.24-5.01-5.01-5.01c-0.65,0-1.27,0.13-1.84,0.36L7.97,4.66z M15.02,11.51 c0-1.67-1.35-3.02-3.02-3.02c-0.06,0-0.12,0.01-0.19,0.02L15,11.7C15,11.64,15.02,11.57,15.02,11.51z M20.35,20.86 c-0.2,0.19-0.45,0.29-0.71,0.29s-0.51-0.1-0.71-0.29l-2.62-2.62c-1.23,0.47-2.66,0.77-4.32,0.77c-8.45,0-10.99-7.5-10.99-7.5 S1.95,8.7,4.64,6.52L2.67,4.55c-0.39-0.39-0.39-1.02,0-1.41c0.39-0.39,1.02-0.39,1.41,0l16.27,16.31 C20.75,19.84,20.75,20.47,20.35,20.86z M14.12,16.03l-1.56-1.57c-0.18,0.03-0.37,0.06-0.56,0.06c-1.67,0-3.02-1.35-3.02-3.02 c0-0.2,0.02-0.39,0.06-0.57L7.48,9.37c-0.31,0.65-0.49,1.37-0.49,2.14c0,2.77,2.24,5.01,5.01,5.01 C12.76,16.52,13.48,16.34,14.12,16.03z`,
    'info-circle': `M12 2.2c-5.4 0-9.8 4.4-9.8 9.8s4.4 9.8 9.8 9.8 9.8-4.4 9.8-9.8-4.4-9.8-9.8-9.8zm.8 13.8c0 .4-.3.8-.8.8s-.8-.3-.8-.8v-4.4c0-.4.3-.8.8-.8s.8.3.8.8V16zm-.3-7.4c-.1.1-.3.2-.5.2s-.4-.1-.5-.2-.2-.3-.2-.5.1-.4.2-.5c.3-.3.8-.3 1.1 0 .1.1.2.3.2.5s-.1.4-.3.5z`,
    padlock: `M17.1,9.5h-0.6V7c0-2.5-2-4.5-4.5-4.5S7.5,4.5,7.5,7v2.5H6.8c-1.3,0-2.3,1-2.3,2.3v7.4c0,1.3,1,2.3,2.3,2.3 h10.4c0.6,0,1.2-0.3,1.7-0.7c0.4-0.4,0.6-1,0.5-1.6v-7.4C19.4,10.5,18.4,9.5,17.1,9.5z M8.5,7c0-1.9,1.6-3.5,3.5-3.5 s3.5,1.6,3.5,3.5v2.5h-7V7z M12.5,15.9V18c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5v-2.1c-0.6-0.2-1.1-0.8-1.1-1.5 c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6C13.6,15.1,13.1,15.7,12.5,15.9z`,
    times: `M17.3 6.7L6.7 17.4m10.6-.1L6.7 6.7`
};
export const lineIcons = {
    calendar: `M8,2.8v3.4 M16,2.8v3.4 M2.9,9.7H21 M21.1,17.3V8c0-2.1-1.7-3.7-3.7-3.7H6.6C4.6,4.3,2.9,6,2.9,8v9.2 c0,2.1,1.7,3.7,3.7,3.7h10.7C19.4,21,21.1,19.3,21.1,17.3z`,
    circle: `M21.05,12c0,5-4.05,9.05-9.05,9.05S2.95,17,2.95,12S7,2.95,12,2.95S21.05,7,21.05,12z`,
    check: `M4,12.8l4.8,4.8L20,6.4`,
    'check-circle': `M21 12c0 5-4 9-9 9s-9-4-9-9 4-9 9-9 9 4 9 9zm-5-2.8l-5.4 5.4L8 12`,
    'chevron-down': `17,9.5 12,14.5 7,9.6`,
    'chevron-drag': `M9,16.4L4.6,12L9,7.6 M15,16.4l4.4-4.4L15,7.6`,
    'chevron-left': `14.5,7 9.5,12 14.4,17`,
    'chevron-right': `9.5,7 14.5,12 9.6,17`,
    'chevron-up': `17,14.5 12,9.5 7,14.4`,
    'clock': `M12,21c5,0,9-4,9-9c0-5-4-9-9-9c-5,0-9,4-9,9C3,17,7,21,12,21z M21,12h-1.6 M12,21v-1.6 M3,12h1.6 M12,3v1.6 M14.8,14.6l-3.2-1.9V8.6`,
    'close-circle': `M21,11.9c0,5-4,9-9,9s-9-4-9-9s4-9,9-9S21,6.9,21,11.9z M15,9L9,14.9 M9,9l5.9,5.9`,
    'divider-dual': `M3.82,9.5h16.36 M3.82,14.5h16.36`,
    'exclamation-circle': `M12 8.1v4.4m0 3.5h0m9-4c0 5-4 9-9 9s-9-4-9-9 4-9 9-9 9 4.1 9 9z`,
    'exclamation-triangle': `M13.4 3.8l8.5 14.7c.6 1.1-.2 2.5-1.4 2.5h-17c-1.3 0-2.1-1.4-1.4-2.5l8.5-14.7c.6-1.1 2.2-1.1 2.8 0zM12 9.1v4.4m0 3.5h0`,
    eye: `M15 12c0 1.7-1.4 3-3 3s-3-1.4-3-3 1.4-3 3-3 3 1.3 3 3zm6.1 0c-2-4.3-5.4-7-9.1-7s-7.1 2.7-9.1 7c2 4.3 5.4 7 9.1 7s7.1-2.7 9.1-7z`,
    'eye-slash': `M14.1 14.2c-.5.5-1.3.9-2.1.9-1.7 0-3-1.4-3-3 0-.8.3-1.6.9-2.1m9.9 4.3c.5-.7.9-1.5 1.3-2.3-2-4.3-5.4-7-9.1-7-.5 0-1 .1-1.5.1M6.9 6.9C5.3 8.1 4 9.9 3 12c2 4.3 5.4 7 9.1 7 1.8 0 3.6-.7 5.1-1.9M2.9 3L21 21`,
    'info-circle': `M12 16v-4.4m0-3.5h0m9 3.9c0 5-4 9-9 9s-9-4-9-9 4-9 9-9 9 4.1 9 9z`,
    padlock: `M17.2,21H6.8c-1,0-1.8-0.8-1.8-1.8v-7.4c0-1,0.8-1.8,1.8-1.8h10.3c1,0,1.8,0.8,1.8,1.8v7.4 C19,20.2,18.2,21,17.2,21z M16,10V7c0-2.2-1.8-4-4-4l0,0C9.8,3,8,4.8,8,7v3 M12,18v-2.5 M12,13.3c-0.6,0-1.1,0.5-1.1,1.1 s0.5,1.1,1.1,1.1s1.1-0.5,1.1-1.1S12.6,13.3,12,13.3z`,
    times: `M17.3 6.7L6.7 17.4m10.6-.1L6.7 6.7`
};
