import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { DropdownBase, InputControl } from '@/components';
import { useResolveValue } from '@/hooks';
import { filterBlockAnswers, getAnswerValue, getInputBlockAnswer } from '@/utils';
export const Dropdown = ({ block: { answer_filters = [], id, key, no_selection_text, required, typed_answers }, defaultValue, errors = [], onChange, }) => {
    const [resolver] = useResolveValue();
    const resolved = useMemo(() => {
        const answerKeys = resolver.resolveAnswerFilter(answer_filters);
        const filters = filterBlockAnswers(typed_answers.answers, answerKeys, defaultValue);
        const answersList = filters.answersFiltered.map((a, order) => {
            return Object.assign(Object.assign({}, a), { label: resolver.resolveString(a.label), order });
        });
        return {
            answersList,
            placeholder: resolver.resolveString(no_selection_text),
            required: resolver.resolveBoolean(required),
        };
    }, []);
    const selectedValue = useMemo(() => {
        var _a;
        const newDefaultValue = getAnswerValue(defaultValue);
        return (_a = resolved.answersList.find((a) => a.value === newDefaultValue)) !== null && _a !== void 0 ? _a : null;
    }, [defaultValue]);
    const handleChange = (answer) => {
        const newAnswer = getInputBlockAnswer(typed_answers.type, answer, key);
        onChange(newAnswer, { order: answer.order });
    };
    return (_jsx(InputControl, Object.assign({ "data-test": id, error: errors[0] || '', isInvalid: errors.length > 0, isRequired: resolved.required }, { children: _jsx(DropdownBase, { onChange: handleChange, options: resolved.answersList, placeholder: resolved.placeholder, value: selectedValue }) })));
};
