import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Image } from '@/components';
const SIZE = '32px';
export const AnswerImage = ({ answerKey, imageList = [], isSelected = false }) => {
    const image = useMemo(() => {
        return imageList === null || imageList === void 0 ? void 0 : imageList.find((i) => i.answer_key === answerKey);
    }, [answerKey]);
    if (!image)
        return null;
    const { not_selected_image_key, selected_image_key } = image;
    if (isSelected && selected_image_key) {
        return _jsx(Image, { image_key: selected_image_key, maxH: SIZE, w: SIZE });
    }
    else if (!isSelected && not_selected_image_key) {
        return _jsx(Image, { image_key: not_selected_image_key, maxH: SIZE, w: SIZE });
    }
    else {
        return null;
    }
};
