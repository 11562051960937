var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { jwtDecode } from 'jwt-decode';
import { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { matchRoutes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { isClinro, setLanguage } from '@/config';
import { publicRoutes, routes } from '@/routes';
import { AuthService, ConfigService, SchedulingService } from '@/services';
import { authReducer, closeLoading, initialAuthState, setAppConfig, setCurrentRoute, setIdentityProviders, setUserDetails, setUserPhase, showLoading, useConfig, useUI, } from '@/store';
import { NativeAction, ROUTE, StorageKey, UrlParamsKey } from '@/types';
import { REGEX, emitNativeWebView } from '@/utils';
const AuthContext = createContext({
    isLoggedIn: false,
    state: initialAuthState,
    dispatch: () => undefined,
    handleAuth: () => undefined,
    handleLogout: () => undefined,
});
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [state, dispatch] = useReducer(authReducer, initialAuthState);
    const { dispatch: configDispatch, state: { appConfig: { webSupport }, }, } = useConfig();
    const uiCTX = useUI();
    const navigate = useNavigate();
    const location = useLocation();
    const [urlParams] = useSearchParams();
    const url = useMemo(() => {
        const studyCode = urlParams.get(UrlParamsKey.STUDY_CODE);
        const isStudyCode = REGEX.STUDY_CODE.test(studyCode);
        return {
            isStudyCode,
            studyCode,
        };
    }, []);
    const handleLogout = () => __awaiter(void 0, void 0, void 0, function* () {
        navigate(ROUTE.HOME + location.search);
        const { INVITE_CODE } = StorageKey, KEYS = __rest(StorageKey, ["INVITE_CODE"]);
        Object.values(KEYS).forEach((key) => localStorage.removeItem(key));
        setIsLoggedIn(false);
    });
    const handleTokenValidation = () => {
        try {
            const token = localStorage.getItem(StorageKey.ACCESS_TOKEN);
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            if (decodedToken.exp < currentTime) {
                return false; // Token is expired
            }
            else {
                return true; // Token is valid
            }
        }
        catch (error) {
            return false; // If the token is invalid, treat it as invalid
        }
    };
    const handleAuth = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        try {
            uiCTX.dispatch(showLoading());
            const user = yield AuthService.getUserDetails();
            const phase = yield SchedulingService.getCurrentUserPhaseAndEvents();
            const config = yield ConfigService.getAppConfig();
            dispatch(setUserDetails(user));
            dispatch(setUserPhase(phase));
            configDispatch(setAppConfig(config));
            setLanguage((_b = (_a = user.data) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.locale);
            setIsLoggedIn(true);
        }
        catch (error) {
            handleLogout();
        }
        finally {
            uiCTX.dispatch(closeLoading());
        }
    });
    const handleCurrentRoute = () => {
        window.scrollTo(0, 0);
        const matchedRoutes = matchRoutes(routes, location.pathname);
        if (matchedRoutes) {
            const route = matchedRoutes[0].route;
            dispatch(setCurrentRoute(route));
        }
    };
    const handleRedirectRoute = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!isClinro) {
            const { requireAuth } = state.currentRoute;
            const isPublicRoute = publicRoutes.includes(location.pathname);
            if (url.isStudyCode) {
                const providers = yield AuthService.getIdentityProviders(url.studyCode);
                dispatch(setIdentityProviders(providers));
                navigate(ROUTE.IDENTITY_PROVIDER);
            }
            else {
                const isTokenValid = handleTokenValidation();
                if (!isLoggedIn) {
                    if (isTokenValid || (requireAuth && !isPublicRoute)) {
                        handleAuth();
                    }
                    return;
                }
                else {
                    if (!isTokenValid) {
                        handleLogout();
                    }
                }
                if (!webSupport) {
                    navigate(ROUTE.UNSUPPORTED);
                    return;
                }
                if (isPublicRoute) {
                    navigate(ROUTE.DASHBOARD);
                }
            }
        }
    });
    useEffect(() => {
        emitNativeWebView({
            payload: {
                path: location.pathname,
                title: state.currentRoute.title,
            },
            type: NativeAction.ROUTE,
        });
        handleRedirectRoute();
    }, [state.currentRoute, isLoggedIn]);
    useEffect(() => {
        handleCurrentRoute();
    }, [location.pathname]);
    const value = useMemo(() => ({
        dispatch,
        handleAuth,
        handleLogout,
        isLoggedIn,
        state,
    }), [state, isLoggedIn]);
    return _jsx(AuthContext.Provider, Object.assign({ value: value }, { children: children }));
};
