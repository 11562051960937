import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';
import { EventBase, Icon, Image } from '@/components';
import { useConfig } from '@/store';
import { APP_CONFIG, ROUTE } from '@/types';
export const ConfigBlockListItem = ({ destination_id, icon_id, text }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { getDestinationConfig } = useConfig();
    const handleEventClick = () => {
        var _a, _b;
        const nav = getDestinationConfig(destination_id);
        if (nav.type === APP_CONFIG.NAVIGATION.NavigationType.WEB_DESTINATION) {
            window.open((_a = nav.request) === null || _a === void 0 ? void 0 : _a.url, '_blank');
        }
        else {
            const url = generatePath(ROUTE.SCREEN, {
                id: (_b = nav.navigation_menu_item_id) !== null && _b !== void 0 ? _b : nav.destination,
            }).toLowerCase();
            navigate(url);
        }
    };
    return (_jsx(EventBase, { leftIcon: icon_id && _jsx(Image, { image_id: icon_id }), onClick: handleEventClick, rightIcon: _jsx(Icon, { color: "darkBrandBlue.100", name: "chevron-right", type: "solid" }), title: t(text) }));
};
