import { LANGUAGE } from '@/types';
import arAE from './ar-AE';
import bgBG from './bg-BG';
import csCZ from './cs-CZ';
import daDK from './da-DK';
import deDE from './de-DE';
import elGR from './el-GR';
import enGB from './en-GB';
import enUS from './en-US';
import esES from './es-ES';
import etET from './et-ET';
import fiFI from './fi-FI';
import frFR from './fr-FR';
import heIL from './he-IL';
import hiIN from './hi-IN';
import huHU from './hu-HU';
import hyAM from './hy-AM';
import itIT from './it-IT';
import jaJP from './ja-JP';
import koKO from './ko-KO';
import ltLT from './lt-LT';
import lvLV from './lv-LV';
import noNO from './nb-NO';
import nlNL from './nl-NL';
import plPL from './pl-PL';
import ptBR from './pt-BR';
import roRO from './ro-RO';
import ruRU from './ru-RU';
import skSK from './sk-SK';
import srRS from './sr-RS';
import svSE from './sv-SE';
import trTR from './tr-TR';
import ukUA from './uk-UA';
import zhCN from './zh-CN';
const lngs = {
    [LANGUAGE.ARABIC]: arAE,
    [LANGUAGE.ARMENIAN]: hyAM,
    [LANGUAGE.BULGARIAN]: bgBG,
    [LANGUAGE.CHINESE]: zhCN,
    [LANGUAGE.CZECH]: csCZ,
    [LANGUAGE.DANISH]: daDK,
    [LANGUAGE.DUTCH]: nlNL,
    [LANGUAGE.ENGLISH_GB]: enGB,
    [LANGUAGE.ENGLISH_US]: enUS,
    [LANGUAGE.ESTONIAN]: etET,
    [LANGUAGE.FINNISH]: fiFI,
    [LANGUAGE.FRENCH]: frFR,
    [LANGUAGE.GERMAN]: deDE,
    [LANGUAGE.GREEK]: elGR,
    [LANGUAGE.HEBREW]: heIL,
    [LANGUAGE.HINDI]: hiIN,
    [LANGUAGE.HUNGARIAN]: huHU,
    [LANGUAGE.ITALIAN]: itIT,
    [LANGUAGE.JAPANESE]: jaJP,
    [LANGUAGE.KOREAN]: koKO,
    [LANGUAGE.LATIN]: srRS,
    [LANGUAGE.LATVIAN]: lvLV,
    [LANGUAGE.LITHUANIAN]: ltLT,
    [LANGUAGE.NORWEGIAN]: noNO,
    [LANGUAGE.POLISH]: plPL,
    [LANGUAGE.PORTUGUESE]: ptBR,
    [LANGUAGE.ROMANIAN]: roRO,
    [LANGUAGE.RUSSIAN]: ruRU,
    [LANGUAGE.SLOVAK]: skSK,
    [LANGUAGE.SPANISH]: esES,
    [LANGUAGE.SWEDISH]: svSE,
    [LANGUAGE.TURKISH]: trTR,
    [LANGUAGE.UKRAINIAN]: ukUA,
};
export const resources = Object.entries(lngs).reduce((acc, [lng, resource]) => {
    const code = lng === LANGUAGE.ENGLISH_GB ? lng : lng.split('-')[0];
    acc[code] = resource;
    return acc;
}, {});
