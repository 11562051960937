var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Heading } from '@chakra-ui/react';
import { MarkdownBase } from '@/components';
export const ScreenIntroText = (_a) => {
    var { children, styles = {}, subtitle = '', title = '' } = _a, rest = __rest(_a, ["children", "styles", "subtitle", "title"]);
    const { on_primary = '' } = styles;
    return (_jsxs(Flex, Object.assign({ direction: "column", gridGap: "2", w: "100%" }, rest, { children: [_jsx(Heading, Object.assign({ color: on_primary, size: "lg" }, { children: title })), subtitle && _jsx(MarkdownBase, { color: on_primary, markdown: subtitle }), children] })));
};
