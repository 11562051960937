var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@chakra-ui/react';
export const CheckboxBase = (_a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return (_jsx(Box, Object.assign({ _checked: {
            bg: 'darkBrandBlue.40',
            color: 'white.100',
            borderColor: 'darkBrandBlue.40',
        }, _disabled: {
            bg: 'grey.60',
            color: 'darkBrandBlue.70',
            borderColor: 'grey.60',
            cursor: 'no-drop',
            _hover: {
                borderColor: 'grey.60',
            },
        }, _hover: {
            borderColor: 'darkBrandBlue.40',
        }, alignItems: "center", border: "2px solid", borderColor: "darkBrandBlue.10", borderRadius: "8", cursor: "pointer", display: "flex", fontSize: "md", gridGap: "4", h: "100%", minH: "60px", px: "35px", py: "4", w: "100%" }, rest, { children: children })));
};
