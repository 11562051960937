var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useResolveValue } from '@/hooks';
import { CaptureService } from '@/services';
import { setCachedQueryAnswer, useQuestionnaire } from '@/store';
import { CachedQueryDataType, SourcedDataMetaType } from '@/types';
export const useCachedQueries = () => {
    const questionnaireCTX = useQuestionnaire();
    const [resolver] = useResolveValue();
    const handleQuery = (query) => {
        const { type } = query;
        const key = resolver.resolveString(query.key);
        switch (type) {
            case SourcedDataMetaType.HAS_KEY: {
                return {
                    key,
                    type,
                };
            }
            case SourcedDataMetaType.FULL_MATCH: {
                return {
                    key,
                    type,
                    value: resolver.resolveString(query.value),
                };
            }
            default: {
                throw new Error(`${type} is an unsupported Cached Query Type`);
            }
        }
    };
    const handleQueryRequest = (cachedQuery) => __awaiter(void 0, void 0, void 0, function* () {
        const { type } = cachedQuery;
        switch (type) {
            case CachedQueryDataType.CLIENT_59_OVERLAP: {
                const { group_by_key } = cachedQuery;
                return CaptureService.overlap({ group_by_key });
            }
            case CachedQueryDataType.LATEST: {
                const { answer_keys: answers, query } = cachedQuery;
                return CaptureService.latestSourcedData({
                    answers,
                    query: handleQuery(query),
                });
            }
            default: {
                throw new Error(`${type} is an unsupported Cached Query`);
            }
        }
    });
    const handleCachedQueries = (cachedQueries = []) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const promises = cachedQueries.map(handleQueryRequest);
            const results = yield Promise.all(promises);
            questionnaireCTX.dispatch(setCachedQueryAnswer(results.flat()));
        }
        catch (error) {
            throw new Error(error);
        }
    });
    return {
        handleCachedQueries,
    };
};
