var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Text } from '@chakra-ui/layout';
import { useTranslation } from 'react-i18next';
import { BlockStyle } from '@/components';
export const ConfigInlineHeader = (_a) => {
    var { style_id, text } = _a, rest = __rest(_a, ["style_id", "text"]);
    const { t } = useTranslation();
    return (_jsx(BlockStyle, Object.assign({ borderRadius: "8px", px: "2", py: "1", style_id: style_id }, rest, { children: _jsx(Text, { children: t(text) }) })));
};
