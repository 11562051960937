import { UIActionType } from '@/types';
export const showLoading = (payload) => ({
    payload,
    type: UIActionType.SHOW_LOADING,
});
export const closeLoading = () => ({
    payload: undefined,
    type: UIActionType.CLOSE_LOADING,
});
export const setPrivateChecked = (payload) => ({
    payload,
    type: UIActionType.SET_PRIVATE_CHECKED,
});
