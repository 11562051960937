import { jsx as _jsx } from "react/jsx-runtime";
import { MarkdownBase } from '@/components';
import { useResolveValue } from '@/hooks';
import { convertToCSS } from '@/utils';
export const PlainText = ({ block: { content, id, layout } }) => {
    const [resolver] = useResolveValue();
    const resolved = {
        content: resolver.resolveString(content),
    };
    const styles = convertToCSS(layout);
    return _jsx(MarkdownBase, { "data-test": id, markdown: resolved.content, sx: styles });
};
