var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { UMOTIF_API } from '@/routes';
import { StorageKey, } from '@/types';
import { buildAPIUrl, getLocalStorage } from '@/utils';
import api from './instance';
const { AUTH, IDENTITY } = UMOTIF_API;
class AuthService {
    getIdentityProviders(studyCode) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.setAPIBaseURL(studyCode);
            const { data } = yield api.get(IDENTITY.IDPS, {
                params: {
                    study_code: studyCode,
                },
            });
            return data;
        });
    }
    getToken(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield api.post(IDENTITY.GET_TOKEN, payload);
            yield this.setAuthToken(data);
            return data;
        });
    }
    getUserDetails() {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield api.get(AUTH.ME);
            return data;
        });
    }
    register(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            return api.post(AUTH.REGISTER, payload);
        });
    }
    forgotPassword(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            return api.post(AUTH.PASSWORD_FORGOT, {
                identifier: payload,
            });
        });
    }
    resetPassword(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield api.post(AUTH.PASSWORD_RESET, payload);
            yield this.setAuthToken(data);
        });
    }
    login(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield api.post(AUTH.LOGIN, Object.assign({ grant_type: 'password', scope: '*' }, payload));
            yield this.setAuthToken(data);
        });
    }
    refreshToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const { refresh_token } = getLocalStorage();
            if (refresh_token) {
                const { data } = yield api.post(AUTH.REFRESH_TOKEN, {
                    grant_type: 'refresh_token',
                    refresh_token,
                    scope: '*',
                });
                yield this.setAuthToken(data);
                return data;
            }
            throw new Error('No refresh token');
        });
    }
    checkInvitationCode(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.setAPIBaseURL(payload);
            const { data } = yield api.get(AUTH.VALIDATE_INVITE_CODE(payload));
            return data;
        });
    }
    setAuthToken(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    let access_token;
                    let refresh_token;
                    if ('AccessToken' in payload) {
                        access_token = payload.AccessToken;
                        refresh_token = payload.RefreshToken;
                    }
                    else {
                        access_token = payload.access_token;
                        refresh_token = payload.refresh_token;
                    }
                    api.defaults.headers.common.Authorization = `Bearer ${access_token}`;
                    localStorage.setItem(StorageKey.ACCESS_TOKEN, access_token);
                    localStorage.setItem(StorageKey.REFRESH_TOKEN, refresh_token);
                    resolve(true);
                }
                catch (error) {
                    reject(false);
                }
            });
        });
    }
    setAPIBaseURL(invitationCode) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    const { apiURL } = buildAPIUrl(invitationCode);
                    localStorage.setItem(StorageKey.API_URL, apiURL);
                    api.defaults.baseURL = apiURL;
                    resolve(true);
                }
                catch (error) {
                    reject(false);
                }
            });
        });
    }
}
export default new AuthService();
