var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useRef, useState } from 'react';
export function usePolling(callback, options = {}) {
    const { interval = 4000, onError, startImmediately = false } = options;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const intervalId = useRef();
    const onErrorCallback = onError && useCallback(onError, []);
    const memoizedCallback = useCallback(callback, []);
    useEffect(() => {
        function pollData() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const result = yield memoizedCallback();
                    setData(result);
                    setError(null);
                }
                catch (err) {
                    if (err instanceof Error) {
                        setError(err);
                        if (onErrorCallback) {
                            onErrorCallback(err);
                        }
                    }
                }
                finally {
                    setLoading(false);
                }
            });
        }
        const pollInterval = setInterval(pollData, interval);
        intervalId.current = pollInterval;
        // Call immediately when first used.
        if (startImmediately)
            pollData();
        return () => {
            clearInterval(pollInterval);
            intervalId.current = undefined;
        };
    }, [memoizedCallback, interval, onErrorCallback]);
    const cancel = () => {
        if (intervalId.current) {
            clearInterval(intervalId.current);
        }
    };
    return { cancel, data, error, loading };
}
