var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { addComputedValue, useAuth, useQuestionnaire } from '@/store';
import { ActionType, } from '@/types';
import { Queue, ResolveValue, ValidateScene, getComputedAnswer, getObjListByKey, hasDuplicates, scrollPage, } from '@/utils';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useResolveValue from './useResolveValue';
export const useQuestionnaireForm = (submitQuestionnaire, submitPartial, setLoading) => {
    var _a;
    const { dispatch, mapComputedValues, state: { cachedQueryAnswers, current: { state: { answers }, }, partialSubmission, questionnaire: { entry_actions, exit_page, id, initial_pages, initial_post_submission_pages, post_submission_scenes, resolvers, scenes, submission_partials, }, }, } = useQuestionnaire();
    const { state: { currentUserPhaseSegment }, } = useAuth();
    const [resolver] = useResolveValue();
    const validateScene = new ValidateScene(answers, cachedQueryAnswers, currentUserPhaseSegment, resolvers, mapComputedValues);
    const { current: queue } = useRef(new Queue());
    const initialPageAnswers = useRef([]);
    const [currentPage, setCurrentPage] = useState(1);
    const checkForDuplicates = () => {
        if (hasDuplicates(queue.itemsArray)) {
            throw new Error('Duplicate pages are not allowed');
        }
    };
    const handleComputedValues = () => {
        const resolvedPage = resolver.resolveCurrentQueuePage();
        const computedScene = mapComputedValues.get(resolvedPage === null || resolvedPage === void 0 ? void 0 : resolvedPage.scene_key);
        if (computedScene && resolvedPage) {
            const computedValues = [...computedScene === null || computedScene === void 0 ? void 0 : computedScene.values()].map((x) => {
                const value = resolver.resolveComputedValue(x.resolver);
                return getComputedAnswer(value, x, resolvedPage);
            });
            dispatch(addComputedValue(computedValues));
        }
    };
    useEffect(() => {
        queue.start([]);
        handleConditions(entry_actions, false);
        [...initial_pages].forEach((page) => queue.add(page));
        queue.add(exit_page);
        queue.goToIndex(0);
        checkForDuplicates();
    }, [id]);
    useEffect(() => {
        if (submission_partials) {
            initialPageAnswers.current = getCurrentPageAnswers();
        }
        checkForDuplicates();
    }, [currentPage]);
    useEffect(() => {
        handleComputedValues();
    }, [currentPage, answers]);
    const scenesKeyList = useMemo(() => getObjListByKey([...scenes, ...post_submission_scenes], 'key'), [id]);
    const currentScene = scenesKeyList[(_a = queue.state.current) === null || _a === void 0 ? void 0 : _a.scene_key];
    const getCurrentPageAnswers = () => {
        const { repeat_index, repeat_key, scene_key } = queue.state.current;
        return answers.filter((x) => x.page_key.scene_key === scene_key &&
            (repeat_key ? x.page_key.repeat_key === repeat_key : true) &&
            (typeof repeat_index === 'number' ? x.page_key.repeat_index === repeat_index : true));
    };
    const getCurrentAnswers = (blockKey) => {
        // TODO: Check the logic of this assignment. Do we need this?
        // Added to fix an issue with optional repeat_key in initial_pages. Answers
        // were not being set correctly for datetime fields when repeat_key was not provided.
        const { repeat_index, repeat_key } = queue.state.current;
        const repeatKey = repeat_key !== undefined ? repeat_key : repeat_key === null ? null : '';
        return answers
            .filter((x) => x.block_key === blockKey &&
            x.page_key.scene_key === (currentScene === null || currentScene === void 0 ? void 0 : currentScene.key) &&
            (repeatKey ? x.page_key.repeat_key === repeatKey : true) &&
            (typeof repeat_index === 'number' ? x.page_key.repeat_index === repeat_index : true))
            .map((x) => x.typed_value.value);
    };
    const pageErrors = validateScene.blocks(currentScene);
    const pageConfirmations = validateScene.confirmations(currentScene);
    const canGoToNextPage = queue.canGoToNext;
    const canGoToPrevPage = queue.canGoToPrev;
    const canSubmitQuestionnaire = (currentScene === null || currentScene === void 0 ? void 0 : currentScene.key) === exit_page.scene_key;
    const handleActions = useCallback((action, resolve) => {
        switch (action.type) {
            case ActionType.CLEAR_ALL: {
                queue.clearAllFrom();
                queue.insert(exit_page);
                break;
            }
            case ActionType.DO_NOTHING:
            case ActionType.REMOVE_FROM_QUEUE: {
                break;
            }
            case ActionType.PUSH_ITERATIONS: {
                const totalIterations = resolve.resolveInteger(action.num_iterations);
                const iterationIndices = Array.from({ length: totalIterations }, (_, i) => i).reverse();
                const sceneKeys = action.scene_keys.reverse();
                const queueList = iterationIndices.flatMap((index) => sceneKeys.map((sceneKey) => ({
                    repeat_index: index,
                    repeat_key: null,
                    scene_key: sceneKey,
                })));
                queue.insert(queueList);
                break;
            }
            case ActionType.PUSH_PAGES_FROM_LIST: {
                const queueList = action.scene_keys
                    .flatMap((scene_key) => {
                    const sceneKeys = resolve.resolveStringList(action.repeat_keys);
                    return sceneKeys.map((repeat_key) => ({
                        repeat_index: null,
                        repeat_key,
                        scene_key,
                    }));
                })
                    .reverse();
                queue.insert(queueList);
                break;
            }
            case ActionType.PUSH_TO_START_OF_QUEUE: {
                const queueList = [...action.pages].reverse();
                queue.insert(queueList);
                break;
            }
            case ActionType.PUSH_WITH_INDEX: {
                const sceneKeys = action.scene_keys.reverse();
                const index = resolve.resolveInteger(action.index);
                const queueList = sceneKeys.map((sceneKey) => ({
                    repeat_index: index,
                    repeat_key: null,
                    scene_key: sceneKey,
                }));
                queue.insert(queueList);
                break;
            }
        }
    }, [exit_page]);
    const handleConditions = (actions, setHistory = true) => {
        return new Promise((resolve) => {
            const resolver = new ResolveValue(answers, cachedQueryAnswers, currentUserPhaseSegment, resolvers, mapComputedValues);
            let isHistorySet = false;
            actions.every(({ action, condition, stop_on_match }) => {
                let found = false;
                if (resolver.resolveBoolean(condition)) {
                    found = true;
                    if (!isHistorySet && setHistory) {
                        isHistorySet = true;
                        queue.setHistory();
                    }
                    handleActions(action, resolver);
                }
                return !(stop_on_match && found);
            });
            resolve(true);
        });
    };
    const handleLoadPartial = () => {
        if (partialSubmission === null || partialSubmission === void 0 ? void 0 : partialSubmission.partial.webQueue) {
            const { history, items, position } = partialSubmission === null || partialSubmission === void 0 ? void 0 : partialSubmission.partial.webQueue;
            queue.start(Object.values(items), history);
            queue.goToIndex(position);
        }
    };
    const handlePostSubmission = () => {
        queue.start(initial_post_submission_pages);
        setCurrentPage(1);
    };
    const handlePartialSubmission = () => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                if (submission_partials) {
                    const pageAnswers = getCurrentPageAnswers();
                    const isAnswersEqual = isEqual(pageAnswers, initialPageAnswers.current);
                    if (!isAnswersEqual) {
                        setLoading(true);
                        yield submitPartial();
                    }
                }
            }
            finally {
                setLoading(false);
                resolve(true);
            }
        }));
    });
    const goToNextPage = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (canSubmitQuestionnaire) {
            submitQuestionnaire().then(() => {
                handlePostSubmission();
            });
        }
        if (canGoToNextPage) {
            yield handlePartialSubmission();
            if (!('button_text' in currentScene)) {
                yield handleConditions(currentScene.exit_actions);
            }
            queue.goNext();
            scrollPage();
            setCurrentPage((step) => step + 1);
        }
    }), [canGoToNextPage, getCurrentAnswers]);
    const goToPrevPage = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (canGoToPrevPage) {
            yield handlePartialSubmission();
            queue.goPrev();
            scrollPage();
            setCurrentPage((step) => step - 1);
        }
    }), [canGoToPrevPage, getCurrentAnswers]);
    const goToPrevSection = useCallback((section) => __awaiter(void 0, void 0, void 0, function* () {
        const currentSectionPageKey = section.page_key.scene_key;
        const queueItemIndex = queue.itemsArray.findIndex((item) => item.scene_key === currentSectionPageKey);
        while (queueItemIndex < queue.position) {
            queue.goPrev();
            setCurrentPage((step) => step - 1);
        }
    }), []);
    return [
        currentScene,
        {
            canGoToNextPage,
            canGoToPrevPage,
            canSubmitQuestionnaire,
            currentPage,
            getCurrentAnswers,
            goToNextPage,
            goToPrevPage,
            goToPrevSection,
            handleLoadPartial,
            pageConfirmations,
            pageErrors,
            pageIsValid: pageErrors.size === 0,
            queue,
        },
    ];
};
export default useQuestionnaireForm;
