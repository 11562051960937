var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input, chakra, useCheckbox } from '@chakra-ui/react';
import { AnswerImage, CheckboxBase, MarkdownBase } from '@/components';
export const CheckboxItem = (_a) => {
    var { answer, answerChange, images = [], onChange } = _a, rest = __rest(_a, ["answer", "answerChange", "images", "onChange"]);
    const { getCheckboxProps, getInputProps, getLabelProps, htmlProps, state: { isChecked }, } = useCheckbox(rest);
    const handleChange = (event) => {
        const newAnswer = isChecked ? Object.assign(Object.assign({}, answer), { value: null }) : answer;
        onChange === null || onChange === void 0 ? void 0 : onChange(event);
        answerChange === null || answerChange === void 0 ? void 0 : answerChange(newAnswer);
    };
    return (_jsxs(chakra.label, Object.assign({ "data-test": answer.id, w: "100%" }, htmlProps, { children: [_jsx(Input, Object.assign({}, getInputProps(), { hidden: true, onChange: handleChange })), _jsxs(CheckboxBase, Object.assign({}, getCheckboxProps(), { children: [_jsx(AnswerImage, { answerKey: answer.key, imageList: images, isSelected: isChecked }), _jsx(MarkdownBase, Object.assign({}, getLabelProps(), { markdown: answer.label }))] }))] })));
};
