var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Checkbox, Link, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
export const FieldCheckbox = (_a) => {
    var { answerChange, data: { default: defaultChecked, key, label, required = false, url }, defaultValue, error, isInvalid } = _a, rest = __rest(_a, ["answerChange", "data", "defaultValue", "error", "isInvalid"]);
    const { t } = useTranslation();
    const labelText = t(label);
    const handleChange = (event) => {
        answerChange(key, event.target.checked);
    };
    return (_jsx(Checkbox, Object.assign({ defaultChecked: defaultChecked, isChecked: !!defaultValue, isInvalid: isInvalid, isRequired: required, onChange: handleChange, size: "lg" }, rest, { children: _jsxs(Box, Object.assign({ color: isInvalid && 'red.80' }, { children: [url ? (_jsx(Link, Object.assign({ href: url, target: "_blank" }, { children: labelText }))) : (labelText), error && _jsx(Text, Object.assign({ fontSize: "14px" }, { children: error }))] })) })));
};
