import { StorageKey } from '@/types';
import { buildSceneKey } from './helpers';
export class Queue {
    constructor(keys) {
        this.keys = Object.assign({ inserted: 'inserted' }, keys);
        this.items = {};
        this.history = {};
        this.position = 0;
    }
    goNext() {
        this.position += 1;
        this.setLocalData();
    }
    goPrev() {
        this.position -= 1;
        const page = this.state.current;
        const key = buildSceneKey(page);
        const history = this.history[key];
        if (history) {
            this.items = history;
            delete this.history[key];
        }
        this.setLocalData();
    }
    goToIndex(index) {
        this.position = index;
        this.setLocalData();
    }
    add(item) {
        this.items[this.length] = item;
    }
    start(items, history = {}) {
        this.reset();
        this.items = this.createItemsObj(items);
        this.history = history;
    }
    insert(item) {
        const index = this.position + 1;
        const items = Array.isArray(item) ? item : [item];
        items.forEach((x) => {
            this.items = this.insertAt(Object.assign(Object.assign({}, x), { [this.keys.inserted]: true }), index);
        });
    }
    insertAt(item, index = 0) {
        const items = this.itemsArray;
        items.splice(index, 0, item);
        return this.createItemsObj(items);
    }
    clearAllFrom(index) {
        const idx = index || this.position + 1;
        const items = this.itemsArray;
        this.items = this.createItemsObj(items.slice(0, idx));
    }
    setHistory() {
        const page = this.state.current;
        if (page && this.items) {
            const key = buildSceneKey(page);
            this.history[key] = this.items;
        }
    }
    reset() {
        this.items = {};
        this.history = {};
        this.position = 0;
    }
    createItemsObj(items) {
        return Object.fromEntries(items.map((x, i) => [i, x]));
    }
    setLocalData() {
        // TODO: The MR Below break Breaks the sceneKey as now it's a PageSelector, so we need to refactor that properly
        // https://gitlab.com/umotif/backend/tm-questionnaire-engine/-/merge_requests/425
        // This is a temporary fix to make the app work
        if (this.state.current) {
            localStorage.setItem(StorageKey.CURRENT_QUEUE_PAGE, JSON.stringify(this.state.current));
        }
    }
    get itemsArray() {
        return Object.values(this.items);
    }
    get state() {
        return {
            current: this.items[this.position],
            next: this.items[this.position + 1],
            prev: this.items[this.position - 1],
        };
    }
    get canGoToNext() {
        return this.state.next !== undefined;
    }
    get canGoToPrev() {
        return this.state.prev !== undefined;
    }
    get length() {
        return this.itemsArray.length;
    }
    get isEmpty() {
        return this.length === 0;
    }
}
