import { QuestionnaireExitActionType, ROUTE } from '@/types';
export class ResolveQuestionnaireExitAction {
    constructor() {
        this._actions = [];
    }
    set actions(actions) {
        this._actions = actions;
    }
    executeActions() {
        this._actions.forEach(({ action }) => {
            const { type } = action;
            switch (type) {
                case QuestionnaireExitActionType.LOGOUT: {
                    this.actionLogout();
                    break;
                }
                default: {
                    throw new Error(`Unknown questionnaire exit action type: ${type}`);
                }
            }
        });
    }
    actionLogout() {
        localStorage.clear();
        window.location.href = ROUTE.HOME;
    }
}
