import { jsx as _jsx } from "react/jsx-runtime";
import { APP_CONFIG } from '@/types';
const configScreenComponents = {
    [APP_CONFIG.COMPONENTS.ConfigScreenType.COVER]: null,
    [APP_CONFIG.COMPONENTS.ConfigScreenType.INPUT]: null,
    [APP_CONFIG.COMPONENTS.ConfigScreenType.INSTRUCTIONS]: null,
};
export const renderConfigScreen = (props) => {
    const Component = configScreenComponents[props.type];
    return _jsx(Component, Object.assign({}, props));
};
