var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { UMOTIF_API } from '@/routes';
import api from './instance';
const { DATA_CAPTURE } = UMOTIF_API;
class CaptureService {
    getQuestionnaire(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield api.get(DATA_CAPTURE.GET_QUESTIONNAIRE(payload));
            return data;
        });
    }
    getPartialSubmission(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield api.get(DATA_CAPTURE.GET_PARTIAL(payload));
            return data;
        });
    }
    latestSourcedData(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield api.post(DATA_CAPTURE.GET_LATEST_SOURCED_DATA, payload);
            return data.answers;
        });
    }
    overlap(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield api.post(DATA_CAPTURE.POST_OVERLAP, payload);
            return data.answers;
        });
    }
    startUpload(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield api.post(DATA_CAPTURE.POST_START_UPLOAD, payload);
            return data;
        });
    }
    submitQuestionnaire(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield api.post(DATA_CAPTURE.POST_SUBMISSIONS, payload);
            return data;
        });
    }
    submitPartialQuestionnaire(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield api.post(DATA_CAPTURE.POST_PARTIALS, payload);
            return data;
        });
    }
}
export default new CaptureService();
