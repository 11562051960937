import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// TODO: This component needs a refactor to be more readable and maintainable
import { Box, VStack } from '@chakra-ui/react';
import { isSameDay } from 'date-fns';
import { useMemo } from 'react';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { EventBase, Icon, Image } from '@/components';
import { setCurrentUserPhaseEvent, useAuth } from '@/store';
import { NativeAction, ROUTE } from '@/types';
import { ResolveDate, emitNativeWebView, getObjListByKey } from '@/utils';
export var EventStateTypes;
(function (EventStateTypes) {
    EventStateTypes["ACTIVE"] = "active";
    EventStateTypes["COMPLETED"] = "completed";
    EventStateTypes["EXPIRED"] = "expired";
    EventStateTypes["LOCKED"] = "locked";
})(EventStateTypes || (EventStateTypes = {}));
const resolveDate = new ResolveDate();
export const EventListItem = (props) => {
    var _a, _b, _c;
    const { completed, is_dependency_locked = undefined, meta: _meta, window_end, window_start, } = props;
    const meta = getObjListByKey(_meta, 'key', 'value');
    const navigate = useNavigate();
    const { dispatch } = useAuth();
    const event = useMemo(() => {
        const dateNow = new Date();
        const windowStart = new Date(window_start);
        const windowEnds = new Date(window_end);
        const isBeforeWindow = dateNow < windowStart;
        const isAfterWindow = dateNow > windowEnds;
        const isWithinWindow = !isBeforeWindow && !isAfterWindow;
        let status = EventStateTypes.ACTIVE;
        if (completed)
            status = EventStateTypes.COMPLETED;
        if (isBeforeWindow || is_dependency_locked)
            status = EventStateTypes.LOCKED;
        if (isAfterWindow)
            status = EventStateTypes.EXPIRED;
        return {
            dateNow,
            isAfterWindow,
            isBeforeWindow,
            isWithinWindow,
            status,
            windowEnds,
            windowStart,
        };
    }, [props]);
    const handleEventClick = () => {
        if (event.status === EventStateTypes.ACTIVE) {
            const path = generatePath(ROUTE.QUESTIONNAIRE, { id: meta === null || meta === void 0 ? void 0 : meta.questionnaire_id });
            dispatch(setCurrentUserPhaseEvent(props));
            emitNativeWebView({
                payload: {
                    path,
                    title: meta === null || meta === void 0 ? void 0 : meta.name,
                },
                type: NativeAction.LINK,
            }, () => navigate(path));
        }
    };
    const IconState = () => {
        switch (event.status) {
            case EventStateTypes.ACTIVE: {
                return _jsx(Icon, { name: "chevron-right", type: "solid" });
            }
            case EventStateTypes.COMPLETED: {
                return _jsx(Icon, { color: (meta === null || meta === void 0 ? void 0 : meta.text_colour) || 'green.60', name: "check", type: "solid" });
            }
            case EventStateTypes.LOCKED: {
                const { dateNow, isWithinWindow, windowStart } = event;
                const isEventSameDay = isSameDay(windowStart, dateNow);
                const formattedDate = resolveDate.formatDateLocalized(windowStart);
                const shouldShowTime = !(isWithinWindow && is_dependency_locked !== undefined);
                const displayTime = isEventSameDay ? formattedDate.time : formattedDate.date;
                return (_jsxs(VStack, Object.assign({ fontSize: "xs", spacing: "0" }, { children: [_jsx(Icon, { m: "auto", name: "padlock", type: "solid" }), shouldShowTime && _jsx(Box, { children: displayTime })] })));
            }
            default: {
                return null;
            }
        }
    };
    return (_jsx(EventBase, { bg: meta === null || meta === void 0 ? void 0 : meta.background_colour, className: event.status, color: meta === null || meta === void 0 ? void 0 : meta.text_colour, cursor: "not-allowed", leftIcon: (meta === null || meta === void 0 ? void 0 : meta.icon_path) && _jsx(Image, { h: "24px", path: meta.icon_path }), onClick: handleEventClick, rightIcon: _jsx(IconState, {}), sx: {
            '&.active': {
                cursor: 'pointer',
            },
            '&.expired': {
                display: 'none',
            },
            '&.completed': {
                bg: 'grey.60',
                color: 'darkBrandBlue.70',
            },
            '&.locked': {
                bg: (_a = meta === null || meta === void 0 ? void 0 : meta.background_colour) !== null && _a !== void 0 ? _a : 'white.100',
                color: (_b = meta === null || meta === void 0 ? void 0 : meta.text_colour) !== null && _b !== void 0 ? _b : 'darkBrandBlue.70',
                borderColor: (_c = meta === null || meta === void 0 ? void 0 : meta.background_colour) !== null && _c !== void 0 ? _c : 'grey.60',
                border: '2px solid',
            },
        }, title: meta === null || meta === void 0 ? void 0 : meta.name }));
};
