var envUrls;
(function (envUrls) {
    envUrls["LOCAL"] = "li0031.local.umotif.io:3000";
    envUrls["INT"] = "trial.int.umotif.io";
    envUrls["VALIDATION"] = "trial.val.umotif.io";
    envUrls["PROD"] = "trial.umotif.io";
})(envUrls || (envUrls = {}));
export const localAPIUrl = 'https://li003-eu.int.umotif.io';
const getEnvConfig = (url) => {
    if (url === envUrls.LOCAL)
        return {
            ENV_NAME: 'local',
        };
    if (url === envUrls.INT)
        return {
            ENV_NAME: 'int',
        };
    if (url === envUrls.VALIDATION)
        return {
            ENV_NAME: 'val',
        };
    return {
        ENV_NAME: 'prod',
    };
};
export default getEnvConfig(window.location.host);
