var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _ResolveValue_answers, _ResolveValue_cachedAnswers, _ResolveValue_segment, _ResolveValue_reusableResolvers, _ResolveValue_computedValues;
import { v4 as uuidv4 } from 'uuid';
import { CachedQueryLatestSourcedDataType, ComputedType, ConditionType, Operator, ResolverType, ReusableResolverType, ValuesType, } from '@/types';
import { ResolveDate, convertTimeToMinutes, decimalSeparator, getLocalStorage, getMapByKey, parseTime, timeZoneName, } from '.';
export class ResolveValue extends ResolveDate {
    constructor(answers, cachedAnswers = [], segment, reusableResolvers, computedValues) {
        super();
        _ResolveValue_answers.set(this, void 0);
        _ResolveValue_cachedAnswers.set(this, void 0);
        _ResolveValue_segment.set(this, void 0);
        _ResolveValue_reusableResolvers.set(this, void 0);
        _ResolveValue_computedValues.set(this, void 0);
        __classPrivateFieldSet(this, _ResolveValue_answers, answers, "f");
        __classPrivateFieldSet(this, _ResolveValue_cachedAnswers, cachedAnswers, "f");
        __classPrivateFieldSet(this, _ResolveValue_segment, segment, "f");
        __classPrivateFieldSet(this, _ResolveValue_reusableResolvers, reusableResolvers, "f");
        __classPrivateFieldSet(this, _ResolveValue_computedValues, computedValues, "f");
    }
    resolveAllAnswers(bKey, sKey, aKey) {
        return __classPrivateFieldGet(this, _ResolveValue_answers, "f")
            .filter((a) => a.answered &&
            a.block_key === bKey &&
            a.page_key.scene_key === sKey &&
            (aKey ? a.answer_key === aKey : true))
            .sort((a, b) => { var _a, _b; return ((_a = a === null || a === void 0 ? void 0 : a.meta) === null || _a === void 0 ? void 0 : _a.order) - ((_b = b === null || b === void 0 ? void 0 : b.meta) === null || _b === void 0 ? void 0 : _b.order); });
    }
    resolveAnswer(bKey, page, aKey, options = {}) {
        const { returnKey = 'typed_value.value', shouldCrash = true } = options;
        const { repeat_index, repeat_key, scene_key } = page;
        const answer = __classPrivateFieldGet(this, _ResolveValue_answers, "f").find((a) => a.block_key === bKey &&
            a.page_key.scene_key === scene_key &&
            (aKey ? a.answer_key === aKey : true) &&
            (repeat_key ? a.page_key.repeat_key === repeat_key : true) &&
            (typeof repeat_index === 'number' ? a.page_key.repeat_index === repeat_index : true));
        if (shouldCrash && !answer && __classPrivateFieldGet(this, _ResolveValue_answers, "f").length > 0) {
            this.resolveCrash(`Resolve Answer - Answer does not exist for: block_key: ${bKey}, scene_key: ${scene_key}, answer_key: ${aKey}}}`);
        }
        // @ts-ignore
        return returnKey.split('.').reduce((acc, key) => acc && acc[key], answer);
    }
    resolveAnswerCachedQuery(key) {
        var _a;
        const answer = this.getAnswersCachedMap.get(key);
        return (_a = answer === null || answer === void 0 ? void 0 : answer.typed_value) === null || _a === void 0 ? void 0 : _a.value;
    }
    resolveComputedAnswer(computed) {
        var _a;
        const page = this.resolvePage(computed.page_resolver);
        const { resolver } = (_a = __classPrivateFieldGet(this, _ResolveValue_computedValues, "f").get(page.scene_key)) === null || _a === void 0 ? void 0 : _a.get(computed.computed_value_key);
        return this.resolveComputedValue(resolver);
    }
    resolveComputedValue(resolver) {
        const typeComputedMap = {
            [ComputedType.BOOLEAN]: this.resolveBoolean.bind(this),
            [ComputedType.DECIMAL]: this.resolveDecimal.bind(this),
            [ComputedType.INTEGER]: this.resolveInteger.bind(this),
            [ComputedType.STRING]: this.resolveString.bind(this),
            [ComputedType.ZONED_DATETIME]: this.resolveZonedDateTime.bind(this),
        };
        const resolverComputed = typeComputedMap[resolver.type];
        return resolverComputed(resolver.value);
    }
    resolveCrash(message) {
        throw new Error(message);
    }
    resolveCurrentQueuePage() {
        const localData = getLocalStorage();
        const page = JSON.parse((localData === null || localData === void 0 ? void 0 : localData.current_queue_page) || '{}');
        return Object.assign({ repeat_index: null, repeat_key: null }, page);
    }
    resolveLatestSourcedData(resolver, fallback, valueType) {
        const key = this.resolveString(resolver);
        const value = this.getAnswersCachedMap.get(key);
        if (value && value.typed_value.type !== valueType) {
            this.resolveCrash('Cached Query - Value type does not match');
        }
        return this.resolveAnswerCachedQuery(key) || fallback;
    }
    resolveOperatorArithmetic(operator, leftValue, rightValue, options = {
        useArithmeticBitwise: true,
    }) {
        const operations = {
            [Operator.Arithmetic.ADDITION]: (a, b) => a + b,
            [Operator.Arithmetic.DIVISION]: (a, b) => {
                if (b === 0)
                    this.resolveCrash('Resolve Operator - Cannot divide by zero');
                return options.useArithmeticBitwise ? ~~(a / b) : a / b;
            },
            [Operator.Arithmetic.MODULO]: (a, b) => {
                if (b === 0)
                    this.resolveCrash('Resolve Operator - Modulo divisor cannot be zero');
                return a % b;
            },
            [Operator.Arithmetic.MULTIPLICATION]: (a, b) => a * b,
            [Operator.Arithmetic.SUBTRACTION]: (a, b) => a - b,
        };
        return operations[operator](leftValue, rightValue);
    }
    resolveOperatorBasic(operator, leftValue, rightValue) {
        const operations = {
            [Operator.Basic.EQUALS]: (a, b) => a === b,
            [Operator.Basic.GREATER_THAN]: (a, b) => a > b,
            [Operator.Basic.GREATER_THAN_OR_EQUALS]: (a, b) => a >= b,
            [Operator.Basic.LESS_THAN]: (a, b) => a < b,
            [Operator.Basic.LESS_THAN_OR_EQUALS]: (a, b) => a <= b,
            [Operator.Basic.NOT_EQUALS]: (a, b) => a !== b,
        };
        return operations[operator](leftValue, rightValue);
    }
    resolveResolvers(key, type) {
        const list = __classPrivateFieldGet(this, _ResolveValue_reusableResolvers, "f")[type];
        const resolver = list.find((x) => x.resolver_key === key && this.resolveBoolean(x.matcher));
        if (!resolver)
            this.resolveCrash(`Reusable Resolvers - resolver not found for key ${key}`);
        return resolver;
    }
    resolveTimeUnits(units) {
        const timeUnits = {};
        for (const [key, value] of Object.entries(units)) {
            const resolvedValue = this.resolveInteger(value);
            timeUnits[key] = resolvedValue;
        }
        return timeUnits;
    }
    resolveAnswerFilter(resolver) {
        if (!resolver)
            return [];
        if (Array.isArray(resolver)) {
            return resolver.reduce((acc, curr) => [...acc, ...this.resolveAnswerFilter(curr)], []);
        }
        const { type } = resolver;
        switch (type) {
            case ResolverType.FILTER_ANSWER_KEY_MATCH: {
                return this.resolveStringList(resolver.answer_keys);
            }
            default: {
                this.resolveCrash(`${type} is an unsupported answer filter resolver`);
            }
        }
    }
    resolveBoolean(resolver = false) {
        if (typeof resolver === 'boolean')
            return resolver;
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const resolved = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.BOOLEAN);
                return this.resolveBoolean(resolved.resolver);
            }
            case ResolverType.GET_COMPUTED_VALUE: {
                return this.resolveComputedAnswer(resolver);
            }
            case CachedQueryLatestSourcedDataType.BOOLEAN_EXISTS: {
                const key = this.resolveString(resolver.value_key);
                return this.getAnswersCachedMap.has(key);
            }
            case CachedQueryLatestSourcedDataType.BOOLEAN_VALUE: {
                const fallback = this.resolveBoolean(resolver.if_none);
                return this.resolveLatestSourcedData(resolver.value_key, fallback, ValuesType.BOOLEAN);
            }
            case CachedQueryLatestSourcedDataType.CLIENT_59_DATE_OVERLAP: {
                const { end_date_answer, start_date_answer, target_end_date_key, target_start_date_key } = resolver;
                const startDate = new Date(this.resolveZonedDateTime(start_date_answer));
                const endDate = new Date(this.resolveZonedDateTime(end_date_answer));
                const targetStartDate = new Date(this.resolveAnswerCachedQuery(target_start_date_key));
                const targetEndDate = new Date(this.resolveAnswerCachedQuery(target_end_date_key));
                const isOngoing = isNaN(targetEndDate.getTime()) && startDate >= targetStartDate;
                const isOverlapping = startDate <= targetEndDate && endDate >= targetStartDate;
                return isOngoing || isOverlapping;
            }
            case ConditionType.ALL_OF: {
                return resolver.conditions.every((c) => this.resolveBoolean(c));
            }
            case ConditionType.ALWAYS: {
                return true;
            }
            case ConditionType.ANY_OF: {
                return resolver.conditions.some((c) => this.resolveBoolean(c));
            }
            case ConditionType.DATE_COMPARISON: {
                const { left_hand_side, operator, right_hand_side } = resolver;
                const leftValue = this.resolveDate(left_hand_side).getTime();
                const rightValue = this.resolveDate(right_hand_side).getTime();
                return this.resolveOperatorBasic(operator, leftValue, rightValue);
            }
            case ConditionType.DECIMAL_COMPARISON: {
                const { left_hand_side, operator, right_hand_side } = resolver;
                const leftValue = this.resolveDecimal(left_hand_side);
                const rightValue = this.resolveDecimal(right_hand_side);
                return this.resolveOperatorBasic(operator, leftValue, rightValue);
            }
            case ConditionType.INTEGER_COMPARISON: {
                const { left_hand_side, operator, right_hand_side } = resolver;
                const leftValue = this.resolveInteger(left_hand_side);
                const rightValue = this.resolveInteger(right_hand_side);
                return this.resolveOperatorBasic(operator, leftValue, rightValue);
            }
            case ConditionType.STRING_COMPARISON: {
                const { left_hand_side, right_hand_side } = resolver;
                const leftValue = this.resolveString(left_hand_side);
                const rightValue = this.resolveString(right_hand_side);
                return leftValue === rightValue;
            }
            case ConditionType.IS_FALSE: {
                return !this.resolveBoolean(resolver.value);
            }
            case ConditionType.IS_TRUE: {
                const value = this.resolveBoolean(resolver.value);
                return Boolean(value);
            }
            case ConditionType.NEVER: {
                return false;
            }
            case ConditionType.TIME_COMPARISON: {
                const { left_hand_side, operator, right_hand_side } = resolver;
                const leftResolved = this.resolveTime(left_hand_side);
                const rightResolved = this.resolveTime(right_hand_side);
                const leftValue = convertTimeToMinutes(leftResolved);
                const rightValue = convertTimeToMinutes(rightResolved);
                return this.resolveOperatorBasic(operator, leftValue, rightValue);
            }
            case ConditionType.TIME_ZONE_EQUALITY: {
                const { left_hand_side, right_hand_side } = resolver;
                const leftValue = this.resolveTimezone(left_hand_side);
                const rightValue = this.resolveTimezone(right_hand_side);
                // case leftValue or rightValue is undefined - Answers not Exists
                // Validations will need to be refactored as it should only run when answered answer is valid
                if (typeof leftValue === 'undefined' || typeof rightValue === 'undefined') {
                    return true;
                }
                return leftValue === rightValue;
            }
            case ConditionType.ZONED_DATE_TIME_COMPARISON: {
                const { left_hand_side, operator, right_hand_side } = resolver;
                const leftValue = new Date(this.resolveZonedDateTime(left_hand_side)).getTime();
                const rightValue = new Date(this.resolveZonedDateTime(right_hand_side)).getTime();
                return this.resolveOperatorBasic(operator, leftValue, rightValue);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                return this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key);
            }
            case ResolverType.BOOLEAN_ANSWER: {
                return this.resolveAnswer(resolver.block_key, resolver.page_key);
            }
            case ResolverType.BOOLEAN_CRASH: {
                this.resolveCrash(resolver.message);
                break;
            }
            case ResolverType.BOOLEAN_IS_ANSWERED:
            case ResolverType.BOOLEAN_IS_ANSWERED_V2: {
                const page = 'page_key' in resolver ? resolver.page_key : this.resolvePage(resolver.page_resolver);
                const answer = this.resolveAnswer(resolver.block_key, page, resolver.answer_key, {
                    shouldCrash: false,
                });
                return answer !== undefined;
            }
            case ResolverType.BOOLEAN_REPEAT_KEY_IS_ANSWER_KEY: {
                const { repeat_key } = this.resolveCurrentQueuePage();
                const answers = this.resolveAllAnswers(resolver.block_key, resolver.scene_key);
                return answers.some((a) => a.answer_key === repeat_key);
            }
            case ResolverType.BOOLEAN_REPEAT_KEY_IS_CONTAINED_IN: {
                const { repeat_key } = this.resolveCurrentQueuePage();
                return resolver.values.includes(repeat_key);
            }
            default: {
                this.resolveCrash(`${type} is an unsupported boolean resolver`);
            }
        }
    }
    resolveDate(resolver) {
        if (!resolver)
            return undefined;
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const resolved = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.DATE);
                return this.resolveDate(resolved.resolver);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                const value = this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key);
                return new Date(value);
            }
            case ResolverType.DATE: {
                return new Date(resolver.value);
            }
            case ResolverType.DATE_CURRENT: {
                return new Date(this.formatDate().date);
            }
            case ResolverType.DATE_MODIFICATION: {
                const { type, value } = resolver, units = __rest(resolver, ["type", "value"]);
                const timeUnits = this.resolveTimeUnits(units);
                const date = this.resolveDate(value);
                return this.formatDateModified(date, timeUnits);
            }
            default: {
                this.resolveCrash(`${type} is an unsupported date resolver`);
            }
        }
    }
    resolveDecimal(resolver) {
        if (!resolver)
            return undefined;
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const resolved = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.DECIMAL);
                return this.resolveDecimal(resolved.resolver);
            }
            case ResolverType.GET_COMPUTED_VALUE: {
                return this.resolveComputedAnswer(resolver);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                const value = this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key);
                return parseFloat(value === null || value === void 0 ? void 0 : value.parsed);
            }
            case ResolverType.DECIMAL: {
                return parseFloat(resolver.value);
            }
            case ResolverType.DECIMAL_ARITHMETIC_BASIC: {
                const { left_hand_side, operator, right_hand_side } = resolver;
                const leftValue = this.resolveDecimal(left_hand_side);
                const rightValue = this.resolveDecimal(right_hand_side);
                return this.resolveOperatorArithmetic(operator, leftValue, rightValue, {
                    useArithmeticBitwise: false,
                });
            }
            case ResolverType.INTEGER: {
                return resolver.value;
            }
            default: {
                this.resolveCrash(`${type} is an unsupported decimal resolver`);
            }
        }
    }
    resolveDependency(resolver) {
        if (!resolver)
            return [];
        if (Array.isArray(resolver)) {
            return resolver.reduce((acc, curr) => [...acc, ...this.resolveDependency(curr)], []);
        }
        const { type } = resolver;
        switch (type) {
            case ResolverType.DEPENDENCY_ANSWER: {
                const { block_key, page_resolver } = resolver;
                const page = this.resolvePage(page_resolver);
                return this.resolveAllAnswers(block_key, page.scene_key);
            }
            default: {
                this.resolveCrash(`${type} is an unsupported dependency resolver`);
            }
        }
    }
    resolveFloat(resolver) {
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const resolved = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.FLOAT);
                return this.resolveFloat(resolved.resolver);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                return this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key);
            }
            case ResolverType.FLOAT: {
                return resolver.value;
            }
            case ResolverType.FLOAT_ANSWER: {
                return this.resolveAnswer(resolver.block_key, resolver.page_key);
            }
            default: {
                this.resolveCrash(`${type} is an unsupported float resolver`);
            }
        }
    }
    resolveInteger(resolver) {
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const resolved = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.INTEGER);
                return this.resolveInteger(resolved.resolver);
            }
            case ResolverType.GET_COMPUTED_VALUE: {
                return this.resolveComputedAnswer(resolver);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                const value = this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key);
                return Number(value);
            }
            case CachedQueryLatestSourcedDataType.INTEGER_VALUE: {
                const fallback = this.resolveInteger(resolver.if_none);
                return this.resolveLatestSourcedData(resolver.value_key, fallback, ValuesType.INTEGER);
            }
            case ResolverType.INTEGER: {
                return resolver.value;
            }
            case ResolverType.INTEGER_ABSOLUTE: {
                return Math.abs(this.resolveInteger(resolver.input));
            }
            case ResolverType.INTEGER_ANSWER: {
                const value = this.resolveAnswer(resolver.block_key, resolver.page_key);
                return Number(value);
            }
            case ResolverType.INTEGER_ANSWER_COUNT: {
                const page = this.resolvePage(resolver.page_resolver);
                return this.resolveAllAnswers(resolver.block_key, page.scene_key).length;
            }
            case ResolverType.INTEGER_ARITHMETIC_BASIC: {
                const { left_hand_side, operator, right_hand_side } = resolver;
                const leftValue = this.resolveInteger(left_hand_side);
                const rightValue = this.resolveInteger(right_hand_side);
                return this.resolveOperatorArithmetic(operator, leftValue, rightValue);
            }
            case ResolverType.INTEGER_CRASH: {
                this.resolveCrash(resolver.message);
                break;
            }
            case ResolverType.INTEGER_CURRENT_PAGE_REPEAT_INDEX: {
                const { repeat_index } = this.resolveCurrentQueuePage();
                return repeat_index;
            }
            case ResolverType.INTEGER_DAY_DIFFERENCE: {
                const { left_hand_side, right_hand_side } = resolver;
                const leftValue = new Date(this.resolveZonedDateTime(left_hand_side)).getTime();
                const rightValue = new Date(this.resolveZonedDateTime(right_hand_side)).getTime();
                const millisecondsDifference = rightValue - leftValue;
                const millisecondsPerDay = 1000 * 60 * 60 * 24;
                return (Math.sign(millisecondsDifference) *
                    Math.floor(Math.abs(millisecondsDifference) / millisecondsPerDay));
            }
            case ResolverType.INTEGER_MAX_FROM_RANGE:
            case ResolverType.INTEGER_MIN_FROM_RANGE: {
                const answer = this.resolveRange(resolver.range);
                return type === ResolverType.INTEGER_MAX_FROM_RANGE ? answer === null || answer === void 0 ? void 0 : answer.max : answer === null || answer === void 0 ? void 0 : answer.min;
            }
            case ResolverType.INTEGER_SWITCH: {
                const condition = resolver.cases.find((c) => this.resolveBoolean(c.condition));
                return this.resolveInteger((condition === null || condition === void 0 ? void 0 : condition.value) || resolver.fallback);
            }
            default: {
                this.resolveCrash(`${type} is an unsupported integer resolver`);
            }
        }
    }
    resolvePage(resolver) {
        const { type } = resolver;
        switch (type) {
            case ResolverType.PAGE_BUILDER: {
                return {
                    repeat_index: null,
                    repeat_key: this.resolveString(resolver.repeat_key_builder),
                    scene_key: resolver.scene_key,
                };
            }
            case ResolverType.PAGE_CURRENT: {
                return this.resolveCurrentQueuePage();
            }
            default: {
                this.resolveCrash(`${type} is an unsupported page resolver`);
            }
        }
    }
    resolveRange(resolver) {
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const resolved = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.RANGE);
                return this.resolveRange(resolved.resolver);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                return this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key);
            }
            case ResolverType.RANGE: {
                return resolver.value;
            }
            case ResolverType.RANGE_ANSWER: {
                return this.resolveAnswer(resolver.block_key, resolver.page_key);
            }
            default: {
                this.resolveCrash(`${type} is an unsupported range resolver`);
            }
        }
    }
    resolveString(resolver) {
        var _a;
        if (!resolver)
            return '';
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const resolved = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.STRING);
                return this.resolveString(resolved.resolver);
            }
            case ResolverType.GET_COMPUTED_VALUE: {
                return this.resolveComputedAnswer(resolver);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                return this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key);
            }
            case CachedQueryLatestSourcedDataType.STRING_VALUE: {
                const fallback = this.resolveString(resolver.if_none);
                return this.resolveLatestSourcedData(resolver.value_key, fallback, ValuesType.STRING);
            }
            case ResolverType.STRING: {
                return resolver.value;
            }
            case ResolverType.STRING_ANSWER: {
                return this.resolveAnswer(resolver.block_key, resolver.page_key);
            }
            case ResolverType.STRING_ANSWER_LABEL: {
                const { answer_key, block_key, page_resolver } = resolver;
                const answerKey = this.resolveString(answer_key);
                const page = this.resolvePage(page_resolver);
                return this.resolveAnswer(block_key, page, answerKey, {
                    returnKey: 'label',
                });
            }
            case ResolverType.STRING_CONVERSION_FROM_DATE: {
                const value = this.resolveDate(resolver.input);
                return this.formatDateLocalized(value).date;
            }
            case ResolverType.STRING_CONVERSION_FROM_DECIMAL: {
                const { input, num_decimal_places: decimal } = resolver;
                const value = this.resolveDecimal(input);
                // @ts-ignore
                const formatted = Number(Math.round(value + 'e' + decimal) + 'e-' + decimal);
                return (_a = formatted === null || formatted === void 0 ? void 0 : formatted.toString()) === null || _a === void 0 ? void 0 : _a.replace(/\.|,/g, decimalSeparator);
            }
            case ResolverType.STRING_CONVERSION_FROM_INTEGER: {
                return String(this.resolveInteger(resolver.input));
            }
            case ResolverType.STRING_CONVERSION_FROM_TIME: {
                const value = this.resolveTime(resolver.input);
                const { hours, minutes } = parseTime(value);
                const date = new Date();
                date.setHours(hours, minutes, 0, 0);
                return this.formatDateLocalized(date).time;
            }
            case ResolverType.STRING_CONVERSION_FROM_ZONED_DATE_TIME: {
                const zonedDateTime = new Date(this.resolveZonedDateTime(resolver.input));
                const formatter = this.formatDateLocalized(zonedDateTime);
                return formatter[resolver.format.type];
            }
            case ResolverType.STRING_CRASH: {
                this.resolveCrash(resolver.message);
                break;
            }
            case ResolverType.STRING_CURRENT_PAGE_REPEAT_KEY: {
                const { repeat_key } = this.resolveCurrentQueuePage();
                return repeat_key;
            }
            case ResolverType.STRING_INTERPOLATABLE: {
                const { display, interpolatable_values } = resolver;
                return interpolatable_values.reduce((interpolatedValue, { key, value }) => {
                    return interpolatedValue.replace(`{{${key}}}`, this.resolveString(value));
                }, display);
            }
            case ResolverType.STRING_SEGMENT_ORIGINATOR: {
                const value = __classPrivateFieldGet(this, _ResolveValue_segment, "f").originator[resolver.property];
                if (!value)
                    this.resolveCrash('Segment originator does not match');
                return value;
            }
            case ResolverType.STRING_SWITCH: {
                const condition = resolver.cases.find((c) => this.resolveBoolean(c.condition));
                return this.resolveString((condition === null || condition === void 0 ? void 0 : condition.value) || resolver.fallback);
            }
            case ResolverType.STRING_UUID_V4: {
                return uuidv4();
            }
            default: {
                this.resolveCrash(`${type} is an unsupported string resolver`);
            }
        }
    }
    resolveStringList(resolver) {
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const resolved = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.STRING_LIST);
                return this.resolveStringList(resolved.resolver);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                return this.resolveAllAnswers(resolver.block_key, page.scene_key, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key).map((a) => a.typed_value.value);
            }
            case ResolverType.LIST_STRING_ANSWERED_ANSWER_KEYS: {
                const page = this.resolvePage(resolver.page_resolver);
                return this.resolveAllAnswers(resolver.block_key, page.scene_key).map((a) => a.answer_key);
            }
            default: {
                this.resolveCrash(`${type} is an unsupported list string resolver`);
            }
        }
    }
    resolveTime(resolver) {
        if (!resolver)
            return '';
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const resolved = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.TIME);
                return this.resolveTime(resolved.resolver);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                return this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key);
            }
            case ResolverType.TIME: {
                return resolver.value;
            }
            default: {
                this.resolveCrash(`${type} is an unsupported time resolver`);
            }
        }
    }
    resolveTimezone(resolver) {
        if (!resolver)
            return '';
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const resolved = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.TIMEZONE);
                return this.resolveTimezone(resolved.resolver);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                return this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key);
            }
            case CachedQueryLatestSourcedDataType.TIME_ZONE_VALUE: {
                const fallback = this.resolveTimezone(resolver.if_none);
                return this.resolveLatestSourcedData(resolver.value_key, fallback, ValuesType.TIMEZONE);
            }
            case ResolverType.TIMEZONE: {
                return resolver.value;
            }
            case ResolverType.TIMEZONE_ANSWER: {
                return this.resolveAnswer(resolver.block_key, resolver.page_key, resolver.answer_key);
            }
            case ResolverType.TIMEZONE_CRASH: {
                this.resolveCrash(resolver.message);
                break;
            }
            case ResolverType.TIMEZONE_DEVICE: {
                return timeZoneName;
            }
            default: {
                this.resolveCrash(`${type} is an unsupported timezone resolver`);
            }
        }
    }
    resolveZonedDateTime(resolver) {
        if (!resolver)
            return '';
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const resolved = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.ZONED_DATETIME);
                return this.resolveZonedDateTime(resolved.resolver);
            }
            case ResolverType.GET_COMPUTED_VALUE: {
                return this.resolveComputedAnswer(resolver);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                return this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key);
            }
            case CachedQueryLatestSourcedDataType.ZONED_DATE_TIME_VALUE: {
                const fallback = this.resolveZonedDateTime(resolver.if_none);
                return this.resolveLatestSourcedData(resolver.value_key, fallback, ValuesType.ZONED_DATETIME);
            }
            case ResolverType.ZONED_DATETIME: {
                return resolver.value;
            }
            case ResolverType.ZONED_DATETIME_ANSWER: {
                return this.resolveAnswer(resolver.block_key, resolver.page_key, resolver.answer_key);
            }
            case ResolverType.ZONED_DATETIME_CRASH: {
                this.resolveCrash(resolver.message);
                break;
            }
            case ResolverType.ZONED_DATETIME_CURRENT: {
                return this.formatDate().tz_offset;
            }
            case ResolverType.ZONED_DATETIME_MODIFICATION: {
                const { type, value } = resolver, units = __rest(resolver, ["type", "value"]);
                if (!this.resolveZonedDateTime(value)) {
                    return this.formatDate().tz_offset;
                }
                const timeUnits = this.resolveTimeUnits(units);
                const date = new Date(this.resolveZonedDateTime(value));
                const dateModified = this.formatDateModified(date, timeUnits);
                return this.formatDate(dateModified).tz_offset;
            }
            case ResolverType.ZONED_DATETIME_START_OF_DAY: {
                const date = new Date(this.resolveZonedDateTime(resolver.value));
                const dateModified = this.resolveScheduleDate(date).START_OF_DAY;
                return this.formatDate(dateModified).tz_offset;
            }
            case ResolverType.ZONED_DATETIME_SWITCH: {
                const condition = resolver.cases.find((c) => this.resolveBoolean(c.condition));
                return this.resolveZonedDateTime((condition === null || condition === void 0 ? void 0 : condition.value) || resolver.fallback);
            }
            default: {
                this.resolveCrash(`${type} is an unsupported ZonedDateTime resolver`);
            }
        }
    }
    get getAnswersCachedMap() {
        return getMapByKey(__classPrivateFieldGet(this, _ResolveValue_cachedAnswers, "f"), 'key');
    }
}
_ResolveValue_answers = new WeakMap(), _ResolveValue_cachedAnswers = new WeakMap(), _ResolveValue_segment = new WeakMap(), _ResolveValue_reusableResolvers = new WeakMap(), _ResolveValue_computedValues = new WeakMap();
