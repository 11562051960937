var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { VStack } from '@chakra-ui/react';
import { getLocalStorage } from '@/utils';
export const BackgroundImage = (_a) => {
    var { children, path } = _a, rest = __rest(_a, ["children", "path"]);
    const { api_url } = getLocalStorage();
    return (_jsx(VStack, Object.assign({ backgroundImage: api_url + path, backgroundPosition: "bottom center", backgroundSize: "cover" }, rest, { children: children })));
};
