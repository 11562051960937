import { jsx as _jsx } from "react/jsx-runtime";
import { useRadioGroup } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { BlockErrors, InputGroupBase, RadioItem } from '@/components';
import { useResolveValue } from '@/hooks';
import { filterBlockAnswers, getAnswerValue, getInputBlockAnswer } from '@/utils';
export const Radio = ({ block: { answer_filters = [], id, images, key, typed_answers }, defaultValue, errors = [], onChange, }) => {
    const selectedValue = useMemo(() => {
        return getAnswerValue(defaultValue);
    }, [defaultValue]);
    useEffect(() => {
        setValue(JSON.stringify(selectedValue));
    }, [defaultValue]);
    const { getRadioProps, getRootProps, setValue } = useRadioGroup({
        defaultValue: JSON.stringify(selectedValue),
    });
    const [resolver] = useResolveValue();
    const resolved = useMemo(() => {
        const answerKeys = resolver.resolveAnswerFilter(answer_filters);
        const filters = filterBlockAnswers(typed_answers.answers, answerKeys, defaultValue);
        const answersList = filters.answersFiltered.map((a, order) => {
            return Object.assign(Object.assign({}, a), { label: resolver.resolveString(a.label), order });
        });
        return {
            answersList,
        };
    }, []);
    const handleChange = (answer) => {
        const newAnswer = getInputBlockAnswer(typed_answers.type, answer, key);
        onChange(newAnswer, { order: answer.order });
    };
    return (_jsx(BlockErrors, Object.assign({ errors: errors }, { children: _jsx(InputGroupBase, Object.assign({ "data-test": id }, getRootProps(), { children: resolved.answersList.map((item) => {
                const value = JSON.stringify(item.value);
                return (_jsx(RadioItem, Object.assign({ answer: item, answerChange: handleChange, images: images }, getRadioProps({ value })), item.id));
            }) })) })));
};
