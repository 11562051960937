import { showLoading, useUI } from '@/store';
import { getLocalStorage, getObjListByKey } from '@/utils';
import { useState } from 'react';
export const usePreloader = () => {
    const uiCtx = useUI();
    const [isPreloading, setIsPreloading] = useState(false);
    const [preloadPercent, setPreloadPercent] = useState(0);
    const [imagesPreloaded, setImagesPreloaded] = useState({});
    const [errors, setErrors] = useState([]);
    const { api_url } = getLocalStorage();
    const loadImage = (item) => new Promise((resolve) => {
        const image = new Image();
        image.id = item.id;
        image.src = item.src;
        image.onload = resolve;
        image.onerror = () => {
            setErrors((prev) => [...prev, item]);
            resolve(true);
        };
    });
    const preloadImages = (mediaList = []) => {
        const images = mediaList.map((x) => (Object.assign(Object.assign({}, x), { src: api_url + x.src })));
        const percent = 100 / mediaList.length;
        return new Promise((resolve) => {
            setIsPreloading(true);
            return Promise.all(images.map((item) => loadImage(item).then(() => {
                setPreloadPercent((prev) => {
                    const progress = prev + percent;
                    uiCtx.dispatch(showLoading({ progress }));
                    return progress;
                });
            }))).then(() => {
                setImagesPreloaded(getObjListByKey(images, 'key'));
                resolve(true);
            });
        });
    };
    return [
        imagesPreloaded,
        {
            isPreloadFailed: errors.length > 0,
            isPreloading,
            preloadImages,
            preloadPercent,
        },
    ];
};
export default usePreloader;
