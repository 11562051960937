import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { TextField } from '@/components';
export const defaultHHMM = '00:00';
export const PickerTime = forwardRef(({ onChange, value }, ref) => {
    const handleChange = ({ target: { value } }) => {
        if (value)
            onChange(value);
    };
    return (_jsx(TextField, { fontSize: "sm", h: "40px", isRequired: true, onChange: handleChange, ref: ref, type: "time", value: value || defaultHHMM }));
});
