export var ValuesType;
(function (ValuesType) {
    ValuesType["BOOLEAN"] = "boolean";
    ValuesType["DATE"] = "date";
    ValuesType["DECIMAL"] = "decimal";
    ValuesType["FILE_LIST"] = "file_list";
    ValuesType["FILE"] = "file";
    ValuesType["FLOAT"] = "float";
    ValuesType["FLOAT64"] = "float64";
    ValuesType["INTEGER"] = "integer";
    ValuesType["RANGE"] = "range";
    ValuesType["STRING"] = "string";
    ValuesType["TIME"] = "time";
    ValuesType["TIMEZONE"] = "time_zone";
    ValuesType["ZONED_DATETIME"] = "zoned_datetime";
})(ValuesType || (ValuesType = {}));
