import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, HStack, Slider, SliderMark, SliderThumb, SliderTrack, Text, VStack, } from '@chakra-ui/react';
import { useEffect, useMemo, useRef } from 'react';
import { ClearBtnBase, Icon, MarkdownBase } from '@/components';
import { useResolveValue } from '@/hooks';
import { DataTest, ValuesType } from '@/types';
import { getInputBlockAnswer } from '@/utils';
export const VerticalScale = ({ block: { answer, bottom_label, can_clear, clear_label, id, increment, key, label_increment, range, top_label, }, defaultValue = null, onChange, }) => {
    useEffect(() => {
        inputRef.current.value = (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.toString()) || '';
    }, [defaultValue]);
    const inputRef = useRef();
    const [resolver] = useResolveValue();
    const resolved = {
        bottomLabel: resolver.resolveString(bottom_label),
        clearLabel: resolver.resolveString(clear_label),
        increment: resolver.resolveInteger(increment),
        incrementLabel: resolver.resolveInteger(label_increment),
        label: resolver.resolveString(answer.label),
        range: resolver.resolveRange(range),
        topLabel: resolver.resolveString(top_label),
    };
    const marks = useMemo(() => Array.from({ length: resolved.range.max - resolved.range.min + 1 }, (_, i) => resolved.range.min + i), []);
    const updateAnswer = (newValue) => {
        const answerValue = Object.assign(Object.assign({}, answer), { label: resolved.label, value: newValue });
        const newAnswer = getInputBlockAnswer(ValuesType.INTEGER, answerValue, key);
        onChange(newAnswer);
    };
    const handleChange = (newValue) => updateAnswer(newValue);
    const handleDeselect = () => updateAnswer(null);
    const hasValue = defaultValue !== null;
    return (_jsxs(HStack, Object.assign({ className: !hasValue && 'no-value', "data-test": id, maxW: "320px", spacing: "4", sx: {
            '&.no-value': {
                '& .chakra-slider__marker, & .chakra-slider__thumb': {
                    visibility: 'hidden',
                },
            },
            '& .chakra-slider__marker, & .chakra-slider__thumb, & .chakra-slider__track': {
                border: '1px',
                borderColor: 'darkBrandBlue.10',
                borderRadius: '4px',
                minH: '30px',
            },
        }, textAlign: "center" }, { children: [_jsxs(VStack, Object.assign({ flex: "1", spacing: "4" }, { children: [_jsxs(VStack, Object.assign({ bg: "darkBrandBlue.90", borderRadius: "10px", color: "white", p: "4" }, { children: [_jsx(MarkdownBase, { markdown: resolved.label }), _jsx(Text, Object.assign({ fontSize: "28px" }, { children: defaultValue }))] })), can_clear && hasValue && (_jsx(ClearBtnBase, Object.assign({ onClick: handleDeselect }, { children: resolved.clearLabel })))] })), _jsxs(VStack, Object.assign({ flex: "1", spacing: "6" }, { children: [_jsx(Text, Object.assign({ "data-test": DataTest.VERTICAL_SCALE_TOP_LABEL, fontSize: "sm" }, { children: resolved.topLabel })), _jsxs(Flex, Object.assign({ alignItems: "center", gridGap: "2", h: "280px" }, { children: [_jsxs(Slider, Object.assign({ defaultValue: defaultValue, h: "100%", max: resolved.range.max, min: resolved.range.min, onChange: handleChange, orientation: "vertical", ref: inputRef, step: resolved.increment }, { children: [_jsx(SliderTrack, { w: "18px" }), _jsx(SliderMark, Object.assign({ alignItems: "center", color: "darkBrandBlue.90", display: "flex", justifyContent: "center", marginStart: "-50px", mb: "-15px", minW: "32px", value: defaultValue }, { children: defaultValue })), _jsx(SliderThumb, Object.assign({ w: "28px" }, { children: _jsx(Icon, { name: "divider-dual", opacity: 0.2, size: "4" }) }))] })), _jsx(Flex, Object.assign({ direction: "column-reverse", h: "100%", justifyContent: "space-between", sx: {
                                    '& > div': {
                                        width: '5px',
                                        height: '1px',
                                        bg: 'darkBrandBlue.70',
                                        display: 'flex',
                                        alignItems: 'center',
                                    },
                                    '& > div:has(p)': {
                                        width: '12px',
                                        height: '2px',
                                    },
                                    '& > div p': {
                                        padding: '0 15px',
                                    },
                                } }, { children: marks.map((mark, i) => {
                                    const hasMark = i % resolved.incrementLabel === 0;
                                    return _jsx("div", { children: hasMark && _jsx(Text, Object.assign({ fontSize: "sm" }, { children: mark })) });
                                }) }))] })), _jsx(Text, Object.assign({ "data-test": DataTest.VERTICAL_SCALE_BOTTOM_LABEL, fontSize: "sm" }, { children: resolved.bottomLabel }))] }))] })));
};
