export class Validator {
    constructor() { }
    integer() {
        return {
            message: '',
            name: 'integer',
            test(value) {
                return Number.isInteger(value);
            },
        };
    }
    max(max) {
        return {
            message: '',
            name: 'max',
            test(value) {
                if (typeof value === 'number')
                    return value <= max;
                if (typeof value === 'string')
                    return value.length <= max;
            },
        };
    }
    min(min) {
        return {
            message: '',
            name: 'min',
            test(value) {
                if (typeof value === 'number')
                    return value >= min;
                if (typeof value === 'string')
                    return value.length >= min;
            },
        };
    }
    required() {
        return {
            message: '',
            name: 'required',
            test(value) {
                if (value === undefined)
                    return false;
                return !!value.length;
            },
        };
    }
    size(size) {
        return {
            message: '',
            name: 'size',
            test(value) {
                return value.length === size;
            },
        };
    }
}
