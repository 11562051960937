import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, Text, Textarea } from '@chakra-ui/react';
import GraphemeSplitter from 'grapheme-splitter';
import { useEffect, useRef } from 'react';
import { InputControl } from '@/components';
import { useResolveValue } from '@/hooks';
import { ValuesType } from '@/types';
import { getInputBlockAnswer } from '@/utils';
const splitter = new GraphemeSplitter();
export const FreeTextField = ({ block: { answer, id, key, max_length = null, placeholder_text, required }, defaultValue = '', onChange, }) => {
    useEffect(() => {
        inputRef.current.value = defaultValue;
    }, [defaultValue]);
    const inputRef = useRef();
    const [resolver] = useResolveValue();
    const resolved = {
        label: resolver.resolveString(answer.label),
        placeholder: resolver.resolveString(placeholder_text),
        required: resolver.resolveBoolean(required),
    };
    const totalChar = splitter.countGraphemes(defaultValue) || 0;
    const isInvalid = max_length && totalChar > max_length;
    const minHeight = '48px';
    const handleChange = (e) => {
        inputRef.current.style.height = minHeight;
        inputRef.current.style.height = `${e.target.scrollHeight}px`;
        const { value } = e.target;
        const answerValue = Object.assign(Object.assign({}, answer), { label: resolved.label, value: value === '' ? null : value });
        const newAnswer = getInputBlockAnswer(ValuesType.STRING, answerValue, key);
        onChange(newAnswer);
    };
    useEffect(() => {
        inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }, []);
    return (_jsx(InputControl, Object.assign({ _focusWithin: {
            borderBottomColor: 'darkBrandBlue.40',
        }, borderBottom: "2px solid", borderBottomColor: "darkBrandBlue.10", className: isInvalid && 'is-invalid', "data-test": id, isInvalid: isInvalid, isRequired: resolved.required, label: resolved.label, sx: {
            '&.is-invalid': {
                borderBottomColor: 'red',
                '& p': {
                    color: 'red',
                },
            },
        } }, { children: _jsxs(HStack, Object.assign({ alignItems: "flex-end", spacing: "6" }, { children: [_jsx(Textarea, { autoCapitalize: "none", autoCorrect: "off", border: "none", boxShadow: "none !important", "data-test": answer.id, defaultValue: defaultValue, isRequired: resolved.required, minH: minHeight, onChange: handleChange, placeholder: resolved.placeholder, pt: "4", px: "1", ref: inputRef, resize: "none", rows: 1, spellCheck: "false" }), max_length && (_jsx(Text, Object.assign({ color: "darkBrandBlue.20", fontSize: "sm", pb: "2" }, { children: `${totalChar}/${max_length}` })))] })) })));
};
