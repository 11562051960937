import { jsx as _jsx } from "react/jsx-runtime";
import { MarkdownBase } from '@/components';
import { useResolveValue } from '@/hooks';
import { convertToCSS } from '@/utils';
export const HeadingText = ({ block: { content, id, layout } }) => {
    const [resolver] = useResolveValue();
    const resolved = {
        content: resolver.resolveString(content),
    };
    const styles = Object.assign(Object.assign({}, convertToCSS(layout)), { fontSize: 'xl', fontWeight: 'semibold' });
    return _jsx(MarkdownBase, { "data-test": id, markdown: resolved.content, sx: styles });
};
