import { StorageKey, UIActionType } from '@/types';
export const uiReducer = (state, action) => {
    switch (action.type) {
        case UIActionType.SHOW_LOADING: {
            const { payload } = action;
            return Object.assign(Object.assign({}, state), { loading: {
                    message: (payload === null || payload === void 0 ? void 0 : payload.message) || '',
                    opacity: (payload === null || payload === void 0 ? void 0 : payload.opacity) || 0.85,
                    progress: Math.round(payload === null || payload === void 0 ? void 0 : payload.progress) || undefined,
                    show: true,
                } });
        }
        case UIActionType.CLOSE_LOADING: {
            return Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { progress: undefined, show: false }) });
        }
        case UIActionType.SET_PRIVATE_CHECKED: {
            //  If the device is not private delete the invite_code cookie
            if (!action.payload)
                localStorage.removeItem(StorageKey.INVITE_CODE);
            return Object.assign(Object.assign({}, state), { checked: action.payload });
        }
        default:
            throw new Error();
    }
};
