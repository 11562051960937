import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo, useReducer } from 'react';
import { initialUIState, uiReducer } from '@/store';
const UIContext = createContext({
    state: initialUIState,
    dispatch: () => undefined,
});
export const useUI = () => useContext(UIContext);
export const UIProvider = ({ children }) => {
    const [state, dispatch] = useReducer(uiReducer, initialUIState);
    const value = useMemo(() => ({
        dispatch,
        state,
    }), [state]);
    return _jsx(UIContext.Provider, Object.assign({ value: value }, { children: children }));
};
