var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HeadersRequestKey } from '@/types';
import api from './instance';
class GoogleMapsService {
    getTimezone(lat, lng) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield api.get(this.baseURL.timezone, {
                params: {
                    key: this.apiKey,
                    location: `${lat},${lng}`,
                    timestamp: this.timestamp,
                },
                transformRequest: (data, headers) => {
                    delete headers.common.Authorization;
                    delete headers.Authorization;
                    delete headers[HeadersRequestKey.DATETIME];
                    delete headers[HeadersRequestKey.LOCALE];
                    return data;
                },
            });
            return data;
        });
    }
    get apiKey() {
        return process.env.REACT_APP_GOOGLE_MAPS_KEY;
    }
    get baseURL() {
        return {
            geocode: 'https://maps.googleapis.com/maps/api/geocode/json',
            timezone: 'https://maps.googleapis.com/maps/api/timezone/json',
        };
    }
    get timestamp() {
        return Math.floor(new Date().getTime() / 1000);
    }
}
export default new GoogleMapsService();
