import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Icon } from '@/components';
export const BreadCrumbs = ({ breadcrumbs }) => {
    return (_jsx(Box, Object.assign({ backgroundColor: "lightBlue.50" }, { children: _jsx(Breadcrumb, Object.assign({ color: "white.100", maxW: "1000px", minH: "44px", mx: "auto", py: "2", sx: {
                '& *': {
                    display: 'flex',
                    alignItems: 'center',
                },
            } }, { children: breadcrumbs.map(({ text, url }, index) => {
                const isLast = index === breadcrumbs.length - 1;
                const isFirst = index === 0;
                return (_jsx(BreadcrumbItem, Object.assign({ isCurrentPage: isLast }, { children: _jsxs(BreadcrumbLink, Object.assign({}, (!isLast && {
                        as: Link,
                        to: url,
                    }), { children: [isFirst && (_jsx(Icon, { color: "white", cursor: "pointer", name: "chevron-left", type: "solid" })), text] })) }), url));
            }) })) })));
};
