export var ReusableResolverType;
(function (ReusableResolverType) {
    ReusableResolverType["BOOLEAN"] = "boolean_resolvers";
    ReusableResolverType["DATE"] = "date_resolvers";
    ReusableResolverType["DECIMAL"] = "decimal_resolvers";
    ReusableResolverType["FLOAT"] = "float_resolvers";
    ReusableResolverType["INTEGER"] = "integer_resolvers";
    ReusableResolverType["RANGE"] = "range_resolvers";
    ReusableResolverType["STRING_LIST"] = "string_list_resolvers";
    ReusableResolverType["STRING"] = "string_resolvers";
    ReusableResolverType["TIME"] = "time_resolvers";
    ReusableResolverType["TIMEZONE"] = "timezone_resolvers";
    ReusableResolverType["ZONED_DATETIME"] = "zoned_datetime_resolvers";
})(ReusableResolverType || (ReusableResolverType = {}));
