// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../../../node_modules/filepond/dist/filepond.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filepond--drop-label.filepond--drop-label label {
  align-items: center;
  background: var(--chakra-colors-darkBrandBlue-90);
  border-radius: 6px;
  color: var(--chakra-colors-white-100);
  cursor: pointer;
  display: flex;
  font-size: var(--chakra-fontSizes-sm);
  gap: 10px;
  padding: 10px 24px;
}

.filepond--drop-label.filepond--drop-label label img {
  max-height: 24px;
}

.filepond--file .filepond--file-status {
  display: flex;
  height: 100%;
  justify-content: flex-start;
}

.filepond--file-status .filepond--file-status-sub {
  display: none;
}

.filepond--item {
  list-style: none;
}

[data-filepond-item-state="processing"] .filepond--action-revert-item-processing {
  opacity: 0.7 !important;
  visibility: visible !important;
}`, "",{"version":3,"sources":["webpack://./src/components/blocks/FileUploadField/style.css"],"names":[],"mappings":"AAEA;EACE,mBAAmB;EACnB,iDAAiD;EACjD,kBAAkB;EAClB,qCAAqC;EACrC,eAAe;EACf,aAAa;EACb,qCAAqC;EACrC,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,2BAA2B;AAC7B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,8BAA8B;AAChC","sourcesContent":["@import url(\"filepond/dist/filepond.min.css\");\n\n.filepond--drop-label.filepond--drop-label label {\n  align-items: center;\n  background: var(--chakra-colors-darkBrandBlue-90);\n  border-radius: 6px;\n  color: var(--chakra-colors-white-100);\n  cursor: pointer;\n  display: flex;\n  font-size: var(--chakra-fontSizes-sm);\n  gap: 10px;\n  padding: 10px 24px;\n}\n\n.filepond--drop-label.filepond--drop-label label img {\n  max-height: 24px;\n}\n\n.filepond--file .filepond--file-status {\n  display: flex;\n  height: 100%;\n  justify-content: flex-start;\n}\n\n.filepond--file-status .filepond--file-status-sub {\n  display: none;\n}\n\n.filepond--item {\n  list-style: none;\n}\n\n[data-filepond-item-state=\"processing\"] .filepond--action-revert-item-processing {\n  opacity: 0.7 !important;\n  visibility: visible !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
