import { useState, useEffect } from 'react';
const connection = navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection ||
    null;
function getNetworkConnection() {
    return connection;
}
function getNetworkConnectionInfo() {
    const connection = getNetworkConnection();
    if (!connection) {
        return {};
    }
    return {
        rtt: connection.rtt,
        type: connection.type,
        saveData: connection.saveData,
        downLink: connection.downLink,
        downLinkMax: connection.downLinkMax,
        effectiveType: connection.effectiveType,
    };
}
function useNetwork() {
    const [state, setState] = useState(() => (Object.assign({ since: undefined, online: navigator.onLine }, getNetworkConnectionInfo())));
    useEffect(() => {
        const handleOnline = () => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { online: true, since: new Date().toString() })));
        };
        const handleOffline = () => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { online: false, since: new Date().toString() })));
        };
        const handleConnectionChange = () => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), getNetworkConnectionInfo())));
        };
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
        const connection = getNetworkConnection();
        connection === null || connection === void 0 ? void 0 : connection.addEventListener('change', handleConnectionChange);
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
            connection === null || connection === void 0 ? void 0 : connection.removeEventListener('change', handleConnectionChange);
        };
    }, []);
    return state;
}
export default useNetwork;
