import { createGlobalStyle } from 'styled-components';
import AvenirMediumWoff from '@/assets/fonts/Avenir-Medium.woff';
import AvenirMediumWoff2 from '@/assets/fonts/Avenir-Medium.woff2';
import SophiaProMediumWoff from '@/assets/fonts/SofiaPro-Medium.woff';
import SophiaProMediumWoff2 from '@/assets/fonts/SofiaPro-Medium.woff2';
import SophiaProRegularWoff from '@/assets/fonts/SofiaProRegular.woff';
import SophiaProRegularWoff2 from '@/assets/fonts/SofiaProRegular.woff2';
export const GlobalStyle = createGlobalStyle `
  body {
    transition-duration: unset;
    overscroll-behavior-y: none;
  }

  @font-face {
    font-family: 'Avenir Medium';
    src: local('Avenir Medium'),
    url(${AvenirMediumWoff2}) format('woff2'),
    url(${AvenirMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Sophia Pro Medium';
    src: local('Sophia Pro Medium'),
    url(${SophiaProMediumWoff2}) format('woff2'),
    url(${SophiaProMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Sofia Pro Regular';
    src: local('Sofia Pro Regular'),
    url(${SophiaProRegularWoff2}) format('woff2'),
    url(${SophiaProRegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  html[dir='rtl'] {
    svg[class^='icon-'] {
      transform: scaleX(-1);
    }

    input {
      text-align: right;
    }
      
    & .chakra {
      &-select {
        &__icon-wrapper {
          right: auto;
          left: 0.5rem;
        }
      }
      &-input {
        &__left-element {
          left: auto;
          right: 0;
        }
        &__right-element {
          right: auto;
          left: 0;
        }
      }
      &-popover {
        &__popper {
          inset: 0 0 auto auto !important;
        }
      }
    }

    & .react-datepicker-popper {
      inset: 0 0 auto auto !important;
    }
  }

  // Reset list styles by levels
  ul > li {
    list-style-type: initial;
  }
  ul > li > ul > li {
    list-style-type: circle;
  }
  ul ul ul li {
    list-style-type: square;
  }

  .chakra-toast__inner {
    min-width: 100% !important;
    margin: 0 !important;
  }

  /* Prevent required fields to display asterisk (*) */
  .chakra-form__required-indicator {
    display: none !important;
  }

  .numeric-rating-scale > &:last-of-type > div,
  [dir="ltr"] .numeric-rating-scale > &:last-of-type > div,
  [dir="rtl"] .numeric-rating-scale > &:first-of-type > div {
    border-radius: 0 8px 8px 0;
  }

  .numeric-rating-scale > &:first-of-type > div,
  [dir="ltr"] .numeric-rating-scale > &:first-of-type > div,
  [dir="rtl"] .numeric-rating-scale > &:last-of-type > div {
    border-radius: 8px 0 0 8px;
  }

  .numeric-rating-scale-labels p:first-child,
  [dir="ltr"] .numeric-rating-scale-labels p:first-child,
  [dir="rtl"] .numeric-rating-scale-labels p:last-child {
    text-align: left;
  }

  .numeric-rating-scale-labels p:last-child,
  [dir="rtl"] .numeric-rating-scale-labels p:first-child, 
  [dir="ltr"] .numeric-rating-scale-labels p:last-child {
    text-align: right;
  }

  .fill-width {
    width: 100vw;
  }

  @media only screen and (max-width: 820px) {
    .fill-width {
      &.ignore-horizontal-padding {
        width: 100vw;
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);

        & img {
          border-radius: 0;
        }
      }
    }
  }
`;
