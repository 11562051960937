import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, ListItem, OrderedList, Spacer, Text, useToast as useChakraToast, } from '@chakra-ui/react';
import { v5 as uuidv5 } from 'uuid';
import { Icon } from '@/components';
const toastVariation = {
    error: {
        color: 'red.80',
        icon: 'exclamation-circle',
    },
    info: {
        color: 'darkBrandBlue.80',
        icon: 'info-circle',
    },
    success: {
        color: 'green.80',
        icon: 'check-circle',
    },
    warning: {
        color: 'amber.80',
        icon: 'exclamation-triangle',
    },
};
const getToastId = (message) => uuidv5(JSON.stringify(message), uuidv5.URL);
export const useToast = () => {
    const toast = useChakraToast();
    const closeAllToast = () => {
        toast.closeAll();
    };
    const closeToast = (message) => {
        const id = getToastId(message);
        if (toast.isActive(id)) {
            toast.close(id);
        }
    };
    const showToast = ({ message, permanent = false, status = 'error' }) => {
        const id = getToastId(message);
        if (!toast.isActive(id)) {
            const { color, icon } = toastVariation[status];
            toast({
                duration: permanent ? 100000000 : 8000,
                id,
                isClosable: true,
                position: 'top',
                status,
                render: ({ onClose }) => (_jsxs(HStack, Object.assign({ alignItems: "center", bg: color, borderBottom: "1px solid rgba(255, 255, 255, 0.2)", color: "white", justifyContent: "center", minH: "44px", px: "4", py: "2", spacing: "3" }, { children: [_jsx(Spacer, {}), _jsx(Icon, { color: "white", name: icon, size: "28px", type: "solid" }), Array.isArray(message) && message.length > 1 ? (_jsx(OrderedList, Object.assign({ px: "3" }, { children: message.map((msg) => (_jsx(ListItem, { children: msg }))) }))) : (_jsx(Text, { children: message })), _jsx(Spacer, {}), _jsx(Icon, { color: "white", name: "times", onClick: onClose, opacity: "0.8", size: "22px" })] }))),
            });
        }
    };
    return { closeAllToast, closeToast, showToast };
};
