var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Grid, Heading } from '@chakra-ui/react';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { CentredWrapper, FormButton, Icon, InputControl, NavLink, StyledLink, TextField, } from '@/components';
import { AuthService } from '@/services';
import { useAuth } from '@/store';
import { HTTP_STATUS, ROUTE } from '@/types';
import { useTranslation } from 'react-i18next';
export const Login = () => {
    const { i18n, t } = useTranslation();
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [passwordInvalid, setPasswordInvalid] = useState(false);
    const [loading, setLoading] = useState(false);
    const { handleAuth, state: { userInvitation }, } = useAuth();
    const handleToggleVisibility = () => setShowPassword(!showPassword);
    const handleChange = (evt) => setPassword(evt.target.value);
    const handleLogin = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        try {
            setLoading(true);
            yield AuthService.login({
                password,
                username: userInvitation.code,
            });
            yield handleAuth();
        }
        catch (e) {
            if (e.status === HTTP_STATUS.UNAUTHORIZED) {
                setPasswordInvalid(true);
            }
        }
        finally {
            setLoading(false);
        }
    });
    return i18n.exists('app:components.login.title') ? (_jsxs(CentredWrapper, { children: [_jsx(Heading, Object.assign({ size: "lg" }, { children: t('app:components.login.title') })), _jsxs(Flex, Object.assign({ as: "form", direction: "column", gridGap: "8", onSubmit: handleLogin, w: "100%" }, { children: [_jsx(InputControl, Object.assign({ isInvalid: passwordInvalid, isRequired: true, label: t('app:components.login.password_label') }, { children: _jsx(TextField, { autoComplete: "current-password", onChange: handleChange, rightElement: _jsxs(Flex, { children: [_jsx(Icon, { name: showPassword ? 'eye' : 'eye-slash', type: "solid", onClick: handleToggleVisibility }), passwordInvalid && _jsx(Icon, { name: "exclamation-circle", type: "solid", color: "red.500" })] }), type: showPassword ? 'text' : 'password' }) })), _jsxs(Grid, Object.assign({ gap: "4", gridAutoColumns: "minmax(0, 1fr)", gridAutoFlow: "column" }, { children: [_jsx(StyledLink, Object.assign({ to: ROUTE.HOME, variant: "CTA_WHITE" }, { children: t('app:components.login.prev') })), _jsx(FormButton, Object.assign({ disabled: loading || !password.length, loading: loading, type: "submit" }, { children: t('app:components.login.sign_in_cta') }))] })), _jsx(NavLink, Object.assign({ to: ROUTE.PASSWORD_FORGOT }, { children: t('app:components.login.forgotten_password_cta') }))] }))] })) : (_jsx(Navigate, { to: ROUTE.HOME }));
};
