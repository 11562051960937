import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Collapse, HStack, Image, Link, Spacer, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import favicon from '@/assets/img/favicons/favicon-smart-app.png';
import { DataTest } from '@/types';
import { getMobileOS } from '@/utils';
import { Icon } from './Icon';
const HEIGHT = '85px';
const { isAndroid, isMobile } = getMobileOS();
export const SmartBanner = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(true);
    const handleHeader = useCallback((space = HEIGHT) => {
        const header = document.getElementsByTagName('header')[0];
        if (header)
            header.style.marginTop = space;
    }, []);
    const handleClose = useCallback(() => {
        setShow(false);
        handleHeader('0');
    }, []);
    useEffect(() => {
        if (isMobile)
            handleHeader();
    }, []);
    return (isMobile && (_jsx(Collapse, Object.assign({ "data-test": DataTest.UNSUPPORTED_SMART_BANNER, in: show }, { children: _jsxs(HStack, Object.assign({ bg: "grey.60", h: HEIGHT, justifyContent: "space-between", p: "3", position: "fixed", top: "0", w: "100%" }, { children: [_jsx(Icon, { color: "grey.80", name: "times", onClick: handleClose }), _jsx(Image, { alt: "uMotif", borderRadius: "16", src: favicon, w: "64px" }), _jsxs(Box, Object.assign({ alignItems: "flex-start" }, { children: [_jsx(Text, { children: "TrialMotif" }), _jsx(Text, Object.assign({ opacity: ".5" }, { children: "uMotif" }))] })), _jsx(Spacer, {}), _jsx(Link, Object.assign({ bg: "lightBlue.60", borderRadius: "20", color: "white", fontWeight: "500", href: isAndroid ? process.env.REACT_APP_ANDROID_URL : process.env.REACT_APP_IOS_URL, px: "20px", py: "6px", textDecoration: "none", textTransform: "uppercase" }, { children: t('common:btn.view') }))] })) }))));
};
