export var PlatformType;
(function (PlatformType) {
    PlatformType["ANDROID"] = "android";
    PlatformType["IOS"] = "ios";
    PlatformType["WEB"] = "web";
})(PlatformType || (PlatformType = {}));
export var SuperGroupAnswerStateType;
(function (SuperGroupAnswerStateType) {
    SuperGroupAnswerStateType["ALL"] = "all";
    SuperGroupAnswerStateType["NONE"] = "none";
    SuperGroupAnswerStateType["REQUIRED"] = "required";
    SuperGroupAnswerStateType["SOME"] = "some";
})(SuperGroupAnswerStateType || (SuperGroupAnswerStateType = {}));
export var DatetimeGranularityType;
(function (DatetimeGranularityType) {
    DatetimeGranularityType["DAY"] = "D";
    DatetimeGranularityType["MONTH"] = "M";
    DatetimeGranularityType["YEAR"] = "Y";
    DatetimeGranularityType["TIME"] = "T";
})(DatetimeGranularityType || (DatetimeGranularityType = {}));
export var TimeUnitType;
(function (TimeUnitType) {
    TimeUnitType["DAYS"] = "days";
    TimeUnitType["HOURS"] = "hours";
    TimeUnitType["MINUTES"] = "minutes";
    TimeUnitType["MONTHS"] = "months";
    TimeUnitType["SECONDS"] = "seconds";
    TimeUnitType["WEEKS"] = "weeks";
    TimeUnitType["YEARS"] = "years";
})(TimeUnitType || (TimeUnitType = {}));
export var LocationType;
(function (LocationType) {
    LocationType["CITIES"] = "cities";
    LocationType["COUNTRIES"] = "countries";
})(LocationType || (LocationType = {}));
export var SegmentOriginatorPropertyType;
(function (SegmentOriginatorPropertyType) {
    SegmentOriginatorPropertyType["ID"] = "id";
    SegmentOriginatorPropertyType["TYPE"] = "type";
})(SegmentOriginatorPropertyType || (SegmentOriginatorPropertyType = {}));
export var Operator;
(function (Operator) {
    let Basic;
    (function (Basic) {
        Basic["EQUALS"] = "==";
        Basic["NOT_EQUALS"] = "!=";
        Basic["GREATER_THAN"] = ">";
        Basic["GREATER_THAN_OR_EQUALS"] = ">=";
        Basic["LESS_THAN"] = "<";
        Basic["LESS_THAN_OR_EQUALS"] = "<=";
    })(Basic = Operator.Basic || (Operator.Basic = {}));
    let Arithmetic;
    (function (Arithmetic) {
        Arithmetic["ADDITION"] = "+";
        Arithmetic["SUBTRACTION"] = "-";
        Arithmetic["MULTIPLICATION"] = "*";
        Arithmetic["DIVISION"] = "/";
        Arithmetic["MODULO"] = "%";
    })(Arithmetic = Operator.Arithmetic || (Operator.Arithmetic = {}));
})(Operator || (Operator = {}));
