import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, IconButton, Select } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Icon } from '@/components';
import { getLocaleMonths, getLocaleYears } from '@/utils';
export const PickerHeader = ({ changeMonth, changeYear, date, decreaseMonth, decreaseYear, increaseMonth, increaseYear, maxDate, minDate, nextMonthButtonDisabled, nextYearButtonDisabled, prevMonthButtonDisabled, prevYearButtonDisabled, showMonthYearPicker, }) => {
    const months = useMemo(() => getLocaleMonths(), []);
    const years = useMemo(() => getLocaleYears(minDate, maxDate), [minDate, maxDate]);
    const handleMonthChange = (event) => {
        changeMonth(months.indexOf(event.target.value));
    };
    const handleYearChange = (event) => {
        changeYear(Number(event.target.value));
    };
    return (_jsxs(HStack, Object.assign({ justifyContent: "space-between", px: "4", py: "2", sx: {
            '& button, & select': {
                bg: 'transparent',
                borderRadius: '8px',
                fontWeight: 600,
            },
        } }, { children: [_jsx(IconButton, { "aria-label": "Previous month", disabled: showMonthYearPicker ? prevYearButtonDisabled : prevMonthButtonDisabled, icon: _jsx(Icon, { name: "chevron-left", type: "solid", color: "darkBrandBlue.80" }), onClick: showMonthYearPicker ? decreaseYear : decreaseMonth, size: "sm" }), !showMonthYearPicker && (_jsx(Select, Object.assign({ onChange: handleMonthChange, size: "sm", value: months[date.getMonth()], className: "react-datepicker__header-month" }, { children: months.map((option) => (_jsx("option", Object.assign({ value: option }, { children: option }), option))) }))), _jsx(Select, Object.assign({ onChange: handleYearChange, size: "sm", value: date.getFullYear(), className: "react-datepicker__header-year" }, { children: years.map((option) => (_jsx("option", Object.assign({ value: option }, { children: option }), option))) })), _jsx(IconButton, { "aria-label": "Next month", disabled: showMonthYearPicker ? nextYearButtonDisabled : nextMonthButtonDisabled, icon: _jsx(Icon, { name: "chevron-right", type: "solid", color: "darkBrandBlue.80" }), onClick: showMonthYearPicker ? increaseYear : increaseMonth, size: "sm" })] })));
};
