var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { closeLoading, showLoading, useUI } from '@/store';
export const useMultiStepForm = (steps, options) => {
    const uiCTX = useUI();
    const [formData, setFormData] = useState(options.data);
    const [formErrors, setFormErrors] = useState({});
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    useEffect(() => {
        handleValidateStep();
    }, [formData]);
    const removeErrorByKey = (key) => {
        const errors = Object.assign({}, formErrors);
        delete errors[key];
        setFormErrors(errors);
    };
    const handleValidateStep = () => {
        return Object.entries(options.validation).every(([key, value]) => {
            if (value.step === currentStepIndex) {
                if (value.required && !formData[key]) {
                    setFormErrors(Object.assign(Object.assign({}, formErrors), { [key]: '' }));
                    return false;
                }
                else {
                    removeErrorByKey(key);
                }
            }
            return true;
        });
    };
    const handleFieldChange = (key, value) => {
        if (value)
            removeErrorByKey(key);
        setFormData(Object.assign(Object.assign({}, formData), { [key]: value }));
    };
    const handleNext = () => {
        const isStepValid = handleValidateStep();
        if (isStepValid) {
            setCurrentStepIndex((i) => {
                if (i >= steps.length - 1)
                    return i;
                return i + 1;
            });
        }
    };
    const handleBack = () => {
        setCurrentStepIndex((i) => {
            if (i <= 0)
                return i;
            return i - 1;
        });
    };
    const handleSubmitForm = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            uiCTX.dispatch(showLoading());
            yield (options === null || options === void 0 ? void 0 : options.onSubmit());
        }
        finally {
            uiCTX.dispatch(closeLoading());
        }
    });
    const handleGoTo = (index) => {
        setCurrentStepIndex(index);
    };
    return {
        currentStepIndex,
        formData,
        formErrors,
        handleBack,
        handleFieldChange,
        handleGoTo,
        handleNext,
        handleSubmitForm,
        isFirstStep: currentStepIndex === 0,
        isLastStep: currentStepIndex === steps.length - 1,
        setFormErrors,
        step: steps[currentStepIndex],
        steps,
    };
};
export default useMultiStepForm;
