export var UserPhaseEventMetaType;
(function (UserPhaseEventMetaType) {
    UserPhaseEventMetaType["BACKGROUND_COLOUR"] = "background_colour";
    UserPhaseEventMetaType["DESCRIPTION"] = "description";
    UserPhaseEventMetaType["ICON"] = "icon_path";
    UserPhaseEventMetaType["LONG_TITLE"] = "long_title";
    UserPhaseEventMetaType["NAME"] = "name";
    UserPhaseEventMetaType["QUESTIONNAIRE_ID"] = "questionnaire_id";
    UserPhaseEventMetaType["SHORT_TITLE"] = "short_title";
    UserPhaseEventMetaType["SUBHEADING_1"] = "subheading1";
    UserPhaseEventMetaType["SUBHEADING_2"] = "subheading2";
    UserPhaseEventMetaType["TEXT_COLOUR"] = "text_colour";
})(UserPhaseEventMetaType || (UserPhaseEventMetaType = {}));
