var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { InputControl, TextField } from '@/components';
export const FieldText = (_a) => {
    var { answerChange, data: { key, label }, defaultValue, error } = _a, rest = __rest(_a, ["answerChange", "data", "defaultValue", "error"]);
    const { t } = useTranslation();
    const handleChange = (event) => {
        answerChange(key, event.target.value);
    };
    return (_jsx(InputControl, Object.assign({ label: t(label) }, rest, { children: _jsx(TextField, { autoCapitalize: "none", autoCorrect: "off", defaultValue: defaultValue, error: error, onChange: handleChange, spellCheck: "false" }) })));
};
