import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { v4 as uuid_v4 } from 'uuid';
import { useResolveValue } from '@/hooks';
import { initialQuestionnaireState, questionnaireReducer } from '@/store';
import { InputBlockTypedAnswerType } from '@/types';
import { getBlockTypeName, getLocalStorage, mergeArrays } from '@/utils';
const QuestionnaireContext = createContext({
    blockList: [],
    mapBlockAnswersList: undefined,
    mapBlockInputByScene: undefined,
    mapComputedValues: undefined,
    state: initialQuestionnaireState,
    dispatch: () => undefined,
    getAnswersDependency: () => undefined,
    getMediaCore: () => undefined,
});
export const useQuestionnaire = () => useContext(QuestionnaireContext);
export const QuestionnaireProvider = ({ children }) => {
    const [state, dispatch] = useReducer(questionnaireReducer, initialQuestionnaireState);
    const { api_url } = getLocalStorage();
    const [resolver] = useResolveValue();
    const mapComputedValues = useMemo(() => {
        const result = new Map();
        state.questionnaire.scenes.forEach((scene) => {
            if (scene.computed_values) {
                const innerMap = new Map();
                scene.computed_values.forEach((cv) => {
                    innerMap.set(cv.computed_value_key, cv);
                });
                result.set(scene.key, innerMap);
            }
        });
        return result;
    }, [state.questionnaire.id]);
    const mapMediaCore = useMemo(() => {
        return Object.fromEntries(state.questionnaire.core_media.map((x) => [x.key, api_url + x.src]));
    }, [state.questionnaire.id]);
    const blockList = useMemo(() => {
        return [
            ...state.questionnaire.scenes.flatMap((s) => mergeArrays(s.main_blocks, s.footer_blocks)),
            ...state.questionnaire.post_submission_scenes.flatMap((s) => mergeArrays(s.main_blocks, s.footer_blocks)),
        ];
    }, [state.questionnaire.id]);
    const inputBlockList = useMemo(() => {
        return blockList.filter((b) => 'key' in b);
    }, [blockList]);
    const mapSceneKeyByBlockId = useMemo(() => {
        const map = new Map();
        state.questionnaire.scenes.forEach((scene) => {
            mergeArrays(scene.main_blocks, scene.footer_blocks).forEach((block) => {
                map.set(block.id, scene.key);
            });
        });
        return map;
    }, [state.questionnaire.id]);
    const mapBlockInputByScene = useMemo(() => {
        const mapScene = new Map();
        state.questionnaire.scenes.forEach((scene) => {
            const mapBlock = new Map();
            mergeArrays(scene.main_blocks, scene.footer_blocks).forEach((block) => {
                if ('key' in block)
                    mapBlock.set(block.key, block);
            });
            mapScene.set(scene.key, mapBlock);
        });
        return mapScene;
    }, [state.questionnaire.id]);
    // Map block answers list
    const mapBlockAnswersList = useMemo(() => {
        const getBaseAnswer = (b, a, order = 0) => {
            const scene_key = mapSceneKeyByBlockId.get(b.id);
            let type = getBlockTypeName(b.type);
            if ('typed_answers' in b) {
                type = getBlockTypeName(b.typed_answers.type, 0);
            }
            return {
                answer_id: a.id,
                answer_key: a.key,
                answered: false,
                block_key: b.key,
                id: uuid_v4(),
                label: resolver.resolveString(a.label),
                meta: {
                    order,
                },
                page_key: {
                    repeat_index: null,
                    repeat_key: null,
                    scene_key,
                },
                typed_value: {
                    type,
                    value: a === null || a === void 0 ? void 0 : a.value,
                },
            };
        };
        const mapBlocks = new Map();
        inputBlockList.forEach((b) => {
            const mapAnswers = new Map();
            if ('answers' in b) {
                b.answers.forEach((a, order) => {
                    const answer = getBaseAnswer(b, a, order);
                    mapAnswers.set(answer.answer_key, answer);
                });
            }
            if ('typed_answers' in b) {
                b.typed_answers.answers.forEach((a, order) => {
                    const answer = getBaseAnswer(b, a, order);
                    // Previously all answers in config had the same value used in submission. This is not the case anymore
                    // and we need to map and format the different types of answers here.
                    switch (b.typed_answers.type) {
                        case InputBlockTypedAnswerType.DECIMAL: {
                            answer.typed_value.value = {
                                parsed: answer.typed_value.value,
                                raw_value: answer.typed_value.value,
                            };
                            break;
                        }
                        default:
                            break;
                    }
                    mapAnswers.set(answer.answer_key, answer);
                });
            }
            if ('answer_yes' in b) {
                const answerYes = getBaseAnswer(b, b.answer_yes);
                const answerNo = getBaseAnswer(b, b.answer_no);
                mapAnswers.set(answerYes.answer_key, answerYes);
                mapAnswers.set(answerNo.answer_key, answerNo);
            }
            if ('answer' in b) {
                const answer = getBaseAnswer(b, b.answer);
                mapAnswers.set(answer.answer_key, answer);
            }
            if ('timezone_answer' in b) {
                const answer = getBaseAnswer(b, b.timezone_answer);
                mapAnswers.set(answer.answer_key, answer);
            }
            if ('utc_answer' in b) {
                const answer = getBaseAnswer(b, b.utc_answer);
                mapAnswers.set(answer.answer_key, answer);
            }
            mapBlocks.set(b.id, mapAnswers);
        });
        return mapBlocks;
    }, [inputBlockList]);
    const mapAnswerDependency = useMemo(() => {
        const mapDeps = new Map();
        inputBlockList.forEach((block) => {
            var _a;
            const scene_key = mapSceneKeyByBlockId.get(block.id);
            (_a = block === null || block === void 0 ? void 0 : block.dependencies) === null || _a === void 0 ? void 0 : _a.forEach((x) => {
                const dep = Object.assign(Object.assign({}, x), { block_key: block.key, page_resolver: Object.assign(Object.assign({}, x.page_resolver), { scene_key }) });
                const dependencies = mapDeps.get(x.block_key) || [];
                mapDeps.set(x.block_key, [...dependencies, dep]);
            });
        });
        return mapDeps;
    }, [inputBlockList]);
    const getAnswersDependency = useCallback((bKey, visited = new Set()) => {
        if (visited.has(bKey))
            return [];
        visited.add(bKey);
        const dependencies = mapAnswerDependency.get(bKey) || [];
        return dependencies.flatMap((dep) => {
            const childDeps = getAnswersDependency(dep.block_key, visited);
            return [dep, ...childDeps];
        });
    }, [mapAnswerDependency]);
    const getMediaCore = useCallback((key) => {
        return mapMediaCore[key] || '';
    }, [mapMediaCore]);
    const value = useMemo(() => ({
        blockList,
        dispatch,
        getAnswersDependency,
        getMediaCore,
        mapBlockAnswersList,
        mapBlockInputByScene,
        mapComputedValues,
        state,
    }), [state]);
    return _jsx(QuestionnaireContext.Provider, Object.assign({ value: value }, { children: children }));
};
