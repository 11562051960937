import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Button, PageWrapper } from '@/components';
import { useAuth } from '@/store';
import { DataTest } from '@/types';
export const Profile = () => {
    const { t } = useTranslation();
    const { handleLogout, state: { user }, } = useAuth();
    return (_jsxs(PageWrapper, Object.assign({ title: t('page:profile.title') }, { children: [_jsx(Box, { children: user && (_jsxs(FormControl, { children: [_jsx(FormLabel, Object.assign({ htmlFor: "PROFILE_EMAIL_INPUT" }, { children: t('common:label.email') })), _jsx(Input, { maxW: "390px", readOnly: true, type: "text", value: user.data.attributes.email, variant: "REGULAR_INPUT" })] })) }), _jsx(Divider, { bg: "grey.80", h: "1px", my: "12", opacity: "100%" }), _jsx(Button, Object.assign({ "data-test": DataTest.PROFILE_LOGOUT, onClick: handleLogout, variant: "cancel", w: "fit-content" }, { children: t('page:profile.logout') }))] })));
};
