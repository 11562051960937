var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Spinner } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { InputControl, TextField } from '@/components';
import { useResolveValue } from '@/hooks';
import { GoogleMapsService } from '@/services';
import { LocationType, ValuesType } from '@/types';
import { getInputBlockAnswer } from '@/utils';
const locationTypes = {
    [LocationType.CITIES]: '(cities)',
    [LocationType.COUNTRIES]: 'country',
};
export const LocationField = ({ block: { answer, id, key, latitude_answer, location_type = LocationType.CITIES, longitude_answer, required, timezone_answer, }, defaultValue = '', onChange, }) => {
    const autocompleteRef = useRef();
    const inputRef = useRef();
    const [loading, setLoading] = useState(false);
    const [resolver] = useResolveValue();
    const resolved = {
        label: resolver.resolveString(answer.label),
        latLabel: resolver.resolveString(latitude_answer === null || latitude_answer === void 0 ? void 0 : latitude_answer.label),
        lngLabel: resolver.resolveString(longitude_answer === null || longitude_answer === void 0 ? void 0 : longitude_answer.label),
        required: resolver.resolveBoolean(required),
        tzLabel: resolver.resolveString(timezone_answer === null || timezone_answer === void 0 ? void 0 : timezone_answer.label),
    };
    useEffect(() => {
        inputRef.current.value = defaultValue;
    }, [defaultValue]);
    useEffect(() => {
        const options = {
            fields: ['formatted_address', 'geometry'],
            types: [locationTypes[location_type]],
        };
        autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);
        autocompleteRef.current.addListener('place_changed', handleChange);
    }, []);
    const handleChange = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        try {
            setLoading(true);
            const result = autocompleteRef.current.getPlace();
            const data = {
                address: (result === null || result === void 0 ? void 0 : result.formatted_address) || '',
                latitude: (_a = result === null || result === void 0 ? void 0 : result.geometry) === null || _a === void 0 ? void 0 : _a.location.lat(),
                longitude: (_b = result === null || result === void 0 ? void 0 : result.geometry) === null || _b === void 0 ? void 0 : _b.location.lng(),
            };
            if (data.address) {
                inputRef.current.value = data.address;
                const answers = [
                    getInputBlockAnswer(ValuesType.STRING, Object.assign(Object.assign({}, answer), { label: resolved.label, value: data.address }), key),
                ];
                if (latitude_answer) {
                    answers.push(getInputBlockAnswer(ValuesType.FLOAT64, Object.assign(Object.assign({}, latitude_answer), { label: resolved.latLabel, value: data.latitude }), key));
                }
                if (longitude_answer) {
                    answers.push(getInputBlockAnswer(ValuesType.FLOAT64, Object.assign(Object.assign({}, longitude_answer), { label: resolved.lngLabel, value: data.longitude }), key));
                }
                if (timezone_answer) {
                    const { timeZoneId } = yield GoogleMapsService.getTimezone(data.latitude, data.longitude);
                    if (timeZoneId) {
                        answers.push(getInputBlockAnswer(ValuesType.TIMEZONE, Object.assign(Object.assign({}, timezone_answer), { label: resolved.tzLabel, value: timeZoneId }), key));
                    }
                }
                onChange(answers);
            }
        }
        catch (error) {
        }
        finally {
            setLoading(false);
        }
    });
    return (_jsx(InputControl, Object.assign({ "data-test": id, isRequired: resolved.required, label: resolved.label }, { children: _jsx(TextField, { "data-test": answer.id, defaultValue: defaultValue, inputMode: "search", onBlur: handleChange, placeholder: "", ref: inputRef, rightElement: loading && _jsx(Spinner, { size: "sm" }) }) })));
};
