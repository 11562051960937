import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PageWrapper } from '@/components';
import { setCurrentRoute, useAuth, useConfig } from '@/store';
export const Screen = () => {
    const { t } = useTranslation();
    const params = useParams();
    const { getNavigationConfig } = useConfig();
    const { dispatch: authDispatch, state: { currentRoute }, } = useAuth();
    const nav = getNavigationConfig(params === null || params === void 0 ? void 0 : params.id);
    const title = t(nav === null || nav === void 0 ? void 0 : nav.title);
    useEffect(() => {
        authDispatch(setCurrentRoute(Object.assign(Object.assign({}, currentRoute), { title })));
    }, [title, currentRoute.path]);
    return _jsx(PageWrapper, Object.assign({ title: t(title) }, { children: "CONTENT PAGE DESTINATION HERE" }));
};
