import { LANGUAGE } from '@/types';
export const defaultLang = LANGUAGE.ENGLISH_GB;
export const supportedLang = [LANGUAGE.ENGLISH_GB, LANGUAGE.ENGLISH_US, LANGUAGE.FRENCH];
export const getBrowserLanguage = () => {
    const { navigator } = window;
    return navigator.languages[0] || navigator.language;
};
export const langCode = getBrowserLanguage();
export const is12Hour = new Intl.DateTimeFormat(langCode, { hour: 'numeric' }).resolvedOptions()
    .hour12;
export const decimalSeparator = new Intl.NumberFormat(langCode)
    .formatToParts(1.1)
    .find((part) => part.type === 'decimal').value;
export const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const getLanguageInfo = (code, from = 'en') => {
    const names = {
        countryName: '',
        languageName: '',
    };
    try {
        const [lngCode = '', lngRegion = ''] = code.split('-');
        names.countryName = new Intl.DisplayNames([from], { type: 'region' }).of(lngRegion);
        names.languageName = new Intl.DisplayNames([from], { type: 'language' }).of(lngCode);
    }
    finally {
        return names;
    }
};
export const getLocaleMonths = (monthFormat = 'short') => {
    const format = new Intl.DateTimeFormat(langCode, { month: monthFormat }).format;
    return [...Array(12).keys()].map((m) => format(new Date(2021, m)));
};
export const getLocaleYears = (startDate = new Date(Date.UTC(1900, 0, 1)), endDate = new Date(Date.UTC(2100, 0, 1))) => {
    const format = new Intl.DateTimeFormat(langCode, {
        year: 'numeric',
    }).format;
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const yearList = [];
    for (let year = startYear; year <= endYear; year++) {
        const formattedYear = format(new Date(year, 0, 1));
        yearList.push(Number(formattedYear));
    }
    return yearList;
};
export const formatLocaleDateTime = (date, options) => {
    const format = new Intl.DateTimeFormat(langCode, Object.assign({ day: '2-digit', month: 'long', weekday: 'long', year: 'numeric' }, options)).format;
    return format(new Date(date));
};
