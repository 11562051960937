import { AuthActionType, } from '@/types';
export const setCurrentRoute = (payload) => ({
    payload,
    type: AuthActionType.SET_CURRENT_ROUTE,
});
export const setUserInvitation = (payload) => ({
    payload,
    type: AuthActionType.SET_USER_INVITATION,
});
export const setUserDetails = (payload) => ({
    payload,
    type: AuthActionType.SET_USER_DETAILS,
});
export const setUserPhase = (payload) => ({
    payload,
    type: AuthActionType.SET_USER_PHASE,
});
export const setCurrentUserPhaseEvent = (payload) => ({
    payload,
    type: AuthActionType.SET_CURRENT_USER_PHASE,
});
export const setUserPhaseEventDone = (payload) => ({
    payload,
    type: AuthActionType.SET_USER_PHASE_EVENT_DONE,
});
export const setIdentityProviders = (payload) => ({
    payload,
    type: AuthActionType.SET_IDENTITY_PROVIDERS,
});
