var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Image, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import LogoWhiteHorizontal from '@/assets/img/logo-white-horizontal.svg';
import { ROUTE } from '@/types';
export const Footer = () => {
    const { t } = useTranslation();
    const links = [
        {
            href: ROUTE.TERMS_AND_CONDITIONS,
            isExternal: true,
            label: t('common:footer.termsConditions'),
        },
        {
            href: ROUTE.PRIVACY_POLICY,
            isExternal: true,
            label: t('common:footer.privacyPolicy'),
        },
        {
            cursor: 'default',
            label: t('common:footer.version', { version: process.env.VERSION }),
        },
    ];
    return (_jsxs(Flex, Object.assign({ as: "footer", bg: "grey.80", color: "white.100", direction: { base: 'column', md: 'row' }, fontSize: "xs", gridGap: "8", p: "4", w: "100%" }, { children: [_jsx(Flex, Object.assign({ alignItems: "center", direction: { base: 'column', md: 'row' }, flex: "1", gridGap: "4" }, { children: links.map((_a) => {
                    var { label } = _a, rest = __rest(_a, ["label"]);
                    return (_jsx(Link, Object.assign({}, rest, { children: label }), label));
                }) })), _jsx(Image, { alt: "uMotif", m: "auto", maxW: "80px", src: LogoWhiteHorizontal })] })));
};
