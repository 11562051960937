var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, } from '@chakra-ui/react';
export const ModalBase = (_a) => {
    var { children, footer, onClose, overlay, subtitle, title } = _a, rest = __rest(_a, ["children", "footer", "onClose", "overlay", "subtitle", "title"]);
    return (_jsxs(Modal, Object.assign({ isCentered: true, onClose: onClose, onEsc: onClose, onOverlayClick: onClose }, rest, { children: [_jsx(ModalOverlay, Object.assign({ sx: {
                    opacity: '.9 !important',
                    bg: 'darkBrandBlue.90',
                } }, overlay)), _jsxs(ModalContent, Object.assign({ borderRadius: "14", gridGap: "4", p: "8" }, { children: [_jsxs(ModalHeader, Object.assign({ p: "0", textAlign: "center" }, { children: [_jsx(Text, Object.assign({ fontSize: "24px", fontWeight: "500" }, { children: title })), subtitle && _jsx(Text, Object.assign({ fontSize: "18px" }, { children: subtitle }))] })), onClose && _jsx(ModalCloseButton, {}), _jsx(ModalBody, Object.assign({ pb: "4", textAlign: "center" }, { children: children })), _jsx(ModalFooter, Object.assign({ flexDirection: { base: 'column', md: 'row' }, gridGap: "4", p: "0", sx: {
                            '& > *': {
                                flex: 1,
                            },
                        } }, { children: footer }))] }))] })));
};
