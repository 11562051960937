import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, HStack, Text } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { CheckboxItem } from '@/components';
import { useResolveValue } from '@/hooks';
import { ValuesType } from '@/types';
import { filterBlockAnswers, getInputBlockAnswer } from '@/utils';
export const NumericalRatingScale = ({ block: { answer_filters = [], answers = [], id, key, labels }, defaultValue = null, onChange, }) => {
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);
    const [value, setValue] = useState(defaultValue);
    const [resolver] = useResolveValue();
    const resolved = useMemo(() => {
        const answerKeys = resolver.resolveAnswerFilter(answer_filters);
        const filters = filterBlockAnswers(answers, answerKeys, defaultValue);
        const answersList = filters.answersFiltered.map((a, order) => {
            return Object.assign(Object.assign({}, a), { label: resolver.resolveString(a.label), order });
        });
        return {
            answersList,
        };
    }, []);
    const handleChange = (answer) => {
        const newAnswer = getInputBlockAnswer(ValuesType.INTEGER, answer, key);
        onChange(newAnswer);
    };
    return (_jsxs(Flex, Object.assign({ direction: "column", gridGap: "2" }, { children: [_jsx(HStack, Object.assign({ __css: {
                    display: 'flex',
                }, className: "numeric-rating-scale", "data-test": id, spacing: "0" }, { children: resolved.answersList.map((item) => {
                    const checked = value === item.value;
                    return (_jsx(CheckboxItem, Object.assign({ answer: item, answerChange: handleChange, isChecked: checked }, (resolved.answersList.length > 1 && {
                        __css: {
                            _hover: {
                                zIndex: 1,
                            },
                            zIndex: checked ? 1 : 0,
                            marginEnd: '-1px !important',
                            marginStart: '-1px !important',
                            '& > div': {
                                borderStartRadius: '0',
                                borderTopRadius: '0',
                                borderBottomRadius: '0',
                                borderEndRadius: '0',
                                justifyContent: 'center',
                                padding: '0',
                            },
                        },
                    })), item.id));
                }) })), _jsx(HStack, Object.assign({ __css: {
                    display: 'flex',
                }, className: "numeric-rating-scale-labels", px: "4px" }, { children: labels.map((_label) => {
                    const label = resolver.resolveString(_label);
                    return (_jsx(Text, Object.assign({ align: "center", flex: "1", fontSize: "xs" }, { children: label }), label));
                }) }))] })));
};
