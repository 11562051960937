var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Image as ChakraImage } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useConfig, useQuestionnaire } from '@/store';
import { getLocalStorage } from '@/utils';
export const Image = (_a) => {
    var { image_id, image_key, path = '' } = _a, rest = __rest(_a, ["image_id", "image_key", "path"]);
    const { getImageConfig } = useConfig();
    const { getMediaCore } = useQuestionnaire();
    const { api_url } = getLocalStorage();
    const imageSrc = useMemo(() => {
        if (path)
            return api_url + path;
        if (image_id)
            return getImageConfig(image_id);
        if (image_key) {
            const src = getMediaCore(image_key);
            if (!src)
                throw new Error(`Image with key ${image_key} not found`);
            return src;
        }
    }, [image_id, image_key, path, getImageConfig, getMediaCore]);
    return _jsx(ChakraImage, Object.assign({ src: imageSrc }, rest));
};
