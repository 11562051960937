import { jsx as _jsx } from "react/jsx-runtime";
import { InputControl, TextField } from '@/components';
import { useDebounce, useResolveValue } from '@/hooks';
import { ValuesType } from '@/types';
import { decimalSeparator, getInputBlockAnswer } from '@/utils';
import { useRef } from 'react';
export const FreeDecimalField = ({ block: { answer, id, input_mode: { max_decimal_places, type_validation_error_message }, key, placeholder_text, required, }, defaultValue = null, errors = [], onChange, }) => {
    const inputRef = useRef();
    const [resolver] = useResolveValue();
    const ERROR_DEBOUNCE_MILLIS = 500;
    const debouncedValue = useDebounce(defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.raw_value, ERROR_DEBOUNCE_MILLIS);
    const debounceError = useDebounce(errors, ERROR_DEBOUNCE_MILLIS);
    const isInvalid = (debounceError === null || debounceError === void 0 ? void 0 : debounceError.length) > 0 && (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.raw_value) !== null && debouncedValue !== null;
    const resolved = {
        label: resolver.resolveString(answer.label),
        placeholder: resolver.resolveString(placeholder_text),
        required: resolver.resolveBoolean(required),
        type_validation_error_message: resolver.resolveString(type_validation_error_message),
    };
    function removePrecedingZeros(decimal) {
        const parts = decimal.split(decimalSeparator);
        // Remove preceding zeros except for the first part
        parts[0] = parts[0].replace(/^0+(?!$)/, '');
        // Join the parts back together with the separator
        return parts.join(decimalSeparator);
    }
    const handleChange = (e) => {
        const value = removePrecedingZeros(e.target.value);
        const parts = value.split(decimalSeparator);
        const hasFractionalPart = parts.length > 1;
        let integerPart = parts[0];
        let fractionalPart = parts[1];
        if (hasFractionalPart) {
            if (fractionalPart.length > max_decimal_places) {
                fractionalPart = fractionalPart.slice(0, max_decimal_places);
            }
        }
        const newValue = value === ''
            ? null
            : integerPart + (hasFractionalPart ? decimalSeparator + fractionalPart : '');
        const answerValue = Object.assign(Object.assign({}, answer), { label: resolved.label, value: newValue });
        inputRef.current.value = newValue;
        const newAnswer = getInputBlockAnswer(ValuesType.DECIMAL, answerValue, key);
        onChange(newAnswer);
    };
    const handlePaste = (e) => {
        e.preventDefault();
    };
    const handleKeyDown = (e) => {
        if (![
            'ArrowLeft',
            'ArrowRight',
            'Backspace',
            'Delete',
            'Tab',
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            decimalSeparator,
        ].includes(e.key))
            e.preventDefault();
    };
    return (_jsx(InputControl, Object.assign({ "data-test": id, isInvalid: isInvalid, isRequired: resolved.required, label: resolved.label }, { children: _jsx(TextField, { "data-test": answer.id, defaultValue: (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.raw_value) || '', error: errors[0] || '', inputMode: "decimal", onChange: handleChange, onKeyDown: handleKeyDown, onPaste: handlePaste, placeholder: resolved.placeholder, ref: inputRef, type: "text" }) })));
};
