import { BLOCK_CSS } from '@/types';
var BASE;
(function (BASE) {
    BASE["PADDING"] = "0px";
})(BASE || (BASE = {}));
const templates = {
    padding: `{top} {right} {bottom} {left}`,
};
const cssReplace = (template, data) => {
    const pattern = /{\s*(\w+?)\s*}/g; // {property}
    return template.replace(pattern, (_, token) => data[token] || '');
};
const getPadding = (p) => ({
    bottom: BLOCK_CSS.PaddingType[p === null || p === void 0 ? void 0 : p.bottom] || BASE.PADDING,
    left: BLOCK_CSS.PaddingType[p === null || p === void 0 ? void 0 : p.start] || BASE.PADDING,
    right: BLOCK_CSS.PaddingType[p === null || p === void 0 ? void 0 : p.end] || BASE.PADDING,
    top: BLOCK_CSS.PaddingType[p === null || p === void 0 ? void 0 : p.top] || BASE.PADDING,
});
export function convertToCSS({ alignment, padding, textAlignment } = {}) {
    const paddingVal = getPadding(padding);
    return {
        justifyContent: BLOCK_CSS.AlignmentType[alignment],
        padding: cssReplace(templates.padding, paddingVal),
        textAlign: BLOCK_CSS.AlignmentType[textAlignment],
    };
}
