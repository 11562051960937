import { jsx as _jsx } from "react/jsx-runtime";
import './style.css';
import { Tooltip } from '@chakra-ui/react';
import { forwardRef, useCallback, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { Icon, InputControl, TextField } from '@/components';
import { getLocale } from '@/config';
import { useResolveValue } from '@/hooks';
import { DatetimeGranularityType, ValuesType } from '@/types';
import { ResolveDate, getInputBlockAnswer } from '@/utils';
import { PickerHeader } from './PickerHeader';
import { PickerTime } from './PickerTime';
const resolveDate = new ResolveDate();
export const DateTime = ({ block: { answer, clear_label, granularity, id, key, max_date, min_date, required, time_zone, timezone_answer, utc_answer, }, defaultValue = '', errors = [], onChange, }) => {
    const [resolver] = useResolveValue();
    const resolved = {
        clearLabel: resolver.resolveString(clear_label),
        label: resolver.resolveString(answer.label),
        maxDate: resolver.resolveDate(max_date),
        minDate: resolver.resolveDate(min_date),
        required: resolver.resolveBoolean(required),
        timezone: resolver.resolveTimezone(time_zone),
        timezoneLabel: resolver.resolveString(timezone_answer === null || timezone_answer === void 0 ? void 0 : timezone_answer.label),
        utcLabel: resolver.resolveString(utc_answer === null || utc_answer === void 0 ? void 0 : utc_answer.label),
    };
    const isInvalid = errors.length > 0 && !isNaN(Date.parse(defaultValue));
    const updateAnswer = (date) => {
        const formattedDate = resolveDate.formatDate(date, resolved.timezone);
        const data = {
            answer: date ? formattedDate.tz_offset : null,
            timezone_answer: resolved.timezone,
            utc_answer: formattedDate.utc,
        };
        const answers = [
            getInputBlockAnswer(ValuesType.ZONED_DATETIME, Object.assign(Object.assign({}, answer), { label: resolved.label, value: data.answer }), key),
            getInputBlockAnswer(ValuesType.ZONED_DATETIME, Object.assign(Object.assign({}, utc_answer), { label: resolved.utcLabel, value: data.utc_answer }), key),
            getInputBlockAnswer(ValuesType.TIMEZONE, Object.assign(Object.assign({}, timezone_answer), { label: resolved.timezoneLabel, value: data.timezone_answer }), key),
        ];
        onChange(answers);
    };
    const handleChange = (date) => updateAnswer(date);
    const handleDeselect = () => updateAnswer();
    const pickerView = useMemo(() => {
        const prop = {
            shouldCloseOnSelect: true,
            showMonthYearPicker: false,
            showTimeInput: false,
            showYearPicker: false,
        };
        switch (granularity) {
            case DatetimeGranularityType.YEAR:
                prop.showYearPicker = true;
                break;
            case DatetimeGranularityType.MONTH:
                prop.showMonthYearPicker = true;
                break;
            case DatetimeGranularityType.DAY:
                break;
            case DatetimeGranularityType.TIME:
                prop.shouldCloseOnSelect = false;
                prop.showTimeInput = true;
                break;
        }
        return prop;
    }, [granularity]);
    const props = Object.assign({ maxDate: resolved.maxDate, minDate: resolved.minDate, selected: defaultValue ? new Date(defaultValue) : undefined }, pickerView);
    const pickerFormatter = useCallback(() => {
        const { selected } = props;
        if (selected) {
            const formattedDate = resolveDate.formatDateLocalized(selected);
            return formattedDate[granularity];
        }
        return '';
    }, [props]);
    const PickerInput = forwardRef(({ onClick }, ref) => {
        const val = pickerFormatter();
        return (_jsx(InputControl, Object.assign({ isInvalid: isInvalid, isRequired: resolved.required }, { children: _jsx(TextField, { "data-test": answer.id, defaultValue: val, error: isInvalid && errors[0], leftElement: _jsx(Icon, { name: "calendar", onClick: onClick }), onClick: onClick, 
                // as this block is not yet validated, we need to prevent the typing behavior to restrict it to the picker only
                onKeyDown: (e) => e.preventDefault(), ref: ref, rightElement: val && (_jsx(Tooltip, Object.assign({ label: resolved.clearLabel, placement: "left" }, { children: _jsx(Icon, { name: "close-circle", cursor: "pointer", onClick: handleDeselect }) }))) }) })));
    });
    return (_jsx(InputControl, Object.assign({ "data-test": id, isRequired: resolved.required, label: resolved.label }, { children: _jsx(DatePicker, Object.assign({ customInput: _jsx(PickerInput, {}), customTimeInput: _jsx(PickerTime, {}), disabledKeyboardNavigation: true, dropdownMode: "select", locale: getLocale(), onChange: handleChange, renderCustomHeader: !props.showYearPicker ? (hProps) => _jsx(PickerHeader, Object.assign({}, props, hProps)) : undefined, showMonthDropdown: true, showPopperArrow: false, showYearDropdown: true }, props)) })));
};
