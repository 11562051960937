var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Text, Box, Divider, useMediaQuery, } from '@chakra-ui/react';
import { DataTest } from '@/types';
import { useResolveValue } from '@/hooks';
import { buildSceneKey } from '@/utils';
import { Icon } from '@/components';
var SectionStatus;
(function (SectionStatus) {
    SectionStatus["ACTIVE"] = "active";
    SectionStatus["TODO"] = "todo";
    SectionStatus["COMPLETED"] = "completed";
})(SectionStatus || (SectionStatus = {}));
const sectionStatus = (section, queue, allSections) => {
    const { position } = queue;
    const currentSectionIndex = allSections.findIndex((sec) => sec.page_key.scene_key === section.page_key.scene_key);
    const nextSection = allSections[currentSectionIndex + 1];
    const sectionStartIndex = queue.itemsArray.findIndex((item) => item.scene_key === section.page_key.scene_key);
    const nextSectionStartIndex = nextSection
        ? queue.itemsArray.findIndex((item) => item.scene_key === nextSection.page_key.scene_key)
        : queue.itemsArray.length;
    if (position >= sectionStartIndex && position < nextSectionStartIndex) {
        return SectionStatus.ACTIVE;
    }
    if (position >= nextSectionStartIndex) {
        return SectionStatus.COMPLETED;
    }
    return SectionStatus.TODO;
};
const SectionCard = ({ section, queue, allSections, goToPrevSection, }) => {
    const [resolver] = useResolveValue();
    const title = resolver.resolveString(section.title);
    const status = sectionStatus(section, queue, allSections);
    const isNavigable = status === SectionStatus.ACTIVE || status === SectionStatus.COMPLETED;
    const handleClick = () => {
        if (isNavigable) {
            goToPrevSection(section);
        }
    };
    return (_jsxs(Flex, Object.assign({ py: "4", sx: { backgroundColor: status === SectionStatus.ACTIVE ? 'darkBrandBlue.5' : 'transparent' } }, { children: [_jsxs(Box, Object.assign({ sx: { width: '100px', margin: 'auto' } }, { children: [status === SectionStatus.ACTIVE && (_jsx(Icon, { name: "circle", color: "green.60", strokeWidth: 3, m: "auto", p: 0 })), status === SectionStatus.COMPLETED && (_jsx(Icon, { name: "check-circle", color: "green.60", type: "solid", m: "auto", p: 0 }))] })), _jsx(Box, Object.assign({ className: `section-card ${status === SectionStatus.ACTIVE ? 'active' : ''}`, sx: { width: '100%', opacity: status === SectionStatus.TODO ? '0.6' : '1.0' }, "data-test": `${DataTest.WAYFINDER_SECTION}-${buildSceneKey(section.page_key)}`, onClick: handleClick }, { children: _jsx(Text, Object.assign({ className: isNavigable ? 'navigable' : '', variant: "WAYFINDER_MENU_ITEM" }, { children: title })) }))] })));
};
export const NavigationMenu = (_a) => {
    var { children = '', sections = [], queue, goToPrevSection } = _a, rest = __rest(_a, ["children", "sections", "queue", "goToPrevSection"]);
    const [isMobile] = useMediaQuery('(min-width: 768px)');
    return (isMobile && (_jsx(Box, Object.assign({}, rest, { w: "350px", "data-test": DataTest.WAYFINDER_SIDEBAR, sx: {
            '&.disabled': {
                opacity: '0.5',
                pointerEvents: 'none',
            },
        } }, { children: sections.map((section) => {
            return (_jsxs(_Fragment, { children: [_jsx(SectionCard, { section: section, queue: queue, goToPrevSection: goToPrevSection, allSections: sections }), _jsx(Divider, {})] }));
        }) }))));
};
