var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Text, VStack, useRadioGroup } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button, CentredWrapper, Icon, InputRadioBase, ModalBase, ScreenIntroText, } from '@/components';
import { setLanguage } from '@/config';
import { setUserDetails, useAuth, useConfig } from '@/store';
import { APP_CONFIG, ROUTE } from '@/types';
import { getLanguageInfo } from '@/utils';
export const LanguageSelection = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { getRadioProps, getRootProps } = useRadioGroup();
    const { dispatch, state: { userInvitation }, } = useAuth();
    const { getComponentConfig } = useConfig();
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState({
        code: '',
        name: '',
    });
    if (!userInvitation.meta.locales.length)
        return _jsx(Navigate, { to: ROUTE.HOME });
    const _a = getComponentConfig(APP_CONFIG.COMPONENTS.ConfigComponentType.LANGUAGE_SELECTION), { confirmation_dialog } = _a, langComp = __rest(_a, ["confirmation_dialog"]);
    const handleToggleModal = () => {
        setShowModal(!showModal);
    };
    const handleLangConfirm = () => {
        const locale = selected.code;
        setLanguage(locale);
        dispatch(setUserDetails({
            data: {
                attributes: {
                    locale,
                },
            },
        }));
        navigate(ROUTE.REGISTER);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(CentredWrapper, Object.assign({ gridGap: "16" }, { children: [_jsx(ScreenIntroText, Object.assign({ subtitle: t(langComp.subtitle), title: t(langComp.title) }, { children: _jsx(Text, Object.assign({ bg: "darkBrandBlue.5", borderRadius: "8", color: "darkBrandBlue.40", mt: "6", padding: "4", textAlign: "center" }, { children: t(langComp.information) })) })), _jsx(VStack, Object.assign({}, getRootProps(), { w: "100%" }, { children: userInvitation.meta.locales.map((code) => {
                            const radioProps = getRadioProps({ value: code });
                            const { countryName, languageName } = getLanguageInfo(code);
                            return (_jsxs(InputRadioBase, Object.assign({ baseStyle: {
                                    justifyContent: 'space-between',
                                    px: '20px',
                                    py: '3',
                                    _checked: {
                                        borderColor: 'green.60',
                                    },
                                    _hover: {
                                        borderColor: 'green.60',
                                    },
                                }, onClick: () => setSelected({ code, name: `${languageName} (${countryName})` }), testId: code }, radioProps, { children: [_jsxs(Flex, Object.assign({ alignItems: "flex-start", direction: "column" }, { children: [_jsx(Text, { children: languageName }), _jsx(Text, Object.assign({ fontSize: "xs", opacity: "0.7" }, { children: countryName }))] })), selected.code === code && (_jsx(Icon, { color: "green.60", name: "check-circle", type: "solid" }))] }), code));
                        }) })), _jsx(Button, Object.assign({ isDisabled: !selected.code, onClick: handleToggleModal }, { children: t(langComp.continue) }))] })), _jsx(ModalBase, Object.assign({ footer: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ onClick: handleToggleModal, variant: "outline" }, { children: t(confirmation_dialog.cancel_text) })), _jsx(Button, Object.assign({ onClick: handleLangConfirm }, { children: t(confirmation_dialog.yes_text) }))] }), isOpen: showModal, onClose: handleToggleModal, title: t(confirmation_dialog.title) }, { children: _jsxs(VStack, { children: [_jsx(Text, { children: t(confirmation_dialog.body) }), _jsx(Text, { children: selected.name })] }) }))] }));
};
