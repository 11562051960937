import { isClinro } from '@/config';
import envConfig, { localAPIUrl } from '@/config/env';
const host = window.location.hostname;
export function isUrlExternal(url) {
    if (!url) {
        return false;
    }
    const linkHost = ((url) => {
        if (/^https?:\/\//.test(url)) {
            // The easiest way to parse an URL is to create <a> element.
            // Based on: https://github.com/mrded/is-url-external
            const parser = document.createElement('a');
            parser.href = url;
            return parser.hostname;
        }
        // Get the relative URL
        return window.location.hostname;
    })(url);
    return host !== linkHost;
}
export function isUrlSpecial(url) {
    if (!url) {
        return false;
    }
    const commonUrlSchemes = [
        'data:',
        'fax:',
        'file:',
        'geo:',
        'info:',
        'magnet:',
        'mailto:',
        'tel:',
    ];
    return commonUrlSchemes.filter((prefix) => url.startsWith(prefix)).length > 0;
}
var RegionCodes;
(function (RegionCodes) {
    RegionCodes[RegionCodes["eu"] = 0] = "eu";
    RegionCodes[RegionCodes["us"] = 1] = "us";
    RegionCodes[RegionCodes["cn"] = 2] = "cn";
})(RegionCodes || (RegionCodes = {}));
export const buildAPIUrl = (studyCode) => {
    const { ENV_NAME } = envConfig;
    const studyIdentifier = studyCode.slice(0, 5);
    const regionNumber = Number(studyCode.slice(5, 6));
    const regionCode = RegionCodes[regionNumber - 1];
    const tld = regionCode === 'cn' ? 'cn' : 'io';
    const apiURL = isClinro
        ? window.location.origin
        : ENV_NAME === 'local'
            ? localAPIUrl
            : `https://${studyIdentifier}-${regionCode}.${ENV_NAME}.umotif.${tld}`;
    return {
        apiURL,
        isRegionCodeValid: !!regionCode,
        regionCode,
        regionNumber,
        studyIdentifier,
    };
};
