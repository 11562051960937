var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button as ChakraButton } from '@chakra-ui/react';
export const Button = (_a) => {
    var { styles = {} } = _a, rest = __rest(_a, ["styles"]);
    return (_jsx(ChakraButton, Object.assign({ background: styles === null || styles === void 0 ? void 0 : styles.secondary, borderRadius: "14px", color: styles === null || styles === void 0 ? void 0 : styles.on_secondary, fontSize: "16px", minH: "50px", w: "100%", whiteSpace: "normal", wordwrap: "break-word" }, rest)));
};
