var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { v4 as uuid } from 'uuid';
import { HTTP_STATUS } from '@/types';
import CaptureService from './capture.service';
class AWSService {
    createS3File(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const { abort, actions, error, file, load, progress } = payload.filepond;
            try {
                const AWSPayload = Object.assign(Object.assign({}, payload.awsFileIds), { filename: file.name, id: uuid() });
                const { url } = yield CaptureService.startUpload(AWSPayload);
                const request = new XMLHttpRequest();
                request.open('PUT', url);
                request.upload.onprogress = (e) => {
                    progress(e.lengthComputable, e.loaded, e.total);
                };
                request.onload = function () {
                    if (request.status === HTTP_STATUS.OK) {
                        load(AWSPayload.id);
                    }
                    else {
                        error();
                    }
                };
                request.onerror = function () {
                    error();
                };
                request.send(file);
                actions === null || actions === void 0 ? void 0 : actions.abortFileProcessing(() => {
                    request.abort();
                    abort();
                });
                return {
                    abort: () => {
                        request.abort();
                        abort();
                    },
                };
            }
            catch (e) {
                error();
            }
        });
    }
}
export default new AWSService();
