export var HTTP_STATUS;
(function (HTTP_STATUS) {
    HTTP_STATUS[HTTP_STATUS["OK"] = 200] = "OK";
    HTTP_STATUS[HTTP_STATUS["CREATED"] = 201] = "CREATED";
    HTTP_STATUS[HTTP_STATUS["ACCEPTED"] = 202] = "ACCEPTED";
    HTTP_STATUS[HTTP_STATUS["NO_CONTENT"] = 204] = "NO_CONTENT";
    HTTP_STATUS[HTTP_STATUS["BAD_REQUEST"] = 400] = "BAD_REQUEST";
    HTTP_STATUS[HTTP_STATUS["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
    HTTP_STATUS[HTTP_STATUS["FORBIDDEN"] = 403] = "FORBIDDEN";
    HTTP_STATUS[HTTP_STATUS["NOT_FOUND"] = 404] = "NOT_FOUND";
    HTTP_STATUS[HTTP_STATUS["METHOD_NOT_ALLOWED"] = 405] = "METHOD_NOT_ALLOWED";
    HTTP_STATUS[HTTP_STATUS["NOT_ACCEPTABLE"] = 406] = "NOT_ACCEPTABLE";
    HTTP_STATUS[HTTP_STATUS["CONFLICT"] = 409] = "CONFLICT";
    HTTP_STATUS[HTTP_STATUS["UNPROCESSABLE_ENTITY"] = 422] = "UNPROCESSABLE_ENTITY";
    HTTP_STATUS[HTTP_STATUS["INTERNAL_SERVER_ERROR"] = 500] = "INTERNAL_SERVER_ERROR";
    HTTP_STATUS[HTTP_STATUS["BAD_GATEWAY"] = 502] = "BAD_GATEWAY";
    HTTP_STATUS[HTTP_STATUS["SERVICE_UNAVAILABLE"] = 503] = "SERVICE_UNAVAILABLE";
    HTTP_STATUS[HTTP_STATUS["GATEWAY_TIMEOUT"] = 504] = "GATEWAY_TIMEOUT";
})(HTTP_STATUS || (HTTP_STATUS = {}));
export var StorageKey;
(function (StorageKey) {
    StorageKey["ACCESS_TOKEN"] = "access_token";
    StorageKey["API_URL"] = "api_url";
    StorageKey["CURRENT_QUEUE_PAGE"] = "current_queue_page";
    StorageKey["INVITE_CODE"] = "invite_code";
    StorageKey["REFRESH_TOKEN"] = "refresh_token";
})(StorageKey || (StorageKey = {}));
export var UrlParamsKey;
(function (UrlParamsKey) {
    UrlParamsKey["CODE"] = "code";
    UrlParamsKey["EVENT_ID"] = "event_id";
    UrlParamsKey["INVITATION_CODE"] = "invitation_code";
    UrlParamsKey["STATE"] = "state";
    UrlParamsKey["STUDY_CODE"] = "study_code";
    UrlParamsKey["SUBMITTED_BY"] = "submitted_by";
    UrlParamsKey["SUBMITTED_FOR"] = "submitted_for";
    UrlParamsKey["SEGMENT_ORIGINATOR_ID"] = "segment_originator_id";
    UrlParamsKey["SEGMENT_ORIGINATOR_TYPE"] = "segment_originator_type";
    UrlParamsKey["TOKEN"] = "token";
})(UrlParamsKey || (UrlParamsKey = {}));
export var HeadersRequestKey;
(function (HeadersRequestKey) {
    HeadersRequestKey["DATETIME"] = "DateTime";
    HeadersRequestKey["LOCALE"] = "X-UMOTIF-LOCALE";
})(HeadersRequestKey || (HeadersRequestKey = {}));
export var LANGUAGE;
(function (LANGUAGE) {
    LANGUAGE["ARABIC"] = "ar-AE";
    LANGUAGE["BULGARIAN"] = "bg-BG";
    LANGUAGE["CHINESE"] = "zh-CN";
    LANGUAGE["CZECH"] = "cs-CZ";
    LANGUAGE["DANISH"] = "da-DK";
    LANGUAGE["DUTCH"] = "nl-NL";
    LANGUAGE["ENGLISH_GB"] = "en-GB";
    LANGUAGE["ENGLISH_US"] = "en-US";
    LANGUAGE["ESTONIAN"] = "et-ET";
    LANGUAGE["FINNISH"] = "fi-FI";
    LANGUAGE["FRENCH"] = "fr-FR";
    LANGUAGE["GERMAN"] = "de-DE";
    LANGUAGE["GREEK"] = "el-GR";
    LANGUAGE["HEBREW"] = "he-IL";
    LANGUAGE["HINDI"] = "hi-IN";
    LANGUAGE["HUNGARIAN"] = "hu-HU";
    LANGUAGE["ARMENIAN"] = "hy-AM";
    LANGUAGE["ITALIAN"] = "it-IT";
    LANGUAGE["JAPANESE"] = "ja-JP";
    LANGUAGE["KOREAN"] = "ko-KO";
    LANGUAGE["LATIN"] = "sr-RS";
    LANGUAGE["LATVIAN"] = "lv-LV";
    LANGUAGE["LITHUANIAN"] = "lt-LT";
    LANGUAGE["NORWEGIAN"] = "nb-NO";
    LANGUAGE["POLISH"] = "pl-PL";
    LANGUAGE["PORTUGUESE"] = "pt-BR";
    LANGUAGE["ROMANIAN"] = "ro-RO";
    LANGUAGE["RUSSIAN"] = "ru-RU";
    LANGUAGE["SLOVAK"] = "sk-SK";
    LANGUAGE["SPANISH"] = "es-ES";
    LANGUAGE["SWEDISH"] = "sv-SE";
    LANGUAGE["TURKISH"] = "tr-TR";
    LANGUAGE["UKRAINIAN"] = "uk-UA";
})(LANGUAGE || (LANGUAGE = {}));
export var DataTest;
(function (DataTest) {
    // BLOCKS
    DataTest["BLOCK_ERRORS"] = "block-errors";
    DataTest["VERTICAL_SCALE_BOTTOM_LABEL"] = "vertical-scale-bottom-label";
    DataTest["VERTICAL_SCALE_TOP_LABEL"] = "vertical-scale-top-label";
    // HEADER
    DataTest["HEADER_PORTAL_NAME"] = "portal-name";
    // NOT FOUND
    DataTest["NOT_FOUND_HOME_LINK"] = "home-link";
    // PROFILE
    DataTest["PROFILE_LOGOUT"] = "logout";
    // UNSUPPORTED
    DataTest["UNSUPPORTED_SMART_BANNER"] = "smart-banner";
    // QUESTIONNAIRE
    DataTest["QUESTIONNAIRE_CTA"] = "questionnaire-cta";
    DataTest["QUESTIONNAIRE_NAV_BACKWARD"] = "questionnaire-nav-backward";
    DataTest["QUESTIONNAIRE_NAV_FORWARD"] = "questionnaire-nav-forward";
    DataTest["QUESTIONNAIRE_ERROR_OCCURRED"] = "questionnaire-error-occurred";
    // WAYFINDER
    DataTest["WAYFINDER_SIDEBAR"] = "wayfinder-sidebar";
    DataTest["WAYFINDER_SECTION"] = "wayfinder-section";
})(DataTest || (DataTest = {}));
export var MessageType;
(function (MessageType) {
    MessageType["QB_PREVIEW"] = "qb:preview";
})(MessageType || (MessageType = {}));
