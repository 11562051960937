var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input, chakra, useRadio } from '@chakra-ui/react';
import { CheckboxBase } from './CheckboxBase';
export const InputRadioBase = (_a) => {
    var { baseStyle, testId } = _a, props = __rest(_a, ["baseStyle", "testId"]);
    const { getCheckboxProps, getInputProps, htmlProps } = useRadio(props);
    const input = getInputProps();
    const checkbox = getCheckboxProps();
    return (_jsxs(chakra.label, Object.assign({ "data-test": testId, w: "100%" }, htmlProps, { children: [_jsx(Input, Object.assign({ hidden: true }, input)), _jsx(CheckboxBase, Object.assign({}, checkbox, baseStyle, { children: props.children }))] })));
};
