import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { CentredWrapper, Icon, ScreenIntroText } from '@/components';
import { useInterval } from '@/hooks';
export const LensSubmitted = () => {
    const [counter, setCounter] = useState(3);
    useEffect(() => {
        new BroadcastChannel('clinro').postMessage('questionnaire-submitted');
    }, []);
    useInterval(() => {
        if (counter === 0) {
            self.close();
        }
        else {
            setCounter(counter - 1);
        }
    }, 1000);
    return (_jsxs(CentredWrapper, Object.assign({ maxW: "380px", textAlign: "center" }, { children: [_jsx(Icon, { color: "green.60", name: "check-circle", size: "60px", strokeWidth: "1" }), _jsx(ScreenIntroText, { subtitle: "Your questionnaire has been sent successfully", title: "Questionnaire Submitted" }), _jsxs(Flex, Object.assign({ gridGap: "2" }, { children: [_jsx(Text, { children: "closing in:" }), _jsx(Text, Object.assign({ as: "b" }, { children: counter }))] }))] })));
};
