import { jsx as _jsx } from "react/jsx-runtime";
import { useRadioGroup } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { BlockErrors, InputGroupBase, RadioItem } from '@/components';
import { useResolveValue } from '@/hooks';
import { ValuesType } from '@/types';
import { getInputBlockAnswer } from '@/utils';
export const BooleanGroup = ({ block: { answer_no, answer_yes, id, images = [], key }, defaultValue, errors = [], onChange, }) => {
    useEffect(() => {
        setValue(defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.toString());
    }, [defaultValue]);
    const { getRadioProps, getRootProps, setValue } = useRadioGroup({
        defaultValue: defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.toString(),
    });
    const [resolver] = useResolveValue();
    const resolved = useMemo(() => {
        const answersList = [answer_yes, answer_no].map((a, order) => {
            return Object.assign(Object.assign({}, a), { label: resolver.resolveString(a.label), order });
        });
        return {
            answersList,
        };
    }, []);
    const handleChange = (answer) => {
        const newAnswer = getInputBlockAnswer(ValuesType.BOOLEAN, answer, key);
        onChange(newAnswer, { order: answer.order });
    };
    return (_jsx(BlockErrors, Object.assign({ errors: errors }, { children: _jsx(InputGroupBase, Object.assign({ "data-test": id }, getRootProps(), { children: resolved.answersList.map((item) => {
                const value = String(item.value);
                return (_jsx(RadioItem, Object.assign({ answer: item, answerChange: handleChange, images: images }, getRadioProps({ value })), item.id));
            }) })) })));
};
