export var ActionType;
(function (ActionType) {
    ActionType["CLEAR_ALL"] = "clearQueuedPages::action";
    ActionType["DO_NOTHING"] = "doNothing::action";
    ActionType["PUSH_ITERATIONS"] = "pushIterations::action";
    ActionType["PUSH_WITH_INDEX"] = "pushWithIndex::action";
    ActionType["PUSH_PAGES_FROM_LIST"] = "pushPagesFromList::action";
    ActionType["PUSH_TO_START_OF_QUEUE"] = "pushToStartOfQueuedPages::action";
    ActionType["REMOVE_FROM_QUEUE"] = "removePagesFromQueuedPages::action";
})(ActionType || (ActionType = {}));
