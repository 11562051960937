import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import brandColours from './colors/brand-colors';
import regularColours from './colors/regular-colors';
// refer to https://chakra-ui.com/docs/theming/theme for default styles
const breakpoints = createBreakpoints({
    '2xl': '1200px',
    '3xl': '1536px',
    lg: '768px',
    md: '480px',
    sm: '320px',
    xl: '960px',
});
export function getInputBorderColor(value, isInvalid) {
    if (isInvalid) {
        return 'red.80';
    }
    if (typeof value === 'string' && value.length > 0) {
        return 'darkBrandBlue.80';
    }
    return 'darkBrandBlue.10';
}
export default extendTheme({
    breakpoints,
    colors: {
        amber: {
            80: regularColours.amber80,
        },
        darkBrandBlue: {
            5: brandColours.blue5,
            10: brandColours.blue10,
            20: brandColours.blue20,
            40: brandColours.blue40,
            70: brandColours.blue70,
            80: brandColours.blue80,
            90: brandColours.blue90,
            100: brandColours.blue100,
        },
        green: {
            60: regularColours.green60,
            80: regularColours.green80,
        },
        grey: {
            20: regularColours.grey20,
            60: regularColours.grey60,
            80: regularColours.grey80,
            90: regularColours.grey90,
        },
        lightBlue: {
            50: brandColours.blue50,
            60: brandColours.blue60,
        },
        red: {
            80: regularColours.red80,
        },
        white: {
            100: regularColours.white100,
            faded: regularColours.fadedWhite,
        },
    },
    components: {
        Button: {
            baseStyle: {
                background: 'darkBrandBlue.90',
                borderRadius: '6px',
                color: 'white.100',
                fontSize: '14px',
            },
            defaultProps: {
                variant: null,
            },
            sizes: {
                md: {
                    _disabled: {
                        background: 'darkBrandBlue.90',
                        color: 'white.faded',
                        opacity: 1,
                    },
                    _hover: {
                        _disabled: {
                            background: 'darkBrandBlue.90',
                        },
                    },
                    fontSize: 'sm',
                    fontWeight: 'hairline',
                    height: 'auto',
                    px: '30px',
                    py: '10px',
                    textAlign: 'center',
                },
            },
            variants: {
                cancel: {
                    background: 'red.80',
                },
                outline: () => ({
                    bg: 'transparent',
                    border: '1px solid',
                    borderColor: `darkBrandBlue.40`,
                    color: 'darkBrandBlue.40',
                }),
            },
        },
        Checkbox: {
            baseStyle: {
                control: {
                    _checked: {
                        bg: 'green.60',
                    },
                },
                icon: {
                    color: 'white',
                    margin: '5px',
                },
            },
        },
        Divider: {
            baseStyle: {
                background: 'grey.20',
                opacity: '50%',
            },
        },
        FormLabel: {
            baseStyle: {
                color: 'darkBrandBlue.100',
                fontSize: 'sm',
                marginBottom: '4px',
                paddingLeft: '4px',
            },
        },
        Heading: {
            baseStyle: {
                color: 'darkBrandBlue.100',
                fontWeight: 'hairline',
            },
            sizes: {
                lg: {
                    fontSize: '30px',
                },
            },
            variants: {
                MASTHEAD: {
                    borderBottom: '1px solid',
                    borderColor: 'grey.80',
                    color: 'darkBrandBlue.100',
                    fontSize: '24px',
                    marginBottom: 12,
                    paddingBottom: 6,
                },
                LEGAL: ({ as, size }) => as === 'h1'
                    ? {
                        borderBottom: '1px solid',
                        borderColor: 'grey.80',
                        color: 'darkBrandBlue.100',
                        fontSize: '2xl',
                        marginBottom: 9,
                        marginTop: 7,
                        paddingBottom: 9,
                    }
                    : {
                        color: 'grey.90',
                        fontFamily: 'Sofia Pro Regular',
                        fontSize: size,
                        fontWeight: 400,
                        pb: 2,
                        pt: 8,
                    },
            },
        },
        Input: {
            baseStyle: {
                field: {
                    _focus: {
                        borderColor: 'darkBrandBlue.80',
                    },
                    _invalid: {
                        borderColor: `red.80`,
                    },
                    border: `2px solid`,
                    borderColor: 'grey.50',
                },
            },
            defaultProps: {
                variant: null,
            },
            variants: {
                CODE_ENTRY: ({ isInvalid, value }) => ({
                    field: {
                        border: `2px solid`,
                        borderColor: getInputBorderColor(value, isInvalid),
                        fontSize: '2xl',
                        h: '60px',
                        maxW: '180px',
                        textAlign: 'center',
                    },
                }),
                REGULAR_INPUT: () => ({
                    field: {
                        border: `1px solid`,
                        borderColor: `darkBrandBlue.80`,
                        borderRadius: '4px',
                        fontSize: 'm',
                        h: '40px',
                    },
                }),
            },
        },
        Link: {
            baseStyle: {
                _hover: {
                    textDecoration: 'none',
                },
                textDecoration: 'none',
                textUnderlineOffset: '2px',
            },
        },
        Progress: {
            baseStyle: {
                filledTrack: {
                    bg: 'darkBrandBlue.100',
                },
            },
        },
        Text: {
            variants: {
                CTA_BLUE: {
                    bg: 'lightBlue.60',
                    borderRadius: '6px',
                    color: 'white.100',
                    fontSize: 'sm',
                    fontWeight: 'hairline',
                    height: '40px',
                    px: '55px',
                    py: '10px',
                    textAlign: 'center',
                    textDecoration: 'none',
                },
                CTA_BLUE_DISABLED: {
                    bg: 'lightBlue.60',
                    borderRadius: '6px',
                    boxShadow: 'base',
                    color: 'white.faded',
                    fontSize: 'sm',
                    fontWeight: 'hairline',
                    height: '40px',
                    opacity: 1,
                    px: '55px',
                    py: '10px',
                    textAlign: 'center',
                    textDecoration: 'none',
                },
                CTA_WHITE: {
                    border: '1px solid',
                    borderColor: 'grey.50',
                    borderRadius: '6px',
                    fontSize: 'sm',
                    fontWeight: 'hairline',
                    height: '40px',
                    minW: '154px',
                    px: '55px',
                    py: '10px',
                    textAlign: 'center',
                    textDecoration: 'none',
                },
                HELP: {
                    my: 2,
                },
                QUESTIONNAIRE: {
                    color: 'darkBrandBlue.100',
                    fontFamily: 'Avenir Medium',
                    fontSize: 'xl',
                },
                QUESTIONNAIRE_STATIC_MARKDOWN_BLOCK: {
                    my: 2,
                },
                REGISTRATION_HELP_FIELD: {
                    my: 2,
                },
                TERMS: {
                    my: 2,
                },
                LEGAL: ({ as }) => as === 'p'
                    ? {
                        my: 2,
                        py: 2,
                    }
                    : {},
                WAYFINDER_MENU_ITEM({ className }) {
                    const isNavigable = className === 'navigable';
                    return {
                        color: 'darkBrandBlue.100',
                        fontSize: 'md',
                        lineHeight: '24px',
                        textAlign: 'left',
                        _hover: {
                            textDecoration: isNavigable ? 'underline' : 'default',
                            cursor: isNavigable ? 'pointer' : 'default',
                        },
                    };
                },
            },
        },
        Tooltip: {
            baseStyle: {
                borderRadius: '12px',
            },
        },
        UnorderdList: {
            variants: {
                TERMS: {
                    listStyle: 'disc',
                    marginInlineStart: '1em',
                },
            },
        },
    },
    fontSizes: {
        '2xl': '1.875rem',
        '3xl': '8.125rem',
        lg: '1rem',
        sm: '0.875rem',
        xl: '1.250rem',
        xs: '0.75rem',
    },
    fonts: {
        body: 'Sofia Pro Regular',
        heading: 'Sophia Pro Medium',
    },
    layerStyles: {
        'wrapper-QUESTIONNAIRE_INSTRUCTION_BLOCK': {
            bg: 'grey.60',
            borderRadius: '10px',
            my: 2,
            px: 3,
            py: 3,
        },
    },
    styles: {
        global: {
            body: {
                color: 'grey.90',
                fontSize: 'sm',
            },
        },
    },
});
