var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, forwardRef, useToken } from '@chakra-ui/react';
import { lineIcons, solidIcons } from './iconsEntry';
export * from './iconsEntry';
const icons = {
    line: lineIcons,
    solid: solidIcons,
};
export const Icon = forwardRef((_a, ref) => {
    var { color: _color = 'currentColor', name, size = '24px', strokeWidth = 1.5, type = 'line' } = _a, rest = __rest(_a, ["color", "name", "size", "strokeWidth", "type"]);
    const d = icons[type][name];
    if (d) {
        const color = useToken('colors', [_color]);
        const pathProps = Object.assign(Object.assign({}, (type === 'solid' && { fill: color })), (type === 'line' && { fill: 'none', stroke: color, strokeWidth }));
        return (_jsx(Box, Object.assign({ _hover: {
                filter: 'brightness(80%)',
            }, as: "svg", boxSizing: "content-box", className: `icon-${name}`, cursor: (rest === null || rest === void 0 ? void 0 : rest.onClick) ? 'pointer' : 'inherit', height: size, minW: size, p: "12px", ref: ref, transition: "all 0.2s ease-in-out", viewBox: "0 0 24 24", width: size, xmlns: "http://www.w3.org/2000/svg" }, rest, { children: _jsx("path", Object.assign({ d: d, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }, pathProps)) })));
    }
    return null;
});
